import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ParamService } from 'src/app/core/param.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-parameter-configuaration',
  templateUrl: './parameter-configuaration.component.html',
  styleUrls: ['./parameter-configuaration.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class ParameterConfiguarationComponent implements OnInit {
  today = new Date();
  dcuParameter = [
    // { name: 'Input Feed', value: 'input Feed' },
    { name: 'Heater', value: 'Heater' },
    { name: 'Coke Drum', value: 'Coke Drum' },
    { name: 'Fractionator', value: 'Fractionator' },
  ];
  selectedPeriod = '0';
  selectedParameter = this.dcuParameter[0].value;
  selectedDomain = 1;
  selectedVersion = '1';
  periodDropdown = [
    { name: new Date(), value: '1' },
    { name: 'Weeekly', value: '7' },
    { name: 'Monthly', value: '30' },
    { name: 'Quarterly', value: '90' },
  ];
  inputFeed: { name: string }[];
  heater: { name: string }[];
  cokeDrum: { name: string }[];
  fractionator: { name: string }[];
  actualParameter: { parameter: string; type: boolean; version: any }[];
  updatedParameter: any[];
  // @ViewChild('updatedValue') updatedValue: ParameterTableComponent;
  completeVerData: any;

  constructor(
    private paramService: ParamService,
    public snackBar: MatSnackBar
  ) {
    this.getTableData();
  }

  ngOnInit(): void {}

  openSnackBar(res) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1500,
      data: res,
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
    });
  }

  getTableData() {
    this.paramService.getParaConfiguarationData(this.selectedVersion).subscribe(
      (result) => {
        this.completeVerData = result;
        this.actualParameter = this.completeVerData[0][this.selectedParameter][
          'predicted_values'
        ];
      },
      (err) => {
        this.openSnackBar(err['error']);
      }
    );
  }

  change(value) {
    this.completeVerData[0][this.selectedParameter]['predicted_values'] = value;
    // console.log('--------config---------',this.completeVerData);
    this.updatedParameter = this.completeVerData;
  }
  createVersion() {
    if (this.updatedParameter) {
      this.paramService.createVersion(this.updatedParameter).subscribe(
        (data) => {
          this.openSnackBar(data);
        },
        (err) => {
          this.openSnackBar(err['error']);
        }
      );
    } else {
      this.paramService.createVersion(this.completeVerData).subscribe(
        (data) => {
          this.openSnackBar(data);
        },
        (err) => {
          this.openSnackBar(err['error']);
        }
      );
    }
  }

  update() {
    if (this.updatedParameter) {
      this.paramService
        .updateParameterValue(this.updatedParameter, this.selectedParameter)
        .subscribe(
          (data) => {
            this.openSnackBar(data);
          },
          (err) => {
            this.openSnackBar(err['error']);
          }
        );
    } else {
      this.paramService
        .updateParameterValue(this.actualParameter, this.selectedParameter)
        .subscribe(
          (data) => {
            this.openSnackBar(data);
          },
          (err) => {
            this.openSnackBar(err['error']);
          }
        );
    }
  }

  changeParameter() {
    this.getTableData();
  }
}
