import { paramData } from '../param-graph/dataSource';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ParamModelComponent } from '../param-model/param-model.component';
import { MultiParamGraphModalComponent } from '../multi-param-graph-modal/multi-param-graph-modal.component';
import { ParamService } from 'src/app/core/param.service';

@Component({
  selector: 'app-frl-measures',
  templateUrl: './frl-measures.component.html',
  styleUrls: ['./frl-measures.component.scss'],
})
export class FrlMeasuresComponent implements OnInit {
  cprval: any;
  dosageVal: any;

  run1Sor = [
    {
      paramName: 'CPR at SOR',
      b: '0',
      y: '0',
      p: '0',
      exParam: ['zone 1', 'zone 2', 'zone 3'],
    },
  ];

  singleParam = [
    {
      paramName: 'Ethane Feed',
      pName: 'Ethane Feed',
      zone1: '9414.38',
      zone2: '7007.62',
      zone3: '7203.94',
      uom: 'Kg/hr',
    },
    {
      paramName: 'SHCR',
      pName: 'SHC Ratio',
      zone1: '0.396688635',
      zone2: '0.427751654',
      zone3: '0.459981274',
      uom: '%',
    },
    {
      paramName: 'TMT',
      pName: 'TMT',
      zone1: '1034.0',
      zone2: '1068.0',
      zone3: '1078.0',
      uom: '°C',
    },
    {
      paramName: 'COT',
      pName: 'COT',
      zone1: '844.0758316',
      zone2: '840.0307958',
      zone3: '842.0463777',
      uom: '°C',
    },
    {
      paramName: 'Fuel Gas Flow',
      pName: '',
      zone1: '0.689655389',
      zone2: '0.747548596',
      zone3: '0.797953938',
      uom: 'Ratio',
    },
    {
      paramName: 'Oxygen Gas Flow',
      pName: '',
      zone1: '0.689655389',
      zone2: '0.747548596',
      zone3: '0.797953938',
      uom: 'Ratio',
    },
    {
      paramName: 'CPR',
      pName: 'CPR',
      zone1: '0.689655389',
      zone2: '0.747548596',
      zone3: '0.797953938',
      uom: 'Ratio',
    },
  ];

  multiParam = [
    { paramName: 'Ethane Feed vs CPR', pName: 'EthaneCPR' },
    { paramName: 'TMT vs CPR', pName: 'TMTCPR' },
    { paramName: 'COT vs CPR', pName: 'COTCPR' },
    { paramName: 'SHCR vs CPR', pName: 'SHCCPR' },
    { paramName: 'Fuel Gas Flow vs CPR' },
    { paramName: 'Oxygen Gas Flow vs CPR' },
  ];

  selectedParam = [
    { ethane_feed: ['efrz1', 'efrz2', 'efrz3'] },
    { cpr: ['cprz1', 'cprz2', 'cprz3'] },
    { shc: ['shcz1', 'shcz2', 'shcz3'] },
    { tmt: ['tmtz1', 'tmtz2', 'tmtz3'] },
    { cot: ['cotz1', 'cotz2', 'cotz3'] },
  ];

  dolDay = [0, 5, 10, 15, 20, 25, 30];

  mymat = this.dolDay[0];

  fgflz1: number;
  fgfwz1: number;
  fgflz2: number;
  fgfwz2: number;
  fgflz3: number;
  fgfwz3: number;
  paramValue: any;
  sorVal: any;
  eorVal: any;
  allData: any;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    public paramService: ParamService
  ) {
    this.paramService.getAllValues().subscribe((res) => {
      this.paramValue = res[0][0];
      this.dosageVal = Number(res[0][0]['dosage']);

      // need to change later
      this.fgflz1 = Number(res[0][0]['fuelflwz1']);
      this.fgfwz1 = Number(res[0][0]['fuelwallz1']);
      this.fgflz2 = Number(res[0][0]['fuelflwz2']);
      this.fgfwz2 = Number(res[0][0]['fuelwallz2']);
      this.fgflz3 = Number(res[0][0]['fuelflwz3']);
      this.fgfwz3 = Number(res[0][0]['fuelwallz3']);
      //till here

      console.log(typeof this.fgfwz3);
    });
  }

  openSingleParamDialog(param): void {
    const dialogRef = this.dialog.open(ParamModelComponent, {
      // disableClose: true,
      // hasBackdrop: true,
      // closeOnNavigation: true,
      panelClass: 'normal-modal-class',
      data: {
        param: param,
        run: 1,
        zone: 1,
      },
    });

    //close on navigation
    this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  openMultipleParamDialog(param): void {
    const dialogRef = this.dialog.open(MultiParamGraphModalComponent, {
      // disableClose: true,
      // hasBackdrop: true,
      // closeOnNavigation: true,
      panelClass: 'normal-modal-class',
      data: {
        param: param,
        run: 1,
        zone: 1,
      },
    });

    //close on navigation
    this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  ngOnInit(): void {
    this.summaryVal();

    this.paramService.getAllValues().subscribe((res) => {
      console.log('All data', res[0][0].run);
      this.allData = res;
      // for (let mData of this.allData) {
      //   child.checked = parentChecked;
      // }
    });
  }

  summaryVal() {
    this.paramService.getSummaryVal().subscribe((res) => {
      this.sorVal = res[0][0];
      this.eorVal = res[0][0];
      console.log('CPR', res[0][0]);
    });
  }
}
