import { ModalComponent } from '../modal/modal.component';
//import { Component, , OnInit } from '@angular/core';
import { MultiParamGraphModalComponent } from '../../components/multi-param-graph-modal/multi-param-graph-modal.component';

import {
  Component,
  Input,
  Inject,
  ViewChild,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { Router } from '@angular/router';

export interface DialogData {
  animal: string;
  name: string;
}

@Component({
  selector: 'app-furnace-img',
  templateUrl: './furnace-img.component.svg',
  styleUrls: ['./furnace-img.component.scss'],
})
export class FurnaceImgComponent {
  // @ViewChild(MatIcon) matIcon: MatIcon;
  @Input() selectedRun: number;
  @ViewChild('icon', { static: false }) iconElement: ElementRef;

  animal: string;
  name: string;
  inputparam:any;

  constructor(
    public dialog: MatDialog,
    private renderer: Renderer2,
    private router: Router
  ) {}

  openDialog({ pageX, pageY, currentTarget }: MouseEvent): void {
    const {
      height,
      width,
      top,
      left,
    } = (currentTarget as HTMLElement).getBoundingClientRect();
    // console.log((currentTarget as HTMLElement).getBoundingClientRect());
    const dialogRef = this.dialog.open(ModalComponent, {
      width: '250px',
      data: { name: 'test' },
      // hasBackdrop: false,
      position: {
        // left: `${pageX}px`, top: `${pageY}px`
        left: `${left + width / 2}px`,
        top: `${top + height}px`,
      },
    });

    //close on navigation
    this.router.events.subscribe(() => {
      dialogRef.close();
    });
  }

  openMultipleParamDialog(param): void {
    console.log("bhawna1",param);
    console.log("bhawna2",this.selectedRun);
    const dialogRef = this.dialog.open(MultiParamGraphModalComponent, {
      // disableClose: true,
      // hasBackdrop: true,
      // closeOnNavigation: true,
      panelClass: 'normal-modal-class',
      
      data: {
        param: param,
        run: 6,
        zone: 1,
      },
    });

    //close on navigation
    this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
