import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-m-opt-param-measures',
  templateUrl: './m-opt-param-measures.component.html',
  styleUrls: ['./m-opt-param-measures.component.scss']
})
export class MOptParamMeasuresComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
