import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoadingBarComponent implements OnInit {
  constructor() {}
  counter: any;

  steps = [
    'DCU/FRL Process Data Ware House Ready –Input Feed',
    'DCU/FRL Process Data Ware House Ready - Heater',
    'DCU/FRL Process Data Ware House Ready - CokeDrum',
    'DCU/FRL Process Data Ware House Ready - Factionator',
    'DCU/FRL Process Data Ware House Ready - FRL',
    'DCU/FRL Process Data Correlation Established - CCR, Temprature & FRL data',
    'ML Model Started to Build',
    'AI Predictions Mode Activated',
    'AI Recommendation Successfully',
  ];

  ngOnInit(): void {
    for (let i = 0; i <= 100; i++) {
      this.counter = i;
    }
  }
}
