<div class="container-fluid">
  <div class="row">
    <div class="col-md-8">
      <div class="info">
        <div class="p-5">

          <img class="logo img-fluid" src="assets/logo/lns_logo.png" alt="livnsense logo" />

        </div>

        <div class="py-3 mx-auto px-2 w-75 text-light bg-white text-center" style="opacity: 0.9;">
          <p class="text-maroon h1 font-weight-bold">
            Net Zero Transition Enabling Platform
        </p>
        <p class="text-maroon font-weight-bold h5">
            Powered by<img src="./assets/logo/greenOps.png" width="200px" alt="">
        </p>
        </div>
      </div>
    </div>
    <div class="col-md-4 bg-white">
      <!-- <div class="text-center">
        <img class="cokenilLogo img-fluid" src="assets/logo/logoTransp.png" alt="Cognitive demo logo" />
      </div> -->

      <form class="form-signin" [formGroup]="loginForm" (ngSubmit)="onFormSubmit()">


        <h1 class="h3 my-3 font-weight-normal">Sign in to your account</h1>
        <label for="inputEmail" class="pt-2">Username</label>
        <input type="text" formControlName="email_id" class="form-control pt-2" placeholder="Enter User ID" autofocus="">


        <label for="inputPassword" class="pt-2">Password</label>
        <div class="input-group">
        <input type="password" formControlName="password" id="inputPassword" class="form-control pt-2" [type]="hide ? 'password' : 'text'" placeholder="Enter Password">
        <div class="input-group-append">
          <span class="input-group-text" >
            <i class="fa" [ngClass]="{'fa-eye-slash': !hide,'fa-eye': hide}" (click)="togglePwd()"></i>
          </span>
       </div>
        </div>
        <button class="btn btn-lg btn-secondary btn-block mt-2" type="submit">Sign in</button>
        <!-- <p class="h4 text-center pt-4">Powered by
          <img class="img-fluid" style="width: 6rem;" src="assets/logo/isense4i.png" alt="isense4i logo" />
        </p> -->
        <div class="text-right">

        </div>
      </form>

    </div>
  </div>
</div>
