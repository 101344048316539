<div class="card my-3 px-3" style="background-color: transparent; border: none;">
  <div class="row">
    <div class="col-md-3">
      <img src="assets\icons\UserMngmt.png" height="40px" class="mr-4">
      <span class="configuration-title">User Management</span>
    </div>
    <div class="col-md-2 ml-auto">
      <button class="btn btn-sm purple-button float-right" aria-hidden="true"
          (click)="openAddUserDialog()"><i class="fa fa-plus mr-2"></i>Add User</button>
  </div>
</div>
  <table class="table bordered-table table-dark user-c-table table-responsive d-table w-100 mt-3">
    <thead>
      <tr>
          <th>Sr.No</th>
          <th>Name</th>
          <th>Login Id</th>
          <th>Email Id</th>
          <th>Designation</th>
          <th>User Type</th>
          <th class="text-center">Actions</th>
      </tr>
  </thead>

    <tbody>
      <tr *ngFor="let user of usersData;let i=index">
        <td>{{i+1}}</td>
        <td>{{user.name}}</td>
        <td>{{user.login_id}}</td>
        <td>{{user.email_id}}</td>
        <td>{{user.designation}}</td>
        <td>{{user.user_type}}</td>
        <td class="text-center">
          <div class="btn-group">
            <button class="btn btn-sm mr-2 purple-button" aria-hidden="true"
              (click)="openUpdateUserDialog(user)">Modify</button>
            <button class="btn btn-danger btn-sm mr-2" aria-hidden="true" (click)="openDeleteUserDialog(user)"
              *ngIf="user.user_type !== 'Non Admin'">Delete</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
