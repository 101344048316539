import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';
import { ApiCallsService } from '../../services/api-calls.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeleteUserDialogComponent implements OnInit {
  isLoading: boolean;

  constructor(public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar, private apiCallsService: ApiCallsService) { }

  ngOnInit() {
  }

  /* Method to close the modal if user clicks the cancel */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /* Method to delete user */
  deleteUser() {
    this.isLoading = true;
    this.apiCallsService.deleteUser(this.data).subscribe(data => {
      this.dialogRef.close();
      this.isLoading = false;
      this.openSnackBar(data);
    }, err => {
      this.dialogRef.close();
      this.isLoading = false;
      this.openSnackBar(err['error']);
    })
  }

  /* Method which opens the snackbar */
  openSnackBar(res) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1500,
      data: res,
      horizontalPosition: 'start',
      verticalPosition: 'bottom'
    });
  }

}
