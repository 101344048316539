import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';
import { ApiCallsService } from '../../services/api-calls.service';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddUserDialogComponent implements OnInit {

  userForm: FormGroup;
  isLoading: boolean;
  roles: any[];

  public errorHandling = (control: string, error: string) => {
    return this.userForm.controls[control].hasError(error);
  }

  constructor(public dialogRef: MatDialogRef<AddUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private apiCallsService: ApiCallsService, public snackBar: MatSnackBar) { }

  ngOnInit() {

    let rolesList = ['Admin', 'Super Admin', 'User'];
    this.roles = rolesList;

    this.createUserForm();
  }
  /* Method which creates form to add new user */
  private createUserForm() {
    this.userForm = new FormGroup({
      name: new FormControl(null, [Validators.required,
      Validators.pattern(/^(([A-Za-z]+[\-\']?)*([A-Za-z]+)?\s)+([A-Za-z]+[\-\']?)*([A-Za-z]+)?$/)]),

      login_id: new FormControl(null, [Validators.required,
      Validators.minLength(5)]),

      email_id: new FormControl(null, [Validators.required,
      Validators.pattern(/^\w+([\.-]?\w+)+@\w+([\.:]?\w+)+(\.[a-zA-Z0-9]{2,3})+$/)]),

      password: new FormControl('', [Validators.required,
      Validators.pattern(/^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/)]),

      designation: new FormControl(null,
        [Validators.required]),

      user_type: new FormControl(null,
        [Validators.required]),

      // notifications: new FormControl(false,
      //   [Validators.required]),

      reEnterPassword: new FormControl('',
        [Validators.required])
    }, { validators: this.pwdMatchValidator });
  }

  /* Method to check password match */
  private pwdMatchValidator(frm: FormGroup) {
    return frm.get('password').value !== frm.get('reEnterPassword').value
      ? { mismatch: true } : null;
  }

  /* Method to close the modal if user clicks the cancel */
  onNoClick(): void {
    this.dialogRef.close();
  }

  /* Method to update configuration */
  addUser() {
    if (this.userForm.valid) {
      this.isLoading = true;
      this.apiCallsService.addNewUser(this.userForm['value']).subscribe(data => {
        this.dialogRef.close();
        this.isLoading = false;
        this.openSnackBar(data);
      }, err => {
        this.dialogRef.close();
        this.isLoading = false;
        this.openSnackBar(err['error']);
      })
    }

    else {
      Object.keys(this.userForm.controls).forEach(field => {
        const control = this.userForm.get(field);
        control.markAsTouched({ onlySelf: true });
      });

      this.openSnackBar({ message: 'Please check all the fields are filled and valid!' });
    }
  }

  /* Method which opens the snackbar */
  openSnackBar(res) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1500,
      data: res,
      horizontalPosition: 'start',
      verticalPosition: 'bottom'
    });
  }

  onRePasswordInput() {
    if (this.userForm.hasError('mismatch'))
      this.userForm.controls['reEnterPassword'].setErrors({ 'mismatch': true });
    else
      this.userForm.controls['reEnterPassword'].setErrors(null);
  }
}
