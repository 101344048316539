import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParamService } from 'src/app/core/param.service';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-actual-parameter-graph-modal',
  templateUrl: './actual-parameter-graph-modal.component.html',
  styleUrls: ['./actual-parameter-graph-modal.component.scss'],
})
export class ActualParameterGraphModalComponent implements OnInit {
  selectedParameter: any;
  fromParamTable: any;
  flowRateParam: any;
  tempParam: any;
  pressureParam: any;
  otherParam: any;
  graphData: any[];
  isLoadingComplete: boolean;
  isCriticalParameter: boolean;
  isNotPredictedLine: boolean;
  chartId: any;
  updatedList: any[]=[];
  constructor(
    public activeModal: NgbActiveModal,
    private paramService: ParamService,
    public dialogRef: MatDialogRef<ActualParameterGraphModalComponent>
  ) {}

  ngOnInit(): void {
    this.isLoadingComplete = false;
    this.getGraphData();
  }

  getGraphData() {
    this.paramService
      .getYieldsParameterGraphData(this.selectedParameter)
      .subscribe((res) => {
        res[0]['actual_values'].forEach(
          (element) =>
            (element['actual_value'] = this.paramService.displayGraphDecimal(
              element['actual_value']
            ))
        );
        if(!this.isNotPredictedLine) {
            res[0]['actual_values'].forEach(
                (element) =>
                  (element['predicted_value'] = this.paramService.displayGraphDecimal(
                    res[0]['recommended_values']
                  ))
              );
              res[0]['isNot_predicted_line'] = false;
              res[0]['disable_actual_toolTip'] = true;
        } else {
            res[0]['isNot_predicted_line'] = true;
            res[0]['disable_actual_toolTip'] = false;
        }
       
        if (this.selectedParameter === 'CCR %') {
          res[0]['actual_values'].forEach(
            (element) => (element['lcl_threshold'] = '20.9')
          );
          res[0]['actual_values'].forEach(
            (element) => (element['ucl_threshold'] = '25')
          );
          res[0]['isNot_threshold_line'] = false;
        } else if (this.selectedParameter === 'Flow Rate') {
          res[0]['actual_values'].forEach(
            (element) => (element['lcl_threshold'] = '292727.6')
          );
          res[0]['actual_values'].forEach(
            (element) => (element['ucl_threshold'] = '386165.7')
          );
          res[0]['isNot_threshold_line'] = false;
        } else {
          res[0]['isNot_threshold_line'] = true;
        }
        this.graphData = res;
        this.isLoadingComplete = true;
      });
  }

  changeParameter() {
    this.isLoadingComplete = false;
    this.getGraphData();
  }

  closeModal() {
    this.dialogRef.close(this.chartId);
  }
}
