<cdk-virtual-scroll-viewport class="scroll1" style="height: 92.5%" itemSize="90">
  <section class="card mb-4">
    <p class="table-title mb-0 py-3"><span class="dot"></span>Summary
    </p>
    <!-- <select class="selectParam custom-select-sm font-weight-bold my-2 mr-auto">
      <option >Runlength - 1</option>
      <option >Runlength - 2</option>
      <option >Runlength - 3</option>
      <option >Runlength - 4</option>
    </select> -->
    <div class="table-responsive" id="demo">
      <table class="table table-borderless tableBody">
        <thead>
          <!-- <tr class="main-table-row text-center">
            <td scope="col"></td>
            <td colspan="3" scope="col font-weight-bold"></td>
            <td scope="col font-weight-bold">UoM</td>
          </tr> -->
        </thead>
        <tbody>
          <!-- <tr class="table-data trow">
            <td class="cellBorder font-weight-bold" style="width: 140px;">DOL</td>
            <td colspan="4" class="cellBorder font-weight-bold text-center">
              
            </td>
          </tr> -->
          <tr class="main-table-row text-center">
            <td scope="col"></td>
            <td scope="col" class="font-weight-bold">Zone 1</td>
            <td scope="col" class="font-weight-bold">Zone 2</td>
            <td scope="col" class="font-weight-bold">Zone 3</td>
            <td scope="col" class="font-weight-bold">UoM</td>
          </tr>
          <tr class="table-data trow">
            <td class="cellBorder font-weight-bold">CPR at SOR</td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
          </tr>
          <tr class="table-data trow">
            <td class="cellBorder font-weight-bold">CPR at EOR</td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
          </tr>
          <tr class="table-data trow">
            <td class="cellBorder font-weight-bold">TMT at SOR</td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
          </tr>
          <tr class="table-data trow">
            <td class="cellBorder font-weight-bold">TMT at EOR</td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
  <section class="card mb-4">
    <p class="table-title mb-0 py-2"><span class="dot"></span>Single Parameter vs Time
    </p>
    <div class="table-responsive" id="demo">
      <table class="table table-borderless tableBody">
        <thead>
          <tr class="main-table-row text-center">
            <td scope="col"></td>
            <td scope="col font-weight-bold">Zone 1</td>
            <td scope="col font-weight-bold">Zone 2</td>
            <td scope="col font-weight-bold">Zone 3</td>
            <td scope="col font-weight-bold">UoM</td>
          </tr>
        </thead>
        <tbody>
          <tr class="table-data trow" *ngFor="let sParam of singleParam" (click)="openSingleParamDialog(sParam.pName)">
            <td class="cellBorder font-weight-bold" style="width: 140px;">{{sParam.paramName}}</td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
            <td class="cellBorder font-weight-bold text-center"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
  <section class="card">
    <p class="table-title mb-0 py-2"><span class="dot"></span>Multiple Parameter vs Time
    </p>
    <div class="table-responsive" id="demo">
      <table class="table table-borderless tableBody">
        <thead>
          <tr class="main-table-row text-right">
            <td scope="col"></td>
            <td scope="col font-weight-bold"></td>
            <td scope="col font-weight-bold"></td>
            <td scope="col font-weight-bold"></td>
          </tr>
        </thead>
        <tbody>
          <tr class="table-data trow" *ngFor="let mParam of multiParam" (click)="openMultipleParamDialog(mParam.pName)">
            <td class="cellBorder font-weight-bold">{{mParam.paramName}}</td>
            <td class="cellBorder font-weight-bold"></td>
            <td class="cellBorder font-weight-bold"></td>
            <td class="cellBorder font-weight-bold"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</cdk-virtual-scroll-viewport>
