<section class="card border-0" style="background-color: #373638;">
    <p class="table-title mb-0 py-2"><span class="dot"></span>Single Parameter vs Time
    </p>
    <div class="table-responsive" id="demo">
      <table class="table table-sm table-borderless tableBody">
        <thead>
          <tr class="main-table-row text-center">
            <td scope="col"></td>
            <td scope="col font-weight-bold">Zone 1</td>
            <td scope="col font-weight-bold">Zone 2</td>
            <td scope="col font-weight-bold">Zone 3</td>
            <td scope="col font-weight-bold">UoM</td>
          </tr>
        </thead>
        <tbody>
          <tr class="table-data trow" *ngFor="let paramValue of paramValues" (click)="openSingleParamDialog(item.key)">
            <td class="cellBorder font-weight-bold p-1 pl-3" style="width: 140px;">{{paramValue.paramname}}</td>
            <td class="cellBorder font-weight-bold text-center">{{paramValue.zone1 |
                  number: '1.2-2'}}</td>
            <td class="cellBorder font-weight-bold text-center">{{paramValue.zone2 |
                  number: '1.2-2'}}</td>
            <td class="cellBorder font-weight-bold text-center">{{paramValue.zone3 |
                  number: '1.2-2'}}</td>
            <td class="cellBorder font-weight-bold text-center">{{paramValue.uom}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
  