<div class="customModal" appGraphDraggable>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <h3 *ngIf="!isCriticalParameter" class="modal-title font-weight-bold text-white" id="modal-title">{{selectedParameter}}</h3>
                <h3 *ngIf="isCriticalParameter" class="modal-title font-weight-bold text-white" id="modal-title">Critical Parameters</h3>
            </div>
            <div class="col-md-6">
                <button type="button" class="close" aria-label="Close button" data-dismiss="modal"
                    (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div *ngIf="isCriticalParameter" class="row mb-2">
            <div class="col-6 ml-auto pr-4 float-right">
                <select class="selectParam form-control custom-select-sm" [(ngModel)]="selectedParameter"
                    (change)="changeParameter()">
                    <option *ngFor="let param of fromParamTable" [value]="param.value">{{param.value}}</option>
                </select>
            </div>
            <!-- <div class="col pr-1">
                <label class="text-left text-light">Select flow rate:</label>
                <select class="selectParam form-control custom-select-sm" [(ngModel)]="selectedParameter"
                    (change)="changeParameter()">
                    <option *ngFor="let param of flowRateParam" [value]="param.value">{{param.dis_name}}</option>
                </select>
            </div>
            <div class="col pr-1">
                <label class="text-left text-light">Select temp:</label>
                <select class="selectParam form-control custom-select-sm" [(ngModel)]="selectedParameter"
                    (change)="changeParameter()">
                    <option *ngFor="let param of tempParam" [value]="param.value">{{param.dis_name}}</option>
                </select>
            </div>
            <div class="col pr-1">
                <label class="text-left text-light">Select pressure:</label>
                <select class="selectParam form-control custom-select-sm" [(ngModel)]="selectedParameter"
                    (change)="changeParameter()">
                    <option *ngFor="let param of pressureParam" [value]="param.value">{{param.dis_name}}</option>
                </select>
            </div>
            <div class="col pr-1">
                <label class="text-left text-light">Select others:</label>
                <select class="selectParam form-control custom-select-sm" [(ngModel)]="selectedParameter"
                    (change)="changeParameter()">
                    <option *ngFor="let param of otherParam" [value]="param.value">{{param.dis_name}}</option>
                </select>
            </div> -->
        </div>
        <div *ngIf="!isCriticalParameter" class="row mb-2">
            <div class="col-6 ml-auto pr-4 float-right">
                <select class="selectParam form-control custom-select-sm" [(ngModel)]="selectedParameter"
                    (change)="changeParameter()">
                    <option *ngFor="let param of fromParamTable" [value]="param.parameter">{{param.parameter |
                        replaceUnderscore}}</option>
                </select>
            </div>
        </div>
        <div *ngIf="!isLoadingComplete" style="width: 100%; height: 500px;padding-top: 30%;">
            <p class="h5 text-light text-center">Loading...</p>
        </div>
        <app-actual-parameter-graph *ngIf="isLoadingComplete" [chartId]="chartId" [graphData]="graphData"></app-actual-parameter-graph>
    </div>
</div>