import { Component, OnInit } from '@angular/core';
// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ParamService } from 'src/app/core/param.service';

@Component({
  selector: 'app-actual-main-yield-graph',
  templateUrl: './actual-main-yield-graph.component.html',
  styleUrls: ['./actual-main-yield-graph.component.scss'],
})
export class ActualMainYieldGraphComponent implements OnInit {
  graphData: any[] = [];
  minTime: any;
  maxTime: any;
  constructor(private paramService: ParamService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Color set
    var colors = new am4core.ColorSet();

    let chart: any = am4core.create('actMainYieldChart', am4charts.XYChart3D);
    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in
    chart.background.fill = '#3C4358';
    // chart.background.fill = '#252A3A';
    chart.responsive.enabled = true;
    //To disable amchart logo
    chart.logo.disabled = true;

    //Add scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    //  Sort by date
    chart.events.on('beforedatavalidated', function (ev) {
      chart.data.sort(function (a, b) {
        return (
          new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf()
        );
      });
    });

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    // dateAxis.minZoomCount = 5;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.title.text = 'Date';
    dateAxis.title.fill = '#fff';
    dateAxis.title.fontWeight = 'bold';
    dateAxis.title.fontSize = 20;
    dateAxis.renderer.labels.template.fill = am4core.color('#fff');
    dateAxis.renderer.labels.template.fontSize = 20;

    // dateAxis.renderer.grid.template.disabled = true;
    // dateAxis.renderer.labels.template.disabled = true;
    // dateAxis.cursorTooltipEnabled = false;

    // this makes the data to be grouped
    dateAxis.groupData = true;
    dateAxis.groupCount = 30;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Kg/Hr';
    valueAxis.title.fill = '#fff';
    valueAxis.title.fontWeight = 'bold';
    valueAxis.title.fontSize = 20;
    valueAxis.renderer.labels.template.fill = am4core.color('#fff');
    valueAxis.renderer.labels.template.fontSize = 18;

    this.paramService.getMaxMinTime().subscribe((res) => {
      this.minTime = res[0].actual.min_timestamp;
      this.maxTime = res[0].actual.max_timestamp;
      this.paramService
        .getCommonActualYieldData(
          new Date(this.minTime).toUTCString(),
          new Date(this.maxTime).toUTCString()
        )
        .subscribe((result) => {
          // console.log('actual', result[0]);
          for (const [key, value] of Object.entries(result[0])) {
            // i = i + 1;
            let color;
            if (this.paramService.colorCode.find((x) => x.value === key)) {
              color = this.paramService.colorCode.find((x) => x.value === key)
                .color;
            } else if (
              this.paramService.colorCode.find((x) => x.value2 === key)
            ) {
              color = this.paramService.colorCode.find((x) => x.value2 === key)
                .color;
            } else {
              color = '#fff';
            }
            createSeries(value, key, color);
          }
        });
    });

    //Create line
    function createSeries(data, name, color) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = 'actual_value';
      series.dataFields.dateX = 'timestamp';
      series.name = name;
      series.tooltipText =
        "{name}: [bold]{actual_value.formatNumber('#.00')} % [/]";
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = color;
      series.tooltip.label.fill = am4core.color('#fff');
      series.stroke = color;
      series.data = data;
      series.strokeWidth = 3;

      series.events.on('beforedatavalidated', function (ev) {
        series.data.sort(function (a, b) {
          return (
            new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf()
          );
        });
      });

      return series;
    }

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.xAxis = dateAxis;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.labels.template.fill = am4core.color('#fff');
    chart.legend.labels.template.fontWeight = 'bold';
    chart.legend.labels.template.fontSize = 12;

    // Enable export
    chart.exporting.menu = new am4core.ExportMenu();

    chart.exporting.filePrefix = 'DCU';

    chart.exporting.menu.items = [
      {
        label: '...',
        menu: [
          {
            label: 'Image',
            menu: [
              { type: 'png', label: 'PNG' },
              { type: 'jpg', label: 'JPG' },
              { type: 'svg', label: 'SVG' },
              { type: 'pdf', label: 'PDF' },
            ],
          },
          {
            label: 'Data',
            menu: [
              { type: 'json', label: 'JSON' },
              { type: 'csv', label: 'CSV' },
              { type: 'xlsx', label: 'XLSX' },
              { type: 'html', label: 'HTML' },
            ],
          },
        ],
      },
    ];

    chart.exporting.timeoutDelay = 10000;

    chart.exporting.menu.items[0].icon =
      'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+';
  }
}
