<app-spinner *ngIf="isLoading">{{ isLoading }}</app-spinner>
<app-navbar></app-navbar>
<div class="wrapper">
  <div class="sidebar" data-color="green" data-background-color="black" data-image="../assets/img/sidebar-2.jpg">
    <!--
      Tip 1: You can change the color of the sidebar using: data-color="purple | azure | green | orange | danger"

      Tip 2: you can also add an image using data-image tag
  -->
    <div class="row logo">

      <div class="text-white simple-text">GreenOps</div>
      <div class="square center">
        <img src="../../../assets/logo/lns_logo.png">
      </div>

      <!-- <div>
        <button (click)="updateToken()">click me!</button>
      </div> -->

    </div>
    <div class="sidebar-wrapper">
      <ul class="nav" style="height: 65vh;overflow: auto;overflow-x: hidden;">
        <li class="nav-item active  ">
          <a class="nav-link" href="#/dashboard/landingpage">
            <i class="material-icons">dashboard</i>
            <p style="color:#fff">Dashboard</p>
          </a>
        </li>
        <!-- <li class="nav-item ">
          <a class="nav-link text-white" href="https://dev.cognitive4demo.livnsense.com/#/dashboard/plant-frl">
            <i class="material-icons">check</i>
            <p>Chemical</p>
          </a>
        </li> -->

        <li class="nav-item " >
          <a class="nav-link text-white" href="https://industry4calculator.livnsense.com" style="border:1px solid white">
            <i class="material-icons">calculate</i>
            <p>Industry 4.0 calculator</p>
          </a>
        </li>

        <li class="nav-item " >
          <a class="nav-link text-white" href="#/adminpanel/manage-user" style="border:1px solid white">
            <i class="material-icons">Admin</i>
            <p>Admin Dashboard</p>
          </a>
        </li>

        <li class="nav-item" (click)="pi = !pi">
          <a class="nav-link text-white" href="#/dashboard/landingpage">
            <!-- <i class="material-icons">check</i> -->
            <p>Process Industry <i style="float:right" class="material-icons">arrow_drop_down</i></p>
          </a>
        </li>

        <div style="padding-left: 20px;" *ngIf="pi">
          <li class="nav-item">
            <a class="nav-link text-white" routerLink="/dashboard/plant-frl">
              <i class="material-icons">check</i>
              <p>Furnace 4.0</p>
            </a>
          </li>
          <!-- <li class="nav-item ">
            <a class="nav-link text-white" >
              <i class="material-icons">check</i>
              <div class="dropdown">
                <p  routerLink="/dashboard/plant-frl" href="#" class="nav-link text-white dropdown-toggle" data-toggle="dropdown" aria-expanded="true" >
                    Chemical
                    <b class="caret"></b>
                </p>
                <ul class="dropdown-menu">
                    <li><a href="#">Cpchem</a></li>
                    <li><a href="#">P66</a></li>
                </ul>
            </div>
          </a>
        </li> -->

          <!-- <li class="nav-item ">
            <a class="nav-link text-white" href="https://dev.cognitive4demo.livnsense.com/#/dashboard/plant-admin">
              <i class="material-icons">check</i>
              <p>Oil & Gas</p>
            </a>
          </li> -->
          <li class="nav-item ">
            <a class="nav-link text-white" routerLink="/dashboard/plant-admin">
              <i class="material-icons">check</i>
              <p>Cognitive DCU</p>
            </a>
          </li>

          <!-- <li class="nav-item ">
            <a class="nav-link text-white" href="https://dev.smelting.livnsense.com">
              <i class="material-icons">check</i>
              <p>Aluminium</p>
            </a>
          </li> -->

          <li class="nav-item ">
            <a class="nav-link text-white" (click)="postAdminData(balcoURL, 'balco')">
              <i class="material-icons">check</i>
              <p>Aluminium</p>
            </a>
          </li>

          <li class="nav-item ">
            <a class="nav-link text-white" (click)="postAdminData(newboilerURL, 'newboiler')">
              <i class="material-icons">check</i>
              <p>Boiler 4.0</p>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" (click)="postAdminData(pyroURL, 'pyro')">
              <i class="material-icons">check</i>
              <p>Pyro 4.0</p>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" (click)="postAdminData(esgURL, 'cement')">
              <i class="material-icons">check</i>
              <p>ESG Report 4.0</p>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" (click)="postAdminData(methaneURL, 'methane')">
              <i class="material-icons">check</i>
              <p>Methane Analytics</p>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" href="https://smartpower.livnsense.com">
              <i class="material-icons">check</i>
              <p>Smart Power</p>
            </a>
          </li>
          <!-- <li class="nav-item ">
            <a class="nav-link text-white" href="https://sensetracker.livnsense.com">
              <i class="material-icons">check</i>
              <p>Sense Tracker</p>
            </a>
          </li> -->

          <li class="nav-item ">
            <a class="nav-link text-white" (click)="postAdminData(pharmaURL, 'pharma')">
              <i class="material-icons">check</i>
              <p>Pharma 4.0</p>
            </a>
          </li>

          <li class="nav-item ">
            <a class="nav-link text-white" (click)="postAdminData(cpchemURL, 'cpchem')">
              <i class="material-icons">check</i>
              <p>Ethylene 4.0</p>
            </a>
          </li>

          <li class="nav-item ">
            <a class="nav-link text-white" (click)="postAdminData(p66URL, 'p66')">
              <i class="material-icons">check</i>
              <p>De-Coker</p>
            </a>
          </li>
          <li class="nav-item ">
            <a class="nav-link text-white" href="https://new-smartwater4.0-dev.livnsense.com/#/overview/real-time-data">
              <!-- <a class="nav-link text-white" href="https://greenmining.livnsense.com"> -->
              <i class="material-icons">check</i>
              <p>Smart water</p>

            </a>
          </li>
          <!--          <li class="nav-item ">-->
          <!--            <a class="nav-link text-white" href="https://new-greenmining.livnsense.com">-->
          <!--              &lt;!&ndash; <a class="nav-link text-white" href="https://greenmining.livnsense.com"> &ndash;&gt;-->
          <!--              <i class="material-icons">check</i>-->
          <!--              <p>John deere</p>-->

          <!--            </a>-->
          <!--          </li>-->
          <li class="nav-item ">
            <a class="nav-link text-white" href="#/dashboard/landingpage">
              <!-- <a class="nav-link text-white" href="https://greenmining.livnsense.com"> -->
              <i class="material-icons">check</i>
              <p>EDA Tool</p>

            </a>
          </li>
        </div>

        <li class="nav-item" (click)="di = !di">
          <a class="nav-link text-white" href="#/dashboard/landingpage">
            <!-- <i class="material-icons">check</i> -->
            <p>Discrete Industry <i style="float:right" class="material-icons">arrow_drop_down</i> </p>
          </a>
        </li>

        <div style="padding-left: 20px;" *ngIf="di">
          <li class="nav-item ">
            <a class="nav-link text-white" (click)="postAdminData(weldSenseURL, 'weldSense')">
              <i class="material-icons">check</i>
              <p>Industrial weld</p>
            </a>
          </li>

          <!-- <li class="nav-item ">
            <a class="nav-link text-white" (click)="postAdminData(jcbURL, 'jcb')">
              <i class="material-icons">check</i>
              <p> heavy machinery</p>
            </a>
          </li> -->

          <!-- commented machine -->

          <li class="nav-item ">
            <a class="nav-link text-white" href="https://new-greenmining.livnsense.com">
              <!-- <a class="nav-link text-white" href="https://greenmining.livnsense.com"> -->
              <i class="material-icons">check</i>
              <p>Greenmining</p>

            </a>
          </li>

          <li class="nav-item ">
            <a class="nav-link text-white" href="https://pcb.inspection-new.livnsense.com/">
              <!-- <a class="nav-link text-white" href="https://greenmining.livnsense.com"> -->
              <i class="material-icons">check</i>
              <p>PCB Inspection</p>

            </a>
          </li>
        </div>
        <!-- <li class="nav-item active-pro ">
              <a class="nav-link" href="./upgrade.html">
                  <i class="material-icons">unarchive</i>
                  <p>Upgrade to PRO</p>
              </a>
          </li> -->


        <!-- <li class="nav-item ">
          <a class="nav-link text-white" (click)="postAdminData(energyURL, 'energysense')">
            <i class="material-icons">check</i>
            <p>EnergySense</p>
          </a>
        </li> -->


      </ul>
    </div>
  </div>
  <div class="main-panel">
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="card card-stats">
              <div class="card-header-primary card-header-icon">
                <div class="card-icon">

                  <i class="material-icons">loop</i>
                </div>
                <p class="card-category text-white">Process Optimization</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="card card-stats">
              <div class="card-header-info card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">precision_manufacturing</i>
                </div>
                <p class="card-category text-white">Predictive Outcomes</p>

              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="card card-stats">
              <div class="card-header-warning card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">anchor</i>
                </div>
                <p class="card-category text-white">Real-Time Analytics</p>
              </div>
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="card card-stats">
              <div class="card-header-success card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">eco</i>
                </div>
                <p class="card-category text-white">Sustainability</p>
              </div>
            </div>
          </div>
        </div>
        <!-- row 2 -->
        <div class="row">
          <div class="col-md-4 col-xl-4 col-lg-12">
            <div class="card card-chart">
              <div class="card-header card-header-success">
                <!-- <div class="ct-chart" id="dailySalesChart"></div> -->
                <div class="chart-wrapper" *ngIf="chart">
                  <canvas id="testChart3" style="width:100%;max-width:700px;max-height:130px;">
                  </canvas>
                </div>
              </div>
              <div class="card-body">
                <h4 class="card-title text-white text-white">Green Tags Mapped</h4>
                <p class="card-category text-white">
                  <i class="fa fa-long-arrow-up"></i> 55%  Increase In Green Tag Mapping.</p>
              </div>
              <!-- <div class="card-footer">
                <div class="stats">
                  <i class="material-icons">access_time</i> updated 4 minutes ago
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-xl-4 col-lg-12">
            <div class="card card-chart" style="min-height:220px;">
              <div class="card-header card-header-warning">
                <!-- <div class="ct-chart" id="websiteViewsChart"></div> -->
                <div class="chart-wrapper" *ngIf="chart">
                  <canvas id="testChart1" style="width:100%;max-width:700px;max-height:130px;">
                  </canvas>
                </div>
              </div>
              <div class="card-body">
                <h4 class="card-title text-white">ROI Improvement</h4>
                <p class="card-category text-white"><i class="fa fa-long-arrow-up"></i> 37% Increase In ROI</p>
              </div>
              <!-- <div class="card-footer">
                <div class="stats">
                  <i class="material-icons">access_time</i> updated 4 minutes ago
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-xl-4 col-lg-12">
            <div class="card card-chart" style="min-height:220px;">
              <div class="card-header card-header-danger">
                <!-- <div class="ct-chart" id="completedTasksChart"></div> -->
                <div class="chart-wrapper" *ngIf="chart">
                  <canvas id="testChart2" style="width:100%;max-width:700px;max-height:130px;">
                  </canvas>
                </div>
              </div>
              <div class="card-body">
                <h4 class="card-title text-white">Emission Reduction</h4>
                <p class="card-category text-white"><i class="fa fa-long-arrow-down"></i> 20% Reduction In Carbon Emissions</p>
              </div>
              <!-- <div class="card-footer">
                <div class="stats">
                  <i class="material-icons">access_time</i> updated 4 minutes ago
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <!-- row 2 -->
        <div class="row">
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="card card-stats card-custom">
              <div class="card-header card-header-warning card-header-icon">
                <div class="card-icon">

                  <i class="material-icons">bookmark_added</i>
                </div>
                <p class="card-category text-white">Total Tags Handled</p>
                <h3 style="margin-bottom: 8%;" class="card-title text-warning">75000+
                  <!--<small>GB</small> -->
                </h3>
              </div>
              <!-- <div class="card-footer"> -->
              <!-- <div class="stats"> -->
              <!-- <i class="material-icons text-warning">warning</i> -->
              <!-- <a href="#pablo" class="warning-link">Get More Space...</a> -->
              <!-- <i class="material-icons">date_range</i> Last 24 Hours -->
              <!-- </div> -->
              <!-- </div> -->
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="card card-stats card-custom">
              <div class="card-header card-header-success card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">savings</i>
                </div>
                <p class="card-category text-white">Savings&nbsp;(per year)</p>
                <h3 style="margin-bottom: 8%;" class="card-title text-warning">$750K+</h3>
              </div>
              <!-- <div class="card-footer">
                <div class="stats">
                  <i class="material-icons">date_range</i> Last 24 Hours
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="card card-stats card-custom">
              <div class="card-header card-header-danger card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">build</i>
                </div>
                <p class="card-category text-white">Total Deployments</p>
                <h3 style="margin-bottom: 8%;" class="card-title text-warning">20+</h3>
              </div>
              <!-- <div class="card-footer">
                <div class="stats">
                  <i class="material-icons">local_offer</i> Tracked from Github
                </div>
              </div> -->
            </div>
          </div>
          <div class="col-xl-3 col-lg-6 col-md-6 col-sm-6">
            <div class="card card-stats card-custom">
              <div class="card-header card-header-info card-header-icon">
                <div class="card-icon">
                  <i class="material-icons">trending_up</i>
                </div>
                <p class="card-category text-white">Efficiency Improvement</p>
                <h3 style="margin-bottom: 8%;" class="card-title text-warning">33%+</h3>
              </div>
              <!-- <div class="card-footer">
                <div class="stats">
                  <i class="material-icons">update</i> Just Updated
                </div>
              </div> -->
            </div>
          </div>
        </div>
        <div class="col-12">
          <footer class="footer fixed-bottom">
            <div class="container-fluid">
              <nav class="float-right text-white">
                <ul>
                  <li>
                    <a href="https://www.livnsense.com">
                      LivNSense GreenOps Pvt. Ltd. All rights reserved.
                    </a>
                  </li>
                </ul>
              </nav>
              <!-- <div class="copyright float-right" id="date"> -->

              <!-- <a href="https://www.livnsense.com" target="_blank">LivNSense GreenOps Pvt. Ltd</a> All rights reserved. -->
              <!-- </div> -->
            </div>
          </footer>
        </div>
      </div>
    </div>
  </div>

</div>
