import { ParamService } from 'src/app/core/param.service';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// declare var $: any;

@Component({
  selector: 'app-predicted1-main-yield-graph-modal',
  templateUrl: './predicted1-main-yield-graph-modal.component.html',
  styleUrls: ['./predicted1-main-yield-graph-modal.component.scss'],
})
export class Predicted1MainYieldGraphModalComponent implements OnInit {
  periodList = ['Daily', 'Weekly', 'Monthly', 'Quarterly'];
  predictionPeriod = this.periodList[0];
  startDate: string;
  endDate: string;
  isLoadingComplete: boolean;
  minTime: any;
  maxTime: any;
  @Input() graphData = 'pre_fore_cast_data';
  period: string;
  chartId = 'preRec';
  constructor(
    public activeModal: NgbActiveModal,
    private paramService: ParamService,
    public dialogRef: MatDialogRef<Predicted1MainYieldGraphModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    // max min time
    // this.paramService.getMaxMinTime().subscribe((res) => {
    //   this.minTime = res[0].actual.min_timestamp;
    //   this.maxTime = res[0].actual.max_timestamp;
    // });
  }

  ngOnInit(): void {
    this.isLoadingComplete = true;
    // this.changePeriod();
  }

  closeModal() {
    this.dialogRef.close(this.data);
  }

  changePeriod() {
    this.isLoadingComplete = false;
    this.chartId = this.chartId + this.predictionPeriod;
    setTimeout(() => {
      if (this.predictionPeriod === 'Daily') {
        this.period = this.predictionPeriod;
      } else if (this.predictionPeriod === 'Weekly') {
        this.period = this.predictionPeriod;
      } else if (this.predictionPeriod === 'Monthly') {
        this.period = this.predictionPeriod;
      } else if (this.predictionPeriod === 'Quarterly') {
        this.period = this.predictionPeriod;
      }
      this.isLoadingComplete = true;
    }, 1);
  }
}
