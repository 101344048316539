import {
  HttpClient,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

const baseUrl = environment['baseUrl2'];

@Injectable({
  providedIn: 'root',
})
export class ApicallService {
  public headerTokens: any;

  constructor(private http: HttpClient, private router: Router) {
    const token: string = localStorage.getItem('user')!;
    const cToken = JSON.parse(token)['token'];

    this.headerTokens = {
      headers: new HttpHeaders().set('Authorization', `Bearer ${cToken}`),
    };
  }

  // Method to get user details
  getUserDetails(): Observable<any> {
    return this.http.get(`${baseUrl}user_details`, this.headerTokens).pipe(
      map((response: any) => response)
    );
  }

  // Method to add new user details
  addUserDetails(body: any): Observable<any> {
    return this.http.post(`${baseUrl}add_user`, body, this.headerTokens).pipe(
      map((response: any) => response)
    );
  }

  // Method to edit user details
  editUserDetails(body: any): Observable<any> {
    return this.http.put(`${baseUrl}update_user`, body, this.headerTokens).pipe(
      map((response: any) => response)
    );
  }

  // Method to delete user
  deleteUser(id: any, name: any): Observable<any> {
    return this.http
      .delete(`${baseUrl}delete_user?user_id=${id}&user_name=${name}`, this.headerTokens)
      .pipe(
        map((response: any) => response)
      );
  }
}
