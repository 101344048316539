import { Component, Input, OnInit } from '@angular/core';
// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

@Component({
  selector: 'app-actual-parameter-graph',
  templateUrl: './actual-parameter-graph.component.html',
  styleUrls: ['./actual-parameter-graph.component.scss'],
})
export class ActualParameterGraphComponent implements OnInit {
  @Input() graphData: any[];
  @Input() chartId: any;
  constructor() {}

  ngOnInit(): void {
    //   this.chartName
  }

  ngAfterViewInit() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart: any = am4core.create(this.chartId, am4charts.XYChart3D);
    chart.background.fill = '#3C4358';
    chart.responsive.enabled = true;

    // chart.paddingRight = 20;
    chart.logo.disabled = true;

    // chart.tooltip.label.wrap = true;

    // // valueAxis.renderer.labels.template.adapter.add("text", function (text) {
    // //   return text + "%";
    // // });

    //   valueAxis.min = graph[0]['predicted_value'] * 90 / 100;

    //Add scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.labels.template.fill = am4core.color('#fff');
    chart.legend.labels.template.fontWeight = 'bold';
    // chart.legend.reverseOrder = true;

    // Enable export
    chart.exporting.menu = new am4core.ExportMenu();

    chart.exporting.timeoutDelay = 10000;
    chart.exporting.filePrefix = 'DCU';

    chart.exporting.menu.items = [
      {
        label: '...',
        menu: [
          {
            label: 'Image',
            menu: [
              { type: 'png', label: 'PNG' },
              { type: 'jpg', label: 'JPG' },
              { type: 'svg', label: 'SVG' },
              { type: 'pdf', label: 'PDF' },
            ],
          },
          {
            label: 'Data',
            menu: [
              { type: 'json', label: 'JSON' },
              { type: 'csv', label: 'CSV' },
              { type: 'xlsx', label: 'XLSX' },
              { type: 'html', label: 'HTML' },
            ],
          },
        ],
      },
    ];

    chart.exporting.menu.items[0].icon =
      'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+';

    chart.data = this.graphData[0]['actual_values'];
    let thresholdLine = this.graphData[0]['isNot_threshold_line'];
    let predictedLine = this.graphData[0]['isNot_predicted_line'];
    let disableActualTooltip = this.graphData[0]['disable_actual_toolTip'];

    //  Sort by date
    chart.events.on('beforedatavalidated', function (ev) {
      chart.data.sort(function (a, b) {
        return (
          new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf()
        );
      });
    });

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.title.text = 'Date';
    dateAxis.title.fill = '#fff';
    dateAxis.title.fontWeight = 'bold';
    dateAxis.renderer.labels.template.fill = am4core.color('#fff');
    dateAxis.renderer.labels.template.fontSize = 15;

    // this makes the data to be grouped
    // dateAxis.groupData = true;
    // dateAxis.groupCount = 30;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Value';
    valueAxis.title.fill = '#fff';
    valueAxis.title.fontWeight = 'bold';
    valueAxis.renderer.labels.template.fill = am4core.color('#fff');
    valueAxis.renderer.labels.template.fontSize = 15;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    chart.cursor.snapToSeries = series;
    chart.cursor.xAxis = dateAxis;

    // Create series
    var series = chart.series.push(new am4charts.LineSeries());
    series.dataFields.valueY = 'actual_value';
    series.dataFields.dateX = 'timestamp';
    series.tooltipText = '[bold]Actual Value: {actual_value}';
    series.name = 'Actual Value';
    series.tooltip.pointerOrientation = 'vertical';
    series.tooltip.disabled = disableActualTooltip;
    series.tooltip.getFillFromObject = false;
    series.tooltip.background.fill = am4core.color('#fff');
    series.tooltip.label.fill = am4core.color('#00');
    series.fill = '#990000';
    series.fillOpacity = 0.3;
    series.stroke = '#990000';

    var series1 = chart.series.push(new am4charts.LineSeries());
    series1.dataFields.valueY = 'lcl_threshold';
    series1.dataFields.dateX = 'timestamp';
    series1.tooltipText = 'LCL: {lcl_threshold}';
    series1.name = 'LCL Value';
    // series1.tooltip.pointerOrientation = "vertical";
    series1.tooltip.getFillFromObject = false;
    series1.tooltip.background.fill = am4core.color('#ff1a1a');
    series1.tooltip.label.fill = am4core.color('#ffffff');
    series1.stroke = '#ff1a1a';
    series1.hiddenInLegend = thresholdLine;

    var series2 = chart.series.push(new am4charts.LineSeries());
    series2.dataFields.valueY = 'ucl_threshold';
    series2.dataFields.dateX = 'timestamp';
    series2.tooltipText = 'UCL: {ucl_threshold}';
    series2.name = 'UCL Value';
    series2.tooltip.pointerOrientation = 'vertical';
    series2.tooltip.getFillFromObject = false;
    series2.tooltip.background.fill = am4core.color('#008000');
    series2.tooltip.label.fill = am4core.color('#ffffff');
    series2.stroke = '#008000';
    series2.hiddenInLegend = thresholdLine;

    let series3 = chart.series.push(new am4charts.LineSeries());
    series3.dataFields.valueY = 'predicted_value';
    series3.dataFields.dateX = 'timestamp';
    series3.name = 'Recommended Value';
    series3.tooltipText = '{name}: [bold]{predicted_value}';
    series3.tooltip.pointerOrientation = 'vertical';
    series3.tooltip.getFillFromObject = false;
    series3.tooltip.background.fill = am4core.color('#ffff00');
    series3.tooltip.label.fill = am4core.color('black');
    series3.stroke = '#ffff00';
    series3.hiddenInLegend = predictedLine;
    // end am4core.ready()
  }
}
