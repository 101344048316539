<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="d-flex">
  <h1 mat-dialog-title class="mb-0">Delete User</h1>
  <i class="fa fa-times cursor-pointer ml-auto" (click)="onNoClick()"></i>
</div>

<div mat-dialog-content>
  <p class="text-center py-3 confirm-text">Are you sure you want to delete the account?</p>
</div>
<div mat-dialog-actions class="float-right">
  <button type="button" class="btn btn-sm btn-danger mx-2" (click)="deleteUser()">Delete Account</button>
</div>
