<a class="back-to-unit cursor-pointer m-3 text-light" [routerLink]="['/dashboard']"><i class="fa fa-chevron-left"></i>
    Dashboard
</a>

<mat-tab-group animationDuration="0ms" mat-align-tabs="center">
    <mat-tab>
        <ng-template mat-tab-label class="configuration-tabs">
            <img src="assets/icons/AccessConfig.png" alt="user" height="24px" id="notify-icon" class="mr-3"> Parameter
            Configuration
        </ng-template>
        <ng-template matTabContent>
            <app-parameter-configuaration></app-parameter-configuaration>
        </ng-template>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label class="configuration-tabs">
            <img src="assets/icons/UserMngmt.png" alt="user" height="24px" id="notify-icon" class="mr-3"> User
            Management
        </ng-template>
        <ng-template matTabContent>
            <app-user-management></app-user-management>
        </ng-template>
    </mat-tab>

    <mat-tab>
        <ng-template mat-tab-label class="configuration-tabs">
            <img src="assets/icons/AccessConfig.png" alt="user" height="24px" id="notify-icon" class="mr-3"> Access
            Configuration
        </ng-template>
        <ng-template matTabContent>
            <app-access-configuration></app-access-configuration>
        </ng-template>
    </mat-tab>

    <mat-tab>
        <ng-template matTabContent>
            Under progress
        </ng-template>
    </mat-tab>
</mat-tab-group>