import { paramData } from '../param-graph/dataSource';
import { NONE_TYPE } from '@angular/compiler';
import {
  Component,
  OnInit,
  Inject,
  ViewEncapsulation,
  Input,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ParamService } from 'src/app/core/param.service';

@Component({
  selector: 'app-param-model',
  templateUrl: './param-model.component.html',
  styleUrls: ['./param-model.component.scss'],
})
export class ParamModelComponent implements OnInit {
  // @Input() tempRun: any;
  runs: any;
  zones = [1, 2, 3];
  tempZone: any;

  selectedRun: any;

  graphData: any[];
  isLoadingComplete: boolean;
  batchRuns: any;

  constructor(
    public dialogRef: MatDialogRef<ParamModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paramService: ParamService
  ) {
    console.log(data);
    // this.paramService.selectedRun.subscribe((res) => {
    //   this.selectedRun = res;
    //   console.log(this.selectedRun);
    // });
  }

  ngOnInit(): void {
    this.isLoadingComplete = false;
    this.getGraphData();

    this.paramService.runCount().subscribe((res) => {
      let temp = res[0].run;
      this.batchRuns = temp.splice(0, temp.length - 1);
      console.log('Batch', this.batchRuns);
      this.runs = this.batchRuns.reverse();
      // this.selectedRun = this.runs[this.runs.length - 1];
      // this.selectedRun = this.runs[0];
    });
  }

  getGraphData() {
    this.paramService
      .getSingleParamGraphData(this.data.param, this.data.zone, this.data.run)
      .subscribe((res) => {
        console.log('Single', res);
        this.graphData = res;
        this.isLoadingComplete = true;
      });
  }

  // changeRun(run) {
  //   // console.log(run);
  //   // this.data.run = run;
  //   // this.tempRun = run;
  //   this.isLoadingComplete = false;
  //   setTimeout(() => {
  //     this.paramService
  //       .getSingleParamGraphData(
  //         this.data.param,
  //         this.data.zone,
  //         this.selectedRun
  //       )
  //       .subscribe((res) => {
  //         this.graphData = res;
  //         this.isLoadingComplete = true;
  //       });
  //   }, 1);
  // }

  changeZone(zone) {
    console.log(zone);
    console.log('Run', this.data.run);
    this.data.zone = zone;
    this.tempZone = zone;
    this.isLoadingComplete = false;
    setTimeout(() => {
      this.paramService
        .getSingleParamGraphData(this.data.param, zone, this.data.run)
        .subscribe((res) => {
          this.graphData = res;
          this.isLoadingComplete = true;
        });
    }, 1);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
