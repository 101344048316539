<div class="paneBody">

  <!-- <p class="h5 text-center yield-title">DCU - Recommendation</p> -->

  <div class="container mb-4">
    <div class="row">
      <div *ngIf="isAdmin" class="col p-0 pb-2">
        <button [disabled]="ifData === false" class="button w-100 text-center text-light font-weight-bold"
          (click)="startAlg()">Recommendation for
          Coke Reduction</button>
      </div>
      <div *ngIf="!isAdmin" class="col p-0 pb-2">
        <select name="selectedParameter" class="form-control text-light selectParam" [(ngModel)]="selectedParameter"
          (change)="changePredictedParameter()">
          <option *ngFor="let option of dcuParameter" [value]="option.value">{{option.name}}</option>
        </select>
      </div>
    </div>
    <!-- <p class="text-white font-weight-bold py-2">Last Updated Date: <span class="font-weight-normal pl-2">{{lastUpdatedDate | date: 'dd-MM-yyyy'}}</span></p> -->
  </div>

  <!-- predicted OPERATOR value starts -->
  <app-parameter-table *ngIf="!isAdmin" [header]="header" [selection]='selectedParameter'
    [selectedParameter]='predictedValuesData1'>
  </app-parameter-table>
  <!-- predicted OPERATOR value end -->

  <app-parameter-table *ngIf="!isAdmin" [header]="'YIELDS (based on recommendation)'" [selection]='selectedParameter'
    [selectedParameter]='yieldspredictedValuesData'>
    <div class="container">
      <div class="row">
        <div class="col p-0 pb-2 mt-1">
          <button class="form-control w-100 text-center text-white bg-success selectParam"
            (click)="saveDashboardData()">Save</button>
        </div>
      </div>
    </div>
  </app-parameter-table>

  <ng-container *ngIf="!hidePredict">
    <!-- predicted ADMIN value starts -->
    <app-parameter-table *ngIf="isAdmin" [header]="header" [selection]='selectedParameter'
      [selectedParameter]='predictedValuesData'>
    </app-parameter-table>
    <!-- predicted value starts -->


    <!-- predicted yield starts -->
    <app-parameter-table [header]="'YIELDS (based on recommendation)'" [selection]='selectedParameter'
      [selectedParameter]='yieldspredictedValuesData'>
      <div class="container">
        <div class="row">
          <div class="col p-0 pb-2 mt-1">
            <button class="form-control w-100 text-center text-white bg-success selectParam"
              (click)="saveDashboardData()">Save</button>
          </div>
        </div>
      </div>
    </app-parameter-table>
  </ng-container>
  <!-- predicted yield starts -->
</div>
