import { DcuGraphModalComponent } from './../dcu-graph-modal/dcu-graph-modal.component';
import { ParamService } from 'src/app/core/param.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import jwt_decode from 'jwt-decode';

import { Router } from '@angular/router';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-balco-furnace-svg',
  templateUrl: './balco-furnace-svg.component.svg',
  styleUrls: ['./balco-furnace-svg.component.scss'],
})
export class BalcofurnacesvgComponent implements OnInit {
  @Input() selection;
  @Input() selectedParameter;
  @Input() header;
  @Input() isConfig;
  @Output() onMinInputValueChanged = new EventEmitter<boolean>();
  @Output() onMaxInputValueChanged = new EventEmitter<boolean>();
  @Output() hovered = new EventEmitter();
  @Output() leave = new EventEmitter();
  pan: any = { x: 0, y: 0 };

  value: '';
  cokeYield: any;
  isUser = false;
  isNotPredictedLine: boolean = true;
  chartId: any;
  selectedGraphList: any[] = [];
  ifData: boolean;
  drumState: any;

  constructor(
    private paramService: ParamService,
    private router: Router
  ) {
    this.paramService.checkVal.subscribe((res) => {
      this.ifData = res;
      if (this.ifData == false) {
        this.drumState = 'offline';
      } else {
        this.drumState = 'online';
      }
    });
  }

  ngOnInit(): void {
    this.paramService.getCokeYieldData().subscribe((res) => {
      this.cokeYield = res[0].coke_yields;
      // console.log('Test' + JSON.stringify(res));
    });

    let token = localStorage.getItem('currentUser');
    let subToken = token.substr(2, token.length - 3);
    let decodeToken = jwt_decode(subToken);
    if (decodeToken['first_name'] == 'engineer') {
      this.isUser = true;
    } else {
      this.isUser = false;
    }

    this.paramService.actYield.subscribe((res) => {
      // console.log(res);
      this.cokeYield = res;
    });
  }

  changeInMin() {
    this.onMinInputValueChanged.emit(this.selectedParameter);
  }

  changeInMax() {
    this.onMaxInputValueChanged.emit(this.selectedParameter);
  }

  hover(e, t) {

    this.hovered.emit({ event: e, tag: t, pan: this.pan });

  }
  left(e) {
    this.leave.emit(e);
  }
}
