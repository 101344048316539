<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="d-flex">
  <h1 mat-dialog-title class="mb-0">Modify</h1>
  <i class="fa fa-times cursor-pointer ml-auto" (click)="onNoClick()"></i>
</div>
<div mat-dialog-content>
  <form action="" class="mt-2">
    <div class="row">
      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput type="text" name="name" [(ngModel)]="data.name" required>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Login Id</mat-label>
          <input matInput type="text" name="loginid" [(ngModel)]="data.login_id" disabled>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Email Id</mat-label>
          <input matInput type="text" name="mailid" [(ngModel)]="data.email_id" required>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Designation</mat-label>
          <input matInput type="text" name="designation" [(ngModel)]="data.designation" required>
        </mat-form-field>
      </div>
      <!-- 
      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>User Type</mat-label>
          <mat-select matInput type="text" name="usertype" [(ngModel)]="data.user_type" required>
            <mat-option [value]="role" *ngFor="let role of roles">{{role}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>User Type</mat-label>
          <select matNativeControl name="user_type" [(ngModel)]="data.user_type" required>
            <option class="optionParam" [value]="role" *ngFor="let role of roles">{{role}}</option>
          </select>
          <!-- <mat-error *ngIf="errorHandling('user_type','required')">Please select user type</mat-error> -->
        </mat-form-field>
      </div>

      <!-- <div class="col-md-12">
        <span class="labels-text" style="margin-left: 0.5em;">Email Notification </span><label class="switch"
          style="line-height: 1;">
          <input type="checkbox" checked [(ngModel)]="data.notifications" name="notifications">
          <span class="slider round"></span>
        </label>
      </div> -->

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput type="password" name="password" [(ngModel)]="passwordForm.password" autocomplete="new-password" required>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Re-enter Password</mat-label>
          <input matInput type="password" name="repassword" [(ngModel)]="passwordForm.reEnterPassword" required
            (input)="onRePasswordInput()">
          <mat-error *ngIf="misMatchError">
            Passwords don't match
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
<div mat-dialog-actions class="float-right d-block">
  <button class="btn btn-sm  mx-2 mb-2 d-block purple-button" color="primary" (click)="resetPassword()"
    [disabled]="isResetDisabled()">Reset
    Password</button>
  <button class="btn btn-sm mx-2 d-block purple-button" (click)="updateUser()">Update</button>
</div>