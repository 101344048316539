import { PlantOperatorComponent } from './pages/plant-operator/plant-operator.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { FurnaceComponent } from './pages/furnace/furnace.component';
import { FurnaceOptimiserComponent } from './pages/furnace-optimiser/furnace-optimiser.component';
import { LoginComponent } from './pages/login/login.component';
import { loginComponent} from './pages/Newlogin/login.component';
import {ForgotPasswordComponent} from './pages/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import { SettingConfiguarationComponent } from './pages/setting-configuaration/setting-configuaration.component';
import { AuthGuard } from './core/helpers/auth.guard';
import { AuthGuardService } from './core/helpers/auth1.guard'
import { BalcodashboardComponent } from './pages/balcodashboard/balcodashboard.component';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';
// import { signupComponent } from './pages/Signup/signup.component'
import { UnitComponent } from './cpcroutes/unit/pages/unit/unit.component';



const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'login' },
  { path: 'login', component: loginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  {path: 'reset-password', component: ResetPasswordComponent },
  {
    path: 'dashboard/plant-admin',
    component: DashboardComponent,
    canActivate: [ AuthGuardService ],
  },
  {
    path: 'dashboard/plant-frl',
    component: FurnaceComponent,
    canActivate: [ AuthGuardService ],
  },
  {
    path: 'dashboard/landingpage',
    component: LandingpageComponent,
    canActivate: [ AuthGuardService ],
  },
  {
    path: 'dashboard/furnace-optimiser',
    component: FurnaceOptimiserComponent,
    canActivate: [ AuthGuardService ],
  },
  {
    path: 'dashboard/alf3-dashboard',
    component: BalcodashboardComponent,
    canActivate: [ AuthGuardService ],
  },
  {
    path: 'dashboard/plant-operator',
    component: PlantOperatorComponent,
    canActivate: [ AuthGuardService ],
  },
  {
    path: 'configuaration',
    component: SettingConfiguarationComponent,
    canActivate: [ AuthGuardService ],
  },
  {
    path: 'equipment',
    loadChildren: () =>
      import('src/app/cpcroutes/equipment/equipment.module').then(
        (m) => m.EquipmentModule
      ),
    canActivate: [ AuthGuardService ],
  },
  {
    path: 'adminpanel',
    loadChildren: () =>
      import('./pages/adminpanel/adminpanel.module').then((m) => m.AdminpanelModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'unit',
    loadChildren: () =>
      import('./cpcroutes/unit/unit.module').then((m) => m.UnitModule),
    canActivate: [ AuthGuardService ],
  },
  { path: '**', redirectTo: 'dashboard/landingpage' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
