import { Component, OnInit, Input } from '@angular/core';
import { ParamService } from 'src/app/core/param.service';

@Component({
  selector: 'app-m-c-param-measures',
  templateUrl: './m-c-param-measures.component.html',
  styleUrls: ['./m-c-param-measures.component.scss'],
})
export class MCParamMeasuresComponent implements OnInit {
  @Input() selectedRun: number;
  paramName: any;
  cprz1: any;
  cprz2: any;
  cprz3: any;
  tmtz1: any;
  tmtz2: any;
  tmtz3: any;

  constructor(private paramService: ParamService) {}

  ngOnInit() {}

  ngOnChanges() {
    // if (this.selectedRun == 1) this.paramValue = this.run1;
    // else if (this.selectedRun == 2) this.paramValue = this.run2;
    // else if (this.selectedRun == 3) this.paramValue = this.run3;
    // else if (this.selectedRun == 4) this.paramValue = this.run4;
    this.sorParam();
  }

  sorParam() {
    this.paramService.funrnaceSOR(this.selectedRun).subscribe((res) => {
      this.paramName = res[0][0].json_build_object;
      this.cprz1 = this.paramName.CPR[0].Zone1;
      this.cprz2 = this.paramName.CPR[0].Zone2;
      this.cprz3 = this.paramName.CPR[0].Zone3;
      this.tmtz1 = this.paramName.TMT[0].Zone1;
      this.tmtz2 = this.paramName.TMT[0].Zone2;
      this.tmtz3 = this.paramName.TMT[0].Zone3;
      console.log(this.cprz1);
    });
  }
}
