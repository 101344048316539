import { BalcoService } from './../../core/balco.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParamTableModalComponent } from 'src/app/components/param-table-modal/param-table-modal.component';


@Component({
  selector: 'app-balcotheme1',
  templateUrl: './balcotheme1.component.html',
  styleUrls: ['./balcotheme1.component.scss']
})
export class Balcotheme1Component implements OnInit {

  isLoading: boolean;

  selectButton = ['Pot Temperature', 'Pot Excess Alf3%'];
  selectedButton = this.selectButton[0];
  today: number = Date.now();
  tomorrow: Date = new Date();
  kpis: any;   //made chnges kips to kpis
  values: any;
  tempVal: any;
  xalf3Val: any;
  energyVal: any;
  alf3AddVal: any;
  alumFormVal: any;
  alumTapVal: any;
  anodeVal: any;
  temperatureVal: any;
  inputForm: FormGroup;
  errMesage;
  submitted: boolean;
  tables: any;
  p: number = 1;
  saves: any;
  msg: any;
  dateInvalid: any;
  actualVal: any;
  potNo:any 
  SelectedPot:string;

  
  constructor(
    private toastr: ToastrService,
    private balcoService: BalcoService,
    private object: FormBuilder,
    public dialog: MatDialog
  ) { 
    this.balcoService.potNo.subscribe((res) => {
      this.potNo = res;
      this.getTable();
    });
  }

  get f() {
    return this.inputForm.controls;
  }

  ngOnInit() {
    this.isLoading = true;
    // this.SelectedPot = pot;
    this.getTable();
    this.getActPrediction();
    this.inputForm = this.object.group({
      prediction_date: this.object.control(null, [Validators.required]),
    });
  }

  refreshVal() {
    this.balcoService.getOptimization(this.potNo).subscribe((res) => {
      console.log(res)
     });
  }

  getTable() {
    this.balcoService.getTableValues(this.potNo).subscribe((res) => {
      this.values = res[0];
      this.actualVal = res[1];
      console.log("Pot ON",this.potNo);
      // getting the same data for every call
      console.log("Table data:==",res)
    });
  }

  openParamDialog(): void {
    const dialogRef = this.dialog.open(ParamTableModalComponent, {
      panelClass: 'normal-modal-class',
      data: {
        tables: this.tables,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.inputForm.invalid) {
      return;
    }
    this.isLoading = true;
    this.balcoService.getInputValue(this.inputForm.value).subscribe(
      (data) => {
        // alert('Recommonded Alf3 Next Shift Added successfully!');
        this.inputForm.reset();
        this.getActPrediction();
        this.toastr.success('Date and Time Added successfully!');
      },
      (err) => {
        this.dateInvalid = err.error.message;

        if (this.dateInvalid) {
          this.msg = err.error.message;
          setTimeout(() => {
            this.msg = '';
          }, 2000);
        }
        this.isLoading = false;
      }
    );
  }
  //Realtime Optimization of Smelting Operations
  getActPrediction() {
    this.balcoService.getOptimization(this.potNo).subscribe((res) => {
      this.values = res[0];
      this.actualVal = res[1];      
    });
  }
}