import { Component, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Router } from '@angular/router';
import { DatashareService } from 'src/app/Apicalls/DataSharing/datashare.service';
import { LoginapiService } from 'src/app/Apicalls/LoginApicall/loginapi.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  year: number = new Date().getFullYear();
  username = '';
  password = '';
  newpassword = '';
  confirmpassword = '';
  email = '';
  otp: any;
  loginHandler = '';
  public isTokenavailable: any =
    localStorage.getItem('user') == null ? false : true;
  loginForm: any;
  forgetPwForm: any;
  changePwForm: any;
  authService: any;
  usertimezone: any;
  isLogin = true;
  isOTP = false;
  isPasswordform = false;
  showPassword1 = false;
  showPassword2 = true;
  isValidEmail = true; // Flag to track email validity
  passMessage: any;
  EmailID: any;
  loader = true;
  success: any;
  errorHandler: any;
  passwordCriteria = {
    length: false,
    number: false,
    lowercase: false,
    uppercase: false,
    specialChar: false,
  };
  constructor(
    private router: Router,
    readonly apiCallsService: LoginapiService,
    private dataService: DatashareService
  ) {}
  ngOnInit() {
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
    }, 2000);
    this.dataService.emailID$.subscribe((data) => {
      this.EmailID = data;
    });
    // window.addEventListener('beforeunload', (event) => {
    //   // Redirect the user to another page
    //   this.router.navigate(['/auth/login']);
    //   // Cancel the event
    //   event.preventDefault();
    //   // Chrome requires returnValue to be set
    //   // event.returnValue = '';
    //   localStorage.clear();
    // });
  }
  // Navigate to login page
  // tslint:disable-next-line:typedef
  goToLoginform() {
    this.router.navigate(['/login']);
  }
  containsNumber(password: string): boolean {
    // Check if password contains at least one number
    return /\d/.test(password);
  }
  containsCapitalLetter(password: string): boolean {
    // Check if password contains at least one capital letter
    return /[A-Z]/.test(password);
  }
  containsSpecialCharacter(password: string): boolean {
    // Check if password contains at least one special character
    return /[!@#$%^&*(),.?":{}|<>]/.test(password);
  }
  // tslint:disable-next-line:typedef
  togglePasswordVisibility1() {
    this.showPassword1 = !this.showPassword1;
  }
  // tslint:disable-next-line:typedef
  togglePasswordVisibility2() {
    this.showPassword2 = !this.showPassword2;
  }
  // Function to update the new password
  SubmitNewpw() {
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.usertimezone = timezone;
    if (this.newpassword.length > 0 && this.confirmpassword.length > 0) {
      if (this.newpassword === this.confirmpassword) {
        const formValues = {
          mail_id: this.EmailID,
          password: this.newpassword,
          ui_timezone: this.usertimezone,
        };
        console.log('formValues', formValues);
        this.apiCallsService.updatePassword(formValues).subscribe({
          next: (res) => {
            this.passMessage = res;
            // this.passMessage = this.passMessage.Message;
            if (
              this.passMessage.Message.includes('Password updated successfully')
            ) {
              this.success = this.passMessage.Message;
              this.isLogin = !this.isLogin;
              this.isPasswordform = !this.isPasswordform;
              // this.router.navigate(['/auth/login']);
              // location.reload();
              // window.location.reload();
            }
          },
          error: (err) => {
            console.log(err);
            // this.isLoadingfirst = false; // Ensure loading is set to false on error
            // Extract the error message from the error response
            if (err.error && err.error.Message) {
              this.errorHandler = err.error.Message;
            } else {
              this.errorHandler = 'An error occurred. Please try again later.';
            }
            // this.AdduserMessage = ''; // Clear success message
          },
        });
      } else {
        this.loginHandler = 'Passwords do not match';
        setTimeout(() => {
          this.loginHandler = '';
        }, 2000);
      }
    }
  }
  // validatePassword(input: NgModel) {
  //   // Reset error messages
  //   input.control.setErrors(null);
  //   const password = input.value;
  //   // Check length
  //   if (password.length < 8 || password.length > 24) {
  //     input.control.setErrors({ minlength: true });
  //     return;
  //   }
  //   // Check for at least one uppercase letter
  //   const uppercaseRegex = /[A-Z]/;
  //   if (!uppercaseRegex.test(password)) {
  //     input.control.setErrors({ uppercase: true });
  //     return;
  //   }
  //   // Check for at least one number
  //   const numberRegex = /[0-9]/;
  //   if (!numberRegex.test(password)) {
  //     input.control.setErrors({ number: true });
  //     return;
  //   }
  //   // Check for at least one special character
  //   const specialCharRegex = /[!@#$%^&*]/;
  //   if (!specialCharRegex.test(password)) {
  //     input.control.setErrors({ specialChar: true });
  //     return;
  //   }
  //   // If confirming password, check for password match with new password
  //   if (input.name === 'confirmpassword' && password !== this.password) {
  //     input.control.setErrors({ passwordMismatch: true });
  //     return;
  //   }
  // }
  // tslint:disable-next-line:typedef
  validatePassword(password: string) {
    this.passwordCriteria.length = password.length >= 8 && password.length <= 24;
    this.passwordCriteria.number = /[0-9]/.test(password);
    this.passwordCriteria.lowercase = /[a-z]/.test(password);
    this.passwordCriteria.uppercase = /[A-Z]/.test(password);
    this.passwordCriteria.specialChar = /[!@#$%^&*]/.test(password);
  }
}
