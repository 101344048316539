<app-navbar></app-navbar>
<!-- <div>
  <p class="text-white" style="font-size: 16px">Dashboard</p>
  <p class="text-white mt-n3" style="font-size: 10px">Home/<span style="color: #64bbf9">Dashboard</span></p>
</div> -->
<div class="main-body">
  <img class="logo img-fluid" src="assets/logo/LivNSense Logo.png" />
  <div class="dashBody row pt-1">
    <div class="col d-flex">
      <div class="mr-auto">
        <p class="text-white pt-2 font-weight-bold" style="font-size: 16px">Digital Twins Process Screen</p>
      </div>
      <div>
        <p class="form-control text-light text-center" style="background-color: #393f4f;">Date: {{today | date:
          'dd-MM-yyyy'}}</p>
        <!-- <p class="text-white font-weight-bold">Last Updated Date: <span class="font-weight-normal">{{lastUpdatedDate |
            date: 'dd-MM-yyyy'}}</span></p> -->
      </div>
    </div>
  </div>
  <div class="dashBody row mb-3">
    <!-- <div class="col-md-3 leftPane">
      <app-dcu-live></app-dcu-live>
    </div> -->
    <div class="col-md-9 centerPane text-center">
      <app-factory-image></app-factory-image>
      <app-common-graph></app-common-graph>
    </div>
    <div class="col-md-3 rightPane">
      <app-dcu-yields></app-dcu-yields>
    </div>
  </div>
</div>