<div class="customModal">
    <div class="modal-body">
      <div class="row">
        <div class="col-md-6">
          <h3 class="modal-title font-weight-bold text-white" id="modal-title">{{header}}</h3>
        </div>
        <div class="col-md-6">
          <mat-icon class="close-icon close-button" color="warn" [mat-dialog-close]="true">close</mat-icon>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-9">

        </div>
        <div class="col-3">
          <!-- <select class="selectParam custom-select-sm font-weight-bold" style="display:inline-block;margin-right:7rem;" (change)="changeRun($event.target.value)">
            <option *ngFor="let run of runs| sortBy:'run'" [value]="run">Run - {{run}}</option>
          </select> -->
          <select class="selectParam custom-select-sm font-weight-bold mr-3" style="display:inline-block;" (change)="changeZone($event.target.value)">
            <option *ngFor="let zone of zones" [value]="zone">Zone - {{zone}}</option>
          </select>
        </div>
      </div>
      <div *ngIf="!isLoadingComplete" style="width: 100%; height: 500px;padding-top: 30%;">
        <p class="h5 text-light text-center">Loading...</p>
      </div>
      <app-multi-param-graph *ngIf="isLoadingComplete" [param1]="param1" [param2]="param2" [pName1]="pName1" [pName2]="pName2" [uom1]="uom1" [uom2]="uom2">
      </app-multi-param-graph>
    </div>
  </div>
