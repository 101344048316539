import { BalcoService } from './../../core/balco.service';
import { Component, OnInit, TemplateRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { routerTransition } from '../../router.animations';
import { Router } from '@angular/router';
import * as Chartist from 'chartist';
import { ChartsModule as Ng2Charts } from 'ng2-charts';
import { Chart } from 'chart.js';
import { UserSharingService } from './../../core/user-sharing.service';
import { environment } from 'src/environments/environment';
import { any } from '@amcharts/amcharts4/.internal/core/utils/Array';

declare var name: any;

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss', 'landingpage.css'],
  animations: [routerTransition()],
  providers: [UserSharingService],
})
export class LandingpageComponent implements OnInit {
  today: number = Date.now();
  chart: any = [];
  public canvas: any;
  public ctx: any;
  public randomToken:any;
  public randomString:any;
  public randomString1:any;
  public randomString2:any;
  public randomString3:any;
  public randomString4:any;
  public randomString5:any;
  public randomString6:any;
  public randomString7:any;
  public randomString8:any;
  public randomString9:any;
  public randomString10:any;

  jcbURL = environment.jcbURL;
  weldSenseURL = environment.weldSenseURL;
  cpchemURL = environment.cpchemURL;
  p66URL = environment.p66URL;
  energyURL = environment.energyURL;
  balcoURL  = environment.balcoURL;
  boilerURL =  environment.boilerURL;
  newboilerURL = environment.newboilerURL; 
  pharmaURL = environment.pharmaURL;
  esgURL = environment.esgURL;
  methaneURL= environment.methaneURL;


  isLoading = false;
  pi=true;
  di=true;

  constructor(
    private router: Router,
    private userSharingService: UserSharingService
  ) {

  }

  ngOnInit(): void {
    this.drawLineChart();
    this.drawLineChart2();
    this.drawLineChart1();

    this.drawLineChart3();
    this.randomString='https://demo6-new.livnsense.com';
    console.log('dashboard');
    this.randomString1='https://demo4.livnsense.com';
    this.randomString2='https://demo3-new.livnsense.com';
    this.randomString3='https://demo2-new.livnsense.com';
    this.randomString6='https://dev.boiler.livnsense.com';
  }


  // ngOnInit(): void {
  //   this.drawLineChart();
  //   this.drawLineChart2();
  //   this.drawLineChart1();

  //   this.drawLineChart3();
  //    this.randomString='https://demo6.livnsense.com';
  //   console.log('dashboard');
  //   this.randomString1='https://demo4.livnsense.com';
  //   this.randomString2='https://demo3.livnsense.com';
  //   this.randomString2();
  //   this.randomString3='https://demo2.livnsense.com';
  //   this.randomString3();
  //   this.randomString4();
  //   this.randomString5();
  //   this.randomString6='https://dev.boiler.livnsense.com';
  //   this.randomString6();
  //   this.randomString7();
  //   this.randomString8();
  //   this.randomString9();
  //   this.randomString10();
  // }

  postAdminData(linkURL: any, portal: string) {
    const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    function generateString(length: number) {
      let result = '';
      const charactersLength = characters.length;
      for ( let i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    }
    const tempToken = generateString(5)
    console.log(tempToken);


    if (portal === 'weldSense') {
      // this.isLoading = true;
      // this.userSharingService.updateToken(tempToken).subscribe((res)=>{
      //   console.log(tempToken);
      //   console.log(res);
      // })
      const newData = {project_code:'demo6', token:tempToken};
      this.userSharingService.updateToken(newData).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'weld-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.weldSenseURL;
      document.body.appendChild(iframe);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString = "https://demo6-new.livnsense.com/#/account/login/"+tempToken;
        // this.randomString = "https://demo6.livnsense.com/#/account/login/"+tempToken;

        window.location.href = this.randomString
        // this.randomString = 'http://localhost:4205/#/account/login/'+tempToken;
        // window.location.href = this.randomString
      }, 300);
    }

    else if (portal === 'jcb') {
      // this.isLoading = true;
      const newData1 = {project_code:'demo4', token:tempToken};
      this.userSharingService.updateToken1(newData1).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData1);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'jcb-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.jcbURL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString1 = "https://demo4.livnsense.com/#/login/"+tempToken;
        window.location.href = this.randomString1
        // this.randomString1= 'http://localhost:4231/#/login/'+tempToken;
        // window.location.href = this.randomString1
      }, 300);
    }
    else if (portal === 'cpchem') {
      // this.isLoading = true;
      const newData2 = {project_code:'demo3', token:tempToken};
      this.userSharingService.updateToken2(newData2).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData2);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'cpc-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.cpchemURL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString2 = "https://demo3-new.livnsense.com/#/auth/cpclogin/"+tempToken;
        // this.randomString2 = "https://demo3.livnsense.com/#/auth/cpclogin/"+tempToken;

        window.location.href = this.randomString2
        // this.randomString2= 'http://localhost:4208/#/auth/cpclogin/'+tempToken;
        // window.location = this.randomString2
      }, 300);
    }

    else if (portal === 'p66') {
      // this.isLoading = true;
      const newData3 = {project_code:'demo2', token:tempToken};
      this.userSharingService.updateToken3(newData3).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData3);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'p66-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.p66URL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString3 = "https://demo2-new.livnsense.com/#/auth/p66login/"+tempToken;
        // this.randomString3 = "https://demo2.livnsense.com/#/auth/p66login/"+tempToken;
        window.location.href = this.randomString3
        // this.randomString3= 'http://localhost:4206/#/auth/p66login/'+tempToken;
        // window.location = this.randomString3
      }, 300);
    }

    else if (portal === 'energysense') {
      // this.isLoading = true;
      const newData4 = {project_code:'demo7', token:tempToken};
      this.userSharingService.updateToken4(newData4).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData4);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'energy-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.energyURL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString4 = "https://demo7.livnsense.com/#/auth/p66login/"+tempToken;
        window.location.href = this.randomString4
        // this.randomString4= 'http://localhost:4207/login/'+tempToken;
        // window.location = this.randomString4
      }, 300);
    }

    else if (portal === 'balco') {
      // this.isLoading = true;
      const newData5 = {project_code:'balco', token:tempToken};
      this.userSharingService.updateToken4(newData5).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData5);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'balco-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.balcoURL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString5 = "https://dev.smelting.livnsense.com/#/balcologin/"+tempToken;
        window.location.href = this.randomString5
        // this.randomString5= 'http://localhost:4321/#/balcologin/'+tempToken;
        // window.location = this.randomString5
      }, 300);
    }
    else if (portal === 'boiler') {
      // this.isLoading = true;
      const newData6 = {project_code:'boiler', token:tempToken};
      this.userSharingService.updateToken4(newData6).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData6);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'boiler-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.boilerURL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString6 = "https://dev.boiler.livnsense.com/#/boilerlogin/"+tempToken;
        window.location.href = this.randomString6
        // this.randomString6= 'http://localhost:4222/#/boilerlogin/'+tempToken;
        // window.location = this.randomString6
      }, 300);
    } else if (portal === 'newboiler') {
      // this.isLoading = true;
      const newData6 = {project_code:'newboiler', token:tempToken};
      this.userSharingService.updateToken4(newData6).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData6);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'newboiler-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.newboilerURL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString6 = "https://new-boiler4.0.livnsense.com/#/boiler-login/"+tempToken;
        window.location.href = this.randomString6
        // this.randomString6= 'http://localhost:4222/#/boilerlogin/'+tempToken;
        // window.location = this.randomString6
      }, 300);
    }
    else if (portal === 'pyro') {
      // this.isLoading = true;
      const newData9 = {project_code:'pyro', token:tempToken};
      this.userSharingService.updateToken4(newData9).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData9);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'cement-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.pyroURL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString9 = "https://pyro4.0-new.livnsense.com/#/pyrologin/"+tempToken;
        // this.randomString9 = "https://pyro4.0.livnsense.com/#/pyrologin/"+tempToken;

        window.location.href = this.randomString9
        // this.randomString6= 'http://localhost:4222/#/boilerlogin/'+tempToken;
        // window.location = this.randomString6
      }, 300);
    }
    else if (portal === 'cement') {
      // this.isLoading = true;
      const newData8 = {project_code:'cement', token:tempToken};
      this.userSharingService.updateToken4(newData8).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData8);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'cement-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.esgURL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString8 = "https://dev.esgreport1.livnsense.com/#/cementlogin/"+tempToken;
        // this.randomString8 = "https://dev.esgreport.livnsense.com/#/cementlogin/"+tempToken;

        window.location.href = this.randomString8
        // this.randomString6= 'http://localhost:4222/#/boilerlogin/'+tempToken;
        // window.location = this.randomString6
      }, 300);
    }
    else if (portal === 'methane') {
      // this.isLoading = true;
      const newData10 = {project_code:'methane', token:tempToken};
      this.userSharingService.updateToken4(newData10).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData10);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'cement-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.methaneURL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        this.randomString10 = "https://methanetracking.dev-new.livnsense.com/#/methanelogin/"+tempToken;
        // this.randomString10 = "https://methanetracking.livnsense.com/#/methanelogin/"+tempToken;
        window.location.href = this.randomString10
        // this.randomString6= 'http://localhost:4222/#/boilerlogin/'+tempToken;
        // window.location = this.randomString6
      }, 3000);
    }
    else if (portal === 'pharma') {
      // this.isLoading = true;
      const newData7= {project_code:'pharma', token:tempToken};
      this.userSharingService.updateToken4(newData7).subscribe((res)=>{
        console.log(tempToken);
        console.log(res);
        console.log(newData7);
      })
      const iframe = document.createElement('IFRAME');
      iframe.id = 'pharma-ifr';
      iframe.style.display = 'none';
      (<HTMLIFrameElement>iframe).src = environment.pharmaURL;
      document.body.appendChild(iframe);
      // this.userSharingService.postCrossDomainMessage(linkURL, portal);
      setTimeout(() => {
        // this.isLoading = false;
        // this.randomString6 = "https://pharma.livnsense.com/#/auth/login/"+tempToken;
        // this.randomString7 = 'http://localhost:4548/#/pharmalogin/'+tempToken;
        this.randomString7 = 'https://new-pharma.livnsense.com/#/pharmalogin/'+tempToken;
        window.location.href = this.randomString7

        // window.location = this.randomString5
      }, 300);
    }

  }

  changeRoute(param: string) {
    console.log(param);
    if (param == '0') this.router.navigateByUrl('/dashboard/landing');
    else if (param == '1') this.router.navigateByUrl('/dashboard/plant-frl');
    else if (param == '2') this.router.navigateByUrl('/dashboard/plant-admin');
    else if (param == '3')
      this.router.navigateByUrl('/dashboard/alf3-dashboard');
    else this.router.navigateByUrl('/unit');
  }



  drawLineChart() {
    setTimeout(() => {
      const chartDateLabels = ['2018', '2019', '2020', '2021'];

      chartDateLabels.forEach((result) => {
        this.canvas = document.getElementById('testChart');
        if(this.canvas){
        this.ctx = this.canvas.getContext('2d');

        this.chart = new Chart(this.ctx, {
          type: 'line',

          data: {
            labels: chartDateLabels,
            datasets: [
              {
                label: 'Tags Mapped',
                data: [16129, 18000, 22000, 25000],
                backgroundColor: 'transparent',
                borderColor: '#FFFFFF',
                radius: 0,
                fill: false,
                pointRadius: 4,
                spanGaps: true,
                pointBackgroundColor: "white",
              },
            ],
          },
          options: {
            responsive: true,

            maintainAspectRatio: true,
            title: {
              display: false,
              text: 'Chart.js Line Chart',
            },
            tooltips: {
              mode: 'index',
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {

                  ticks: {
                    display: true,
                    fontColor: '#fff',
                  },
                  display: true,
                  scaleLabel: {
                    display: true,
                    // labelString: "Month",
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: true,
                    fontColor: '#fff',
                  },
                  display: true,

                  scaleLabel: {
                    display: true,
                    // labelString: "Value",
                  },
                },
              ],
            },
          },
        });
          }});
    }, 500);
  }

  drawLineChart3() {
    setTimeout(() => {
      const chartDateLabels = ['2018', '2019', '2020', '2021'];

      chartDateLabels.forEach((result) => {
        this.canvas = document.getElementById('testChart3');
        this.ctx = this.canvas.getContext('2d');

        this.chart = new Chart(this.ctx, {
          type: 'line',

          data: {
            labels: chartDateLabels,
            datasets: [
              {
                label: 'Tags Mapped',
                data: [28000, 43000, 54000, 72000],
                backgroundColor: 'transparent',
                borderColor: '#FFFFFF',
                radius: 0,
                fill: false,
                pointRadius: 4,
                spanGaps: true,
                pointBackgroundColor: "white",
              },
            ],
          },
          options: {
            responsive: true,

            maintainAspectRatio: true,
            title: {
              display: false,
              text: 'Chart.js Line Chart',
            },
            tooltips: {
              mode: 'index',
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {

                  ticks: {
                    display: true,
                    fontColor: '#fff',
                  },
                  display: true,
                  scaleLabel: {
                    display: true,
                    // labelString: "Month",
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: true,
                    fontColor: '#fff',
                  },
                  display: true,

                  scaleLabel: {
                    display: true,
                    labelString: "NO. Of Tags",
                    fontColor: '#fff',
                    fontFamily:'Arial'
                  },
                },
              ],
            },
          },
        });
      });
    }, 500);
  }

  drawLineChart1() {
    setTimeout(() => {
      const chartDateLabels = ['2018', '2019', '2020', '2021'];

      chartDateLabels.forEach((result) => {
        this.canvas = document.getElementById('testChart1');
        this.ctx = this.canvas.getContext('2d');

        this.chart = new Chart(this.ctx, {
          type: 'bar',
          data: {
            labels: chartDateLabels,

            datasets: [
              {
                label: 'ROI',
                data: [7, 15, 33, 37],
                borderDash: [10,5],
                showLine: false,
                backgroundColor: 'transparent',
                borderColor: '#FFFFFF',
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,
              text: 'Chart.js Bar Chart',
            },
            tooltips: {
              mode: 'index',
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {

                  ticks: {
                    display: true,
                    fontColor: '#fff',
                  },
                  display: true,

                  scaleLabel: {

                    display: true,
                    // labelString: "Month",
                  },
                },
              ],
              yAxes: [
                {
                  display: true,
                  ticks: {
                    display: true,
                    fontColor: '#fff',
                  },
                  scaleLabel: {
                    display: true,

                    // labelString: "Value",
                  },
                },
              ],
            },
          },
        });
      });
    }, 500);
  }

  drawLineChart2() {
    setTimeout(() => {
      const chartDateLabels = ['2018', '2019', '2020', '2021'];

      chartDateLabels.forEach((result) => {
        this.canvas = document.getElementById('testChart2');
        this.ctx = this.canvas.getContext('2d');

        this.chart = new Chart(this.ctx, {
          type: 'line',
          data: {
            labels: chartDateLabels,
            datasets: [
              {
                label: 'Emission',
                data: [465, 846, 1526, 2600],
                backgroundColor: 'transparent',
                borderColor: '#FFFFFF',
                pointRadius: 4,
                spanGaps: true,
                pointBackgroundColor: "white",
                radius: 0,
                fill: false,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            title: {
              display: false,
              text: 'Chart.js Line Chart',
            },
            tooltips: {
              mode: 'index',
            },
            hover: {
              mode: 'nearest',
              intersect: true,
            },
            legend: {
              display: false,
            },
            scales: {
              xAxes: [
                {
                  ticks: {
                    display: true,
                    fontColor: '#fff',
                  },
                  display: true,
                  scaleLabel: {
                    display: true,
                    // labelString: "Month",
                  },
                },
              ],
              yAxes: [
                {
                  ticks: {
                    display: true,
                    fontColor: '#fff',
                  },
                  display: true,
                  scaleLabel: {
                    display: true,
                    labelString: "Metric Tons",
                    fontColor: '#fff',
                  },
                },
              ],
            },
          },
        });
      });
    }, 500);
  }
  public chartClicked(e: any): void {
    // console.log(e);
  }

  public chartHovered(e: any): void {
    // console.log(e);
  }
}

