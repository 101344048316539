import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';

const baseUrl = environment['baseUrl'];

@Injectable({
  providedIn: 'root',
})
export class ApiCallsService {
  constructor(private http: HttpClient) {}

  /* Method to add new machine */
  addNewUser(body): Observable<any> {
    return this.http.post(`${baseUrl}users/adduser`, body).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  /* Method to update machine */
  updateUser(body): Observable<any> {
    return this.http.put(`${baseUrl}users/modifyuser`, body).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  /* Method to delete machine */
  deleteUser(loginId): Observable<any> {
    return this.http.delete(`${baseUrl}users/delete/${loginId}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  modifyPassword(body) {
    return this.http.put(`${baseUrl}users/modify_password`, body).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  /* Method to get all the users */
  getAllUsers(): Observable<any> {
    return this.http.get(`${baseUrl}users/get_all_users`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getAllPermissions() {
    return this.http.get(`${baseUrl}users/access`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  updatePermissions(body) {
    return this.http.put(`${baseUrl}users/access/update`, body).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }
}
