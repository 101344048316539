<div class="customModal" appGraphDraggable>
    <div class="modal-body">
        <div class="row">
            <div class="col-md-6">
                <h3 class="modal-title font-weight-bold text-white" id="modal-title">{{selectedParameter}}</h3>
            </div>
            <div class="col-md-6">
                <button type="button" class="close" aria-label="Close button" data-dismiss="modal"
                    (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div class="row mb-2">
            <!-- <div class="w-50 ml-auto pr-4 float-right">
                <select class="form-control custom-select-sm" [(ngModel)]="selectedParameter"
                    (change)="changeParameter()">
                    <option *ngFor="let param of fromParamTable" [value]="param.parameter">{{param.parameter |
                        replaceUnderscore}}</option>
                </select>
            </div> -->
        </div>
        <div *ngIf="!isLoadingComplete" style="width: 100%; height: 500px;padding-top: 30%;">
            <p class="h5 text-light text-center">Loading...</p>
            <!-- <mat-progress-bar class="w-50 mx-auto" style="height: 10px;" mode="indeterminate"></mat-progress-bar> -->
        </div>
        <app-actual-parameter-graph *ngIf="isLoadingComplete" [chartId]="chartId" [graphData]="graphData">
        </app-actual-parameter-graph>
    </div>
</div>