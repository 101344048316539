import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';


//const baseUrl = environment['baseUrl'];

@Injectable({
  providedIn: 'root',
})
export class BalcoService {
  [x: string]: any;
  private balcobaseurl = 'https://api.smelting.livnsense.com/';
  public potNo = new BehaviorSubject(1301);
  getSelectedAlgoConfig: any;

  constructor(private http: HttpClient) {}

  //Realtime Optimization of Smelting Operations
  getOptimization(potNo): Observable<any> {
    return this.http.get(`${this.balcobaseurl}dashboard/alf_pred?pot=${potNo}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getROptimization(potNo): Observable<any> {
    return this.http.get(`${this.balcobaseurl}dashboard/save?pot=${potNo}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  

  //Revenue Realization of Improved Operations
  getRealization(potNo): Observable<any> {
    return this.http.get(`${this.balcobaseurl}kpi/revenue/${potNo}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getInputValue(body) {
    return this.http.post<any>(`${this.balcobaseurl}dashboard/alf_pred?`, body).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getTableValues(potNo): Observable<any> {
    return this.http.get(`${this.balcobaseurl}dashboard/alf_pred?pot=${potNo}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }
 

  getSaveTable(): Observable<any> {
    return this.http.get(`${this.balcobaseurl}kpi/roi`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  postPotValue(body) {
    return this.http.post<any>(`${this.balcobaseurl}kpi/temperature`, body).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getLatestData(potNo): Observable<any> {
    return this.http.get(`${this.balcobaseurl}dashboard/latest_data/${potNo}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }

  getOverallTable(potNo,sd,ed): Observable<any> {
    return this.http.get(`${this.balcobaseurl}dashboard/overall_data/${potNo}?start_date=${sd}&end_date=${ed}`).pipe(
      map(
        (response: any) => {
          return response;
        },
        (error) => {
          console.log(error);
        }
      )
    );
  }


  //For Data Upload Utility i.e shift and lab

  uploadFiles(_type:any, fd:any): Observable<any> {
  
   return this.http.post<any>(`${this.balcobaseurl}kpi/file_upload?type=${_type}`,fd, {observe: "response"})
    .pipe(catchError(this.handleError))

  }
  handleError(error){
    return throwError(error.message)
  }
  
}


