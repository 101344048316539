<section class="border-0">
  <div class="table-responsive" id="demo">
    <table class="table table-borderless tableBody">
      <thead class="text-primary">
        <tr class="mb-3">
          <td scope="col" style="width: 40%;"></td>
          <td scope="col" class="h5 text-right font-weight-bold" style="width: 20%;">Zone 1</td>
          <td scope="col" class="h5 text-right font-weight-bold" style="width: 20%;">Zone 2</td>
          <td scope="col" class="h5 text-right font-weight-bold" style="width: 20%;">Zone 3</td>
        </tr>
      </thead>
      <tbody>
        <tr class="table-data trow" *ngFor="let paramValue of paramValues | keyvalue: returnZero"
          (click)="openSingleParamDialog(paramValue.key)">
          <!-- Key: <b>{{paramValue.key}}</b>
              <div *ngFor="let item1 of paramValue.value">
                  {{item1.Zone1}}
                </div> -->
          <ng-container *ngFor="let param of paramValue.value">
            <td class="h4 cellBorder font-weight-bold text-left">{{paramValue.key}}<span class="h6 font-weight-bold px-2">{{param.uom}}</span></td>
          <td class="h4 cellBorder font-weight-bold text-right">{{param.Zone1 |
                number: '1.2-2'}}</td>
          <td class="h4 cellBorder font-weight-bold text-right">{{param.Zone2 |
                number: '1.2-2'}}</td>
          <td class="h4 cellBorder font-weight-bold text-right">{{param.Zone3 |
                number: '1.2-2'}}</td>
          </ng-container>
        </tr>
      </tbody>
    </table>
  </div>
</section>
