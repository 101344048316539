<div class="loads">
  <p class="h6 text-light">Loading....</p>
    <div class="loader">
    </div>

  <!-- <p class="h5 text-light font-weight-bold py-1">DCU/FRL Process Data Ware House Ready –Input Feed...Done</p>

  <p class="h5 text-light font-weight-bold py-1">DCU/FRL Process Data Ware House Ready - Heater...Done</p>

  <p class="h5 text-light font-weight-bold py-1">DCU/FRL Process Data Ware House Ready - CokeDrum...Done</p>

  <p class="h5 text-light font-weight-bold py-1">DCU/FRL Process Data Ware House Ready - Factionator...Done</p>

  <p class="h5 text-light font-weight-bold py-1">DCU/FRL Process Data Ware House Ready - FRL...Done</p>

  <p class="h5 text-light font-weight-bold py-1">DCU/FRL Process Data Correlation Established - CCR, Temprature & FRL data...Done</p>

  <p class="h5 text-light font-weight-bold py-1">ML Model Started to Build.......</p>

  <p class="h5 text-light font-weight-bold py-1">AI Predictions Mode Activated...</p>

  <p class="h5 text-light font-weight-bold py-1">AI Recommendation Successfully Done........</p> -->

  <!-- <div *ngFor="let step of steps" class="mb-2">
    <span class="text-white">{{ step }}</span>
    <div class="progress">
      <div
        class="progress-bar progress-bar-info progress-bar-striped"
        role="progressbar"
        attr.aria-valuenow="{{ counter }}"
        aria-valuemin="0"
        aria-valuemax="100"
        [ngStyle]="{ width: counter + '%' }"
      >
        {{ counter }}%
      </div>
    </div>
  </div> -->

</div>
