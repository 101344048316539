import {Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { DatashareService } from 'src/app/Apicalls/DataSharing/datashare.service';
import { LoginapiService } from 'src/app/Apicalls/LoginApicall/loginapi.service';
import jwt_decode from "jwt-decode";
import { AuthenticationService } from '../../core/authentication.service';
import { NotificationService } from '../../core/notification.service';


@Component({
  selector: 'app-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: `./login.component.html`
})
// tslint:disable-next-line:class-name
export class loginComponent implements OnInit{
  public isTokenavailable: any =
    localStorage.getItem('user') == null ? false : true;
  isLoading: boolean = false;
  year: number = new Date().getFullYear();
  username = '';
  password = '';
  token = localStorage.getItem('user');
  lastLogin: any;
  tokenEmail: any;
  loginHandler = '';
  showPassword = false;
  decodeToken: any;

  constructor(
    private router: Router,
    readonly apiCallsService: LoginapiService,
    private dataService: DatashareService,
    private authenticationService: AuthenticationService,
    private notifyService: NotificationService
  ) {}
  ngOnInit() {
    localStorage.clear();
    setTimeout(() => {
      if (this.isTokenavailable) {
        location.reload();
        this.isTokenavailable = true;
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }, 100);
  }
  // Function to parse token
  parseToken() {
    const token: any = this.token;
    const tokenPayload = token.split('.')[1];
    const decodedPayload = atob(tokenPayload);
    const decodedPayloadJson = JSON.parse(decodedPayload);
    this.lastLogin = decodedPayloadJson.last_login;
    this.tokenEmail = decodedPayloadJson.email_id;
  }


  
  // Method to toggle between show and hide password
  // tslint:disable-next-line:typedef
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }
  // Method to login
  // tslint:disable-next-line:typedef
  login() {
    this.isLoading=true;
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (this.username.length > 0 && this.password.length > 0) {
      const formValues = {
        username: this.username,
        password: this.password,
        ui_timezone: timezone,
      };
      this.apiCallsService.login(formValues).subscribe({
        next: (data: any) => {
          localStorage.setItem('user', JSON.stringify(data));
          const token: any = localStorage.getItem('user');
          const tokenPayload = token.split('.')[1];
          const decodedPayload = atob(tokenPayload);
          const decodedPayloadJson = JSON.parse(decodedPayload);
          this.lastLogin = decodedPayloadJson.last_login;
          this.tokenEmail = decodedPayloadJson.email_id;
          if (this.lastLogin == null) {
            this.dataService.setemailID(this.tokenEmail);
            this.router.navigate(['/reset-password']);
            this.isLoading = false;
          } else {
            this.router.navigate(['dashboard/landingpage']);
          }
        },
        error: (err: { error: { Message: string } }) => {
          this.isLoading=false;
          this.loginHandler = err.error.Message;
          setTimeout(() => {
            this.loginHandler = '';
          }, 5000);
        },
      });
    } else {
      this.isLoading = false;
    }
  }
  // Navigate to forgot password page
  // tslint:disable-next-line:typedef
  goToForgotPasswordform() {
    this.router.navigate(['/forgot-password']);
  }
}
