<div class="card border-0 my-3">
  <div class="card-header p-1">
    <p class="h6 pt-2 text-white" style="font-weight: bold;">PROCESS ANALYSIS & SYNTHESIS</p>
  </div>
  <div class="card-body">
    <div class="row pt-0 pb-0" *ngIf="!isUser">
      <div class="col-md-3 pat" [class.disabled-content]="!ifData" (click)="openCritical('fu')">
        <div class="icon">
          <i class="fas fa-project-diagram" style="font-size: 25px; color: #ff8000"></i>
        </div>
        <p class="h6 pt-2 font-weight-bold pat-title">CRITICAL PARAMETER
          ANALYSIS</p>
      </div>
      <!-- <div class="col-md-6 cardBar">
        <div class="row"> -->
      <div class="col-md-3 cardBar pat" [class.disabled-content]="!ifData" (click)="openMainYield(actualMainYield, 'actual')">
        <div class="icon">
          <i class="far fa-chart-bar" style="font-size: 25px; color: #ff8000"></i>
        </div>
        <p class="h6 pt-2 font-weight-bold pat-title">ACTUAL YIELDS</p>
      </div>
      <div class="col-md-3 cardBar pat" [class.disabled-content]="!ifData" (click)="openMainYield(preRecMainYield, 'preRec')">
        <div class="icon">
          <i class="fas fa-chart-pie" style="font-size: 25px; color: #ff8000"></i>
        </div>
        <p class="h6 pt-2 font-weight-bold pat-title">YIELD PREDICTION ON ACTUAL DATA</p>
      </div>
      <!-- </div>
      </div> -->
      <div class="col-md-3 cardBar pat" (click)="openMainYield(postRecMainYield, 'postRec')"
        [class.disabled-content]="!strAlgo">
        <div class="icon">
          <i class="fas fa-chart-line" style="font-size: 25px; color: #ff8000"></i>
        </div>
        <p class="h6 pt-2 font-weight-bold pat-title">YIELD PREDICTION AFTER PARAMETER CHANGES</p>
      </div>
    </div>

    <div class="row pt-0 pb-0" *ngIf="isUser">
      <div class="col-md-6 pat" (click)="openMainYield(preRecMainYield, 'preRec')">
        <div class="icon">
          <i class="fas fa-chart-pie" style="font-size: 25px; color: #ff8000"></i>
        </div>
        <p class="h6 pt-2 font-weight-bold pat-title">YIELD PREDICTION ON ACTUAL DATA</p>
      </div>
      <div class="col-md-6 cardBar pat" (click)="openMainYield(postRecMainYield, 'postRec')">
        <div class="icon">
          <i class="fas fa-chart-line" style="font-size: 25px; color: #ff8000"></i>
        </div>
        <p class="h6 pt-2 font-weight-bold pat-title">YIELD PREDICTION AFTER PARAMETER CHANGES</p>
      </div>
    </div>
  </div>
</div>