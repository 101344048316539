<div class="customModal" appGraphDraggable>
    <div class="modal-body">
        <div class="row">
            <div class="col d-flex">
                <div class="form-group">
                    <select id="my-select" class="selectParam custom-select" name="predictionPeriod"
                        [(ngModel)]="predictionPeriod" (change)="changePeriod()">
                        <option *ngFor="let period of periodList" [value]="period">{{period}}</option>
                    </select>
                </div>
                <p class="h4 text-light mx-auto">Parameter Recommendation</p>
                <button type="button" class="close text-right" aria-label="Close button" data-dismiss="modal"
                    (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
        </div>
        <div *ngIf="!isLoadingComplete" style="width: 100%; height: 500px;padding-top: 30%;">
            <p class="h5 text-light text-center">Loading...</p>
        </div>
        <div *ngIf="isLoadingComplete">
            <app-predicted-main-yield-graph *ngIf="predictionPeriod =='Daily'">
            </app-predicted-main-yield-graph>
            <app-predicted1-main-yield-line-graph *ngIf="predictionPeriod !='Daily'" [startDate]="startDate"
                [endDate]="endDate" [graphData]="graphData" [chartId]="chartId" [period]="period"></app-predicted1-main-yield-line-graph>
        </div>
    </div>
</div>