<div class="card border-0">
  <div class="card-header">

    <div class="row">

      <div class="col-md-6">
        <p class="h6 text-white font-weight-bold ">Current Temperature</p>
        <p class="font-weight-bold" style="color: #1FC67A; font-size: 1.1rem;">{{actualVal.actual_temperature}} &#8451;</p>
      </div>
      <div class="col-md-6 cardBar">
        <p class="h6 text-white font-weight-bold ">Current Excess ALF3</p>
        <p class="font-weight-bold" style="color: #1FC67A; font-size: 1.1rem;">{{actualVal.actual_excess_alf3 | number: '1.0-3'}} %</p>
      </div>

  </div>
  </div>
  <div class="card-body">
    <div class="col">
    <div class="row ">
      <div class="col-md-4"><p class="h6 text-white font-weight-bold ">Predicted Temperature</p></div>
      <div class="col-md-4"><p class="h6 text-white ">{{values.date|date:'medium' }}</p></div>
      <div class="col-md-4"><p class="font-weight-bold" style="color: #1FC67A; font-size: 1.1rem;">{{actualVal.actual_temperature}} &#8451;</p></div>
    </div>
    <div>
    <app-balco-furnace-svg></app-balco-furnace-svg>
          <!-- <ellipse style="cursor: pointer;" (click)="openDialog($event)"  xmlns="http://www.w3.org/2000/svg" cx="638.75" cy="327.5" rx="5.5" ry="5.5" fill="#ffffff" stroke="#000000" pointer-events="all"/> -->

        </div>
  </div>
</div>

</div>
