<app-navbar></app-navbar>
<div class="main-body">
  <div class="dashBody row pt-1">
    <div class="col d-flex">
      <div class="mr-auto">

        <span style="display: flex;flex-direction:row;justify-content:space-between;">
        <!-- <span style="display: flex;flex-direction:row;justify-content:space-between;"><a href='https://demo1.livnsense.com/#/dashboard/landingpage'> -->
          <a href='#/dashboard/landingpage'><mat-icon style="margin: 12px;background-color: white;border-radius:50px ;">arrow_back</mat-icon></a>
          <p class="text-white pt-2 font-weight-bold" style="font-size: 16px;margin-top: 5px;">
            Digital Twins FRL Optimizer</p>
        </span>

      </div>
      <div>
        <p class="form-control text-light text-center" style="background-color: #393f4f;">Date: {{today | date:
          'dd-MM-yyyy'}}</p>
      </div>
    </div>
  </div>
  <div class="dashBody row mb-3">
    <div class="col-md-3 leftPane">
                  <!-- start-->
                  <div (click)="changeProcess()" class="switch" [ngClass]="{ 'checked': live }" style="margin-top: 1rem;">
                    <input type="checkbox" class="switch-input" [value]="live" [attr.checked]="live">
                    <span class="switch-label" data-on="Live" data-off="Batch Data"></span>
                    <span class="switch-handle"></span>
                  </div>
                  <ng-container *ngIf="!live">
                    <select name="selectedRun1" [(ngModel)]="selected" (change)="changeRun($event)">
                      <option *ngFor="let run of batchRuns | sortBy:'run'" [value]="run" [selected]="run == selected">Run -
                        {{run}}
                      </option>
                    </select>
                  </ng-container>


                  <div class="ml-auto pt-3">

                      <span class="text-white" *ngIf="live">On Day 7</span>

                      <span class="text-white" *ngIf="!live">Run Length {{days | number: '1.0-0'}} Days</span>

                  </div>

                  <!--End-->

        <p class="font-weight-normal">Critical Parameters</p>
        <app-s-param-measures [selectedRun]="selected"></app-s-param-measures>
        <app-m-param-measures [selectedRun]="selected"></app-m-param-measures>
    </div>
    <div class="col-md-6 centerPane text-center mt-1">
      <p class="h2 text-white font-weight-bold pb-1">Furnace Run Length</p>
      <!-- <div class="item-hints toolTip">
        <div class="row hint" data-position="4">

          <i class="fas fa-info-circle text-white"></i>
          <div class="hint-content ">
            <p class="tooltipMsg p-2 rounded">Click on button to start AI based Engine for Recommendation
            </p>
          </div>
        </div>
      </div> -->

      <div class="mt-5">
        <app-furnace-img></app-furnace-img>
      </div>

    </div>
    <div class="col-md-3 rightPane">

      <div class="row ml-2 mt-3">
      <button type="button" class="mb-2 mr-2 btn btn-success" (click)="predictParam()">Runlength Prediction</button>
      <div class="ml-2"></div>
      <div *ngIf="predlive ? predshow : !predshow" >
      <button type="button" class="mb-2 mr-2 btn btn-success" (click)="optimizedParam()">Optimize</button>
    </div>
      </div>
      <div><hr/></div>
      <div *ngIf="predlive ? !predshow : predshow" class="row h-10 text-center">
        <p *ngIf="!loading" class="text-white pl-3">Click on the Button for the predictions</p>

        <mat-spinner class="my-auto mx-auto" *ngIf="loading"></mat-spinner>
      </div>
      <div *ngIf="predlive ? predshow : !predshow" >
        <app-actual-predicted-measures></app-actual-predicted-measures>
      </div>
      <!-- app optimized start-->
      <div *ngIf="predlive ? predshow : !predshow" >
        <div *ngIf="optlive ? !show : show" class="row h-10 text-center">
          <p *ngIf="!loading" class="text-white pl-3">Click on Optimize Button</p>

          <mat-spinner class="my-auto mx-auto" *ngIf="loading"></mat-spinner>
        </div>
      </div>
      <div *ngIf="optlive ? show : !show" >
        <div>

              <span class="text-white pt-2 font-weight-bold mt-3 mb-5" style="font-size: 16px">Run Length {{predDays | number: '1.0-0'}} Days</span>
              <app-optimized-measures></app-optimized-measures>
        </div>
      </div>
      <!-- app optimized end-->

    </div>
  </div>
  <div col-12 ml-2 class="text-white"> © LivNSense GreenOps Pvt. Ltd. All Rights Reserved. </div>
</div>
