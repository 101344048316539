import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';
import { ApiCallsService } from '../../services/api-calls.service';

@Component({
  selector: 'app-access-configuration',
  templateUrl: './access-configuration.component.html',
  styleUrls: ['./access-configuration.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccessConfigurationComponent implements OnInit {

  allPermissions: any = {};
  sections: any;
  initialPermissions: string;

  // notificationViewFilters: any[] = [{ name: '1 Day', value: 1 }, { name: '2 Days', value: 2 }, { name: '3 Days', value: 3 }, { name: '7 Days', value: 7 }];

  // maxNotificationDownload: any[] = [{ name: '30 Days', value: 30 }, { name: '60 Days', value: 60 }, { name: '120 Days', value: 120 }];


  constructor(private apiCallsService: ApiCallsService, private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getAllPermissions();
  }

  getAllPermissions() {
    this.apiCallsService.getAllPermissions().subscribe(data => {
      this.allPermissions = data;
      this.initialPermissions = JSON.stringify(data);
      const extras = ['Default Notification View Timer Period', 'Maximum Values Notification Download Time Period', 'Password Expiry Period'];
      this.sections = Object.keys(data).filter(k => extras.indexOf(k) < 0);
    }, err => {
      const extras = ['Default Notification View Timer Period', 'Maximum Values Notification Download Time Period', 'Password Expiry Period'];
      this.allPermissions = {"Right Pane": [{"feature": "Left Pane : Module Access", "Super Admin": true, "Admin": true, "Non Admin": true}, {"feature": "Right Pane: Recommendations", "Super Admin": true, "Admin": true, "Non Admin": true}, {"feature": "Right Pane: Critical Trends", "Super Admin": true, "Admin": true, "Non Admin": true}, {"feature": "Button: Target HGI", "Super Admin": true, "Admin": true, "Non Admin": true}, {"feature": "Mid Pane: Model Alerts", "Super Admin": true, "Admin": true, "Non Admin": true}, {"feature": "Numbers On Click trending", "Super Admin": true, "Admin": true, "Non Admin": true}], "Left Pane": [{"feature": "System Overview", "Super Admin": true, "Admin": true, "Non Admin": true}, {"feature": "Equipment", "Super Admin": true, "Admin": true, "Non Admin": true}, {"feature": "Reporting", "Super Admin": true, "Admin": true, "Non Admin": true}, {"feature": "Benchmarking", "Super Admin": true, "Admin": true, "Non Admin": true}], "Default Notification View Timer Period": 1, "Maximum Values Notification Download Time Period": 120, "Password Expiry Period": 60};
      this.initialPermissions = JSON.stringify(this.allPermissions);
      this.sections = Object.keys(this.allPermissions).filter(k => extras.indexOf(k) < 0);
      console.log(err);
      this.openSnackBar(err['error']);
    })
  }

  openSnackBar(res) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1500,
      data: res,
      horizontalPosition: 'start',
      verticalPosition: 'bottom'
    });
  }

  isUpdateDisabled() {
    if (typeof this.allPermissions !== 'undefined') {
      return JSON.stringify(this.allPermissions) !==
        this.initialPermissions
        ? false
        : true;
    }
  }

  updatePermissions() {
    this.apiCallsService.updatePermissions(this.allPermissions).subscribe(data => {
      this.openSnackBar(data);
    }, err => {
      console.log(err);
      this.openSnackBar(err['error']);
    })
  }
}
