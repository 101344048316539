<div class="customModal">
    <div class="modal-body">
      <div class="d-flex">
        <div class="ml-auto">
          <mat-icon class="close-icon close-button" color="warn" [mat-dialog-close]="true">close</mat-icon>
        </div>
      </div>
      <div class="text-center">
        <div class="text-warning"><mat-icon class="warning">warning</mat-icon></div>
        <div class="h4 text-white py-1">Furnace is in De-coking State. No Data Available</div>
        <div class="h5 text-warning py-1">Please select a different date.</div>
     </div>
    </div>
  </div>
