import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { interval } from 'rxjs';
import { take } from 'rxjs/operators';
import { DatashareService } from 'src/app/Apicalls/DataSharing/datashare.service';
import { LoginapiService } from 'src/app/Apicalls/LoginApicall/loginapi.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {
  year: number = new Date().getFullYear();
  email = '';
  otp: any;
  loginHandler = '';
  public isTokenavailable: any =
    localStorage.getItem('user') == null ? false : true;
  loginForm: any;
  forgetPwForm: any;
  changePwForm: any;
  authService: any;
  usertimezone: any;
  isLogin = true;
  isOTP = false;
  isPasswordform = false;
  showPassword = false;
  isValidEmail = true; // Flag to track email validity
  emailMessage: any;
  EmailMessage: any;
  otpMessage: any;
  OTPMessage: any;
  remember = false;
  isOTPSent = false;
  countdown = 0;
  loader = true;
  errorHandler: any;
  constructor(
    private router: Router,
    readonly apiCallsService: LoginapiService,
    private dataService: DatashareService
  ) {}
  ngOnInit() {
    this.loader = true;
    setTimeout(() => {
      this.loader = false;
    }, 2000);
    this.remember = true;
    localStorage.clear();
    setTimeout(() => {
      if (this.isTokenavailable) {
        location.reload();
        this.isTokenavailable = true;
        localStorage.clear();
        this.router.navigate(['/login']);
      }
    }, 100);
  }
    // Navigate to login page
    goToLoginform() {
      this.router.navigate(['/login']);
    }
    // Function to get the otp in the mail
    getOTPform() {
      const formValues = {
        mail_id: this.email,
      };
      this.dataService.setemailID(this.email);
      this.apiCallsService.verifyEmail(formValues).subscribe({
        next: (res) => {
          this.emailMessage = res;
          this.EmailMessage = this.emailMessage.Message;
          if (
            this.emailMessage.Message.includes(
              'An OTP has been sent to your email'
            )
          ) {
            this.isOTP = true;
            this.showPassword = true;
            this.remember = false;
            this.startCountdown();
          }
        },
        error: (err) => {
          console.log(err);
          // this.isLoadingfirst = false; // Ensure loading is set to false on error
          // Extract the error message from the error response
          if (err.error && err.error.Message) {
            this.errorHandler = err.error.Message;
          } else {
            this.errorHandler = 'An error occurred. Please try again later.';
          }
          // this.AdduserMessage = ''; // Clear success message
        },
      });
    }
    // Function to set the 30 seconds to countdown to disable the resend otp
  // tslint:disable-next-line:typedef
    startCountdown() {
      this.isOTPSent = true;
      this.countdown = 30; // 30 seconds countdown
      const timer = interval(1000).pipe(take(30)); // Create an observable that emits every second for 30 seconds
      timer.subscribe({
        next: () => {
          this.countdown -= 1;
        },
        complete: () => {
          this.isOTPSent = false;
          this.countdown = 0; // Reset countdown after completion
        },
      });
    }
    // Function to resend the otp to the mail
  // tslint:disable-next-line:typedef
    resendOTP() {
      if (!this.isOTPSent) {
        this.getOTPform();
      }
    }
    // Function to verify the otp and navigate to reset password page
  // tslint:disable-next-line:typedef
    goToResetPasswordform() {
      // this.router.navigate(['/auth/reset-password']);
      const formValues = {
        otp: this.otp,
      };
      this.apiCallsService.otpverifyEmail(formValues).subscribe({
        next: (res) => {
          this.otpMessage = res;
          this.OTPMessage = this.otpMessage.Message;
          if (this.otpMessage.Message.includes('OTP Verified Successfully')) {
            this.router.navigate(['/reset-password']);
          }
        },
        error: (err) => {
          console.log(err);
          // this.isLoadingfirst = false; // Ensure loading is set to false on error
          // Extract the error message from the error response
          if (err.error && err.error.Message) {
            this.errorHandler = err.error.Message;
          } else {
            this.errorHandler = 'An error occurred. Please try again later.';
          }
          // this.AdduserMessage = ''; // Clear success message
        },
      });
    }
    // Method to check if email is in valid format
    isValidEmailFormat(email: string): boolean {
      // Regular expression for email validation
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
}
