<app-navbar></app-navbar>

<div class="main-body">

  <div class="dashBody row m-3 mt-0">

    <div *ngIf="live" class="col-md-6">
      <div class="card border-0">
        <div class="container-fluid pt-2" style="background-color: #272B4A;">
          <div class="d-flex">
            <p class="h1 text-success font-weight-bold ml-auto">Actual Predicted</p>
          </div>


          <div class="pt-3 px-3 row">

            <div class="ml-auto pt-2">
              <p class="font-weight-bold text-white" style="font-size: 3.4rem"><span class="pr-3"
                  style="font-weight: bolder; font-size:1.8rem">Run
                  Length</span>{{days | number: '1.0-0'}} <span
                  style="font-weight: bolder; font-size:1.8rem">Days</span></p>
            </div>

          </div>

          <div class="mt-4">

              <app-actual-predicted-measures></app-actual-predicted-measures>

          </div>

        </div>
      </div>
    </div>

    <div *ngIf="!live" class="col-md-6">
      <div class="card border-0">
        <div class="container-fluid pt-2" style="background-color: #272B4A;">
          <div class="d-flex">
            <div [ngSwitch]="selected">
              <p class="h1 text-success font-weight-bold" *ngSwitchCase="6">Live</p>
              <p class="h1 text-white font-weight-bold" *ngSwitchDefault>Run - {{selected}}</p>
            </div>
            <p class="h1 text-success font-weight-bold ml-auto">Actual</p>
          </div>


          <div class="pt-3 px-3 row">

            <div class="ml-auto pt-2">
              <p class="font-weight-bold text-white" style="font-size: 3.4rem"><span class="pr-3"
                  style="font-weight: bolder; font-size:1.8rem">Run
                  Length</span>{{days | number: '1.0-0'}} <span
                  style="font-weight: bolder; font-size:1.8rem">Days</span></p>
            </div>

          </div>

          <div class="mt-4">
            <fieldset class="scheduler-border">
              <legend class="scheduler-border">Critical Parameters</legend>
              <app-actual-measures></app-actual-measures>
            </fieldset>
          </div>

        </div>
      </div>

    </div>

    <div class="col-md-6">

      <div *ngIf="live ? !show : show" class="row h-100 text-center">
        <p *ngIf="!loading" class="h1 text-white my-auto mx-auto">Click on Optimize Button</p>
        <mat-spinner class="my-auto mx-auto" *ngIf="loading"></mat-spinner>
      </div>

      <div *ngIf="live ? show : !show" class="card border-0">
        <div class="container-fluid pt-2" style="background-color: #272B4A;">
          <div class="d-flex">
            <p class="h1 text-warning font-weight-bold ml-auto">Optimized & Predicted</p>
          </div>

          <div class="pt-3 px-3 row">
            <div class="ml-auto pt-2">
              <p class="font-weight-bold text-white" style="font-size: 3.4rem"><span class="pr-3"
                  style="font-weight: bolder; font-size:1.8rem">Run
                  Length</span>{{predDays | number: '1.0-0'}} <span
                  style="font-weight: bolder; font-size:1.8rem">Days</span></p>
            </div>
          </div>

          <div class="mt-4">
            <fieldset class="scheduler-border">
              <legend class="scheduler-border">Critical Parameters</legend>
              <app-optimized-measures></app-optimized-measures>
            </fieldset>
          </div>

        </div>
      </div>
    </div>
  </div>

</div>

<div class="row btnPos">
  <div class="col-md-4">
    <button [disabled]="live && optimized" *ngIf="live" class="button text-center text-light font-weight-bold"
      (click)="optimizedParam()">Optimize</button>
  </div>

  <div class="col-md-4">
    <button class="button text-center text-light font-weight-bold" (click)="changeRoute('plant-frl')">Furnace</button>
  </div>
  <div class="col-md-4">
    <button [disabled]="live && !optimized" class="button text-center text-light font-weight-bold" (click)="changeRoute('furnace-efficiency')" >Furnace
      Efficiency</button>
  </div>
</div>
