import { ParamService } from 'src/app/core/param.service';
import { Component, OnInit, SimpleChanges } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { BalcoService } from './../../core/balco.service';


export interface DialogData {
  animal: string;
  name: string;
}


@Component({
  selector: 'app-balco-furnace-image',
  templateUrl: './balco-furnace-image.component.html',
  styleUrls: ['./balco-furnace-image.component.scss'],
})
export class BalcofurnaceImageComponent implements OnInit {
  
  potNo:any;
  actualVal: any;
  values: any;

  constructor(
    private balcoService: BalcoService
  ) { 
    this.balcoService.potNo.subscribe((res) => {
      this.potNo = res;
      this.getTable();
      console.log("Table data:==",res)
    });
  }
  ngOnInit(): void {


  }
  getTable() {
    this.balcoService.getTableValues(this.potNo).subscribe((res) => {
      this.values = res[0];
      this.actualVal = res[1];
      console.log("Pot ON",this.potNo);
      // getting the same data for every call
      console.log("Table data:==",res)
    });
  }
  
}
