import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ParamService } from 'src/app/core/param.service';

@Component({
  selector: 'app-furnace-optimiser',
  templateUrl: './furnace-optimiser.component.html',
  styleUrls: ['./furnace-optimiser.component.scss'],
})
export class FurnaceOptimiserComponent implements OnInit {
  today: number = Date.now();
  private runs: string[];
  public show: boolean = false;
  paramValue: any;
  selectedProcess: any;
  days: any;
  counter: any;
  selected: any;
  live: boolean;
  predDays = 0;
  clicked = false;
  optimized = false;
  loading = false;

  constructor(private router: Router, private paramService: ParamService) {}

  ngOnInit(): void {
    this.paramService.selectedRun.subscribe((res) => {
      this.selected = res;

      if (res === 6) {
        this.live = true;
      }
    });

    this.paramService.getEthaneZonewise(this.selected).subscribe((res) => {
      this.days = res[0].actual_days[0].day;
    });

    this.paramService.getEthaneZonewise(this.selected).subscribe((res) => {
      this.predDays = res[0].optimised_days[0].day;
      console.log(this.predDays);
    });
  }

  changeRoute(param) {
    if (param == 'plant-frl') this.router.navigateByUrl('/dashboard/plant-frl');
    else this.router.navigateByUrl('/dashboard/plant-frl');
  }

  optimizedParam() {
    this.loading = true;
    var min = 5,
      max = 10;
    var rand = Math.floor(Math.random() * (max - min + 1) + min);
    setTimeout(() => {
      this.loading = false;
      this.show = !this.show;
      this.optimized = true;
      console.log(rand);
    }, rand * 1000);
  }
}
