<app-navbar></app-navbar>
<div class="main-body">
  <div class="dashBody row pt-1">
    <div class="col d-flex">
      <div class="mr-auto">
        <span style="display: flex;flex-direction:row;justify-content:space-between;">
          <a href='#/dashboard/landingpage'>
        <!-- <span style="display: flex;flex-direction:row;justify-content:space-between;"><a href='https://demo1.livnsense.com/#/dashboard/landingpage'> -->
          <mat-icon style="margin: 12px;background-color: white;border-radius:50px ;">arrow_back</mat-icon></a>
          <p class="text-white pt-2 font-weight-bold" style="font-size: 16px;margin-top: 5px;">
          Digital Twins Process Screen</p>
        </span>

      </div>
      <div>
        <p class="form-control text-light text-center" style="background-color: #393f4f;">Date: {{today | date:
          'dd-MM-yyyy'}}</p>
      </div>
    </div>
  </div>
  <div class="dashBody row mb-3">
    <div class="col-md-3 leftPane">
      <app-dcu-live></app-dcu-live>
    </div>
    <div class="col-md-6 centerPane text-center">
      <app-factory-image></app-factory-image>
      <app-common-graph></app-common-graph>
    </div>
    <div class="col-md-3 rightPane">
      <app-dcu-yields></app-dcu-yields>
    </div>
  </div>
  <div col-12 ml-2 class="text-white"> © LivNSense GreenOps Pvt. Ltd. All Rights Reserved. </div>
</div>
