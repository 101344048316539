import { BalcoService } from './../../core/balco.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ParameterTableComponent } from 'src/app/components/parameter-table/parameter-table.component';
// import { Balcotheme1Component } from 'src/app/components/balcotheme1/balcotheme1.component';
// import { ParamTableModalComponent } from 'src/app/components/param-table-modal/param-table-modal.component';

@Component({
  selector: 'app-balcodashboard',
  templateUrl: './balcodashboard.component.html',
  styleUrls: ['./balcodashboard.component.scss'],
})
export class BalcodashboardComponent implements OnInit {
  //SelectedTheme: any;
  SelectTheme = [
    // 'Realtime Monitoring of Smelting Operations',
    'Realtime Optimization of Smelting Operations',
    // 'Revenue Realization of Improved Operations',
    // 'Upload Shift/Lab Data'
  ];

  SelectedTheme = 'Realtime Optimization of Smelting Operations';
  

  SelectPot = [
    { id: 1301, name: 'Pot 1301' },
    { id: 1302, name: 'Pot 1302' },
    { id: 1303, name: 'Pot 1303' },
    { id: 1304, name: 'Pot 1304' },
    { id: 1305, name: 'Pot 1305' },
    { id: 1306, name: 'Pot 1306' },
    { id: 1307, name: 'Pot 1307' },
    { id: 1308, name: 'Pot 1308' },
    { id: 1309, name: 'Pot 1309' },
    { id: 1310, name: 'Pot 1310' }
  ];
  SelectedPot: number;
  isLoading: boolean;
  recom_values: any;
  recom_actualVal: any;
  today: number = Date.now();
  tables: any;
  predlive: boolean = false;
  public predshow: boolean = false;
  public loading: boolean = false;

  constructor(private balcoService: BalcoService , public dialog: MatDialog) {
    this.balcoService.potNo.subscribe((res) => {
      this.SelectedPot = res;
    });
    
  }

  ngOnInit(): void {}

  // radio buttom theme event
  onItemChange(event) {
    this.SelectedTheme = event.SelectTheme.value;
  }

  OnPots(event) {
    console.log(event)
    let pot = event.target.value;   
    this.SelectedPot = pot;
    this.balcoService.potNo.next(this.SelectedPot);
    this.predlive = false;
 
  }

  generateRecommendations(): void {
    
    this.loading = true;
    this.predlive = false;
    var min = 5,
      max = 10;
    var rand = Math.floor(Math.random() * (max - min + 1) + min);
    setTimeout(() => {
      this.loading = false;
      
      this.predlive = true;
      //console.log(rand);
    }, rand * 1000);
  }
  // openParamDialog(): void {
  //   const dialogRef = this.dialog.open(ParamTableModalComponent, {
  //     panelClass: 'normal-modal-class',
  //     data: {
  //       tables: this.tables,
  //     },
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     console.log('The dialog was closed');
  //   });
  // }
}
