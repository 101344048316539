import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ParamModelComponent } from '../param-model/param-model.component';
import { ParamService } from 'src/app/core/param.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-s-param-measures',
  templateUrl: './s-param-measures.component.html',
  styleUrls: ['./s-param-measures.component.scss'],
})
export class SParamMeasuresComponent implements OnInit {
  @Input() selectedRun: number;

  paramValues: any;

  constructor(
    public dialog: MatDialog,
    private paramService: ParamService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.paramService.selectedRun.subscribe((res) => {
      this.selectedRun = res;
      console.log(this.selectedRun);
    });
  }

  openSingleParamDialog(param): void {
    const dialogRef = this.dialog.open(ParamModelComponent, {
      // disableClose: true,
      // hasBackdrop: true,
      // closeOnNavigation: true,
      panelClass: 'normal-modal-class',
      data: {
        param: param,
        run: this.selectedRun,
        zone: 1,
      },
    });

    //close on navigation
    this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  ngOnChanges() {
    // if (this.selectedRun == 1) this.paramValue = this.run1;
    // else if (this.selectedRun == 2) this.paramValue = this.run2;
    // else if (this.selectedRun == 3) this.paramValue = this.run3;
    // else if (this.selectedRun == 4) this.paramValue = this.run4;
    this.singleParam();
  }

  singleParam() {
    if (this.selectedRun === 6) {
      this.paramService
        .furnaceLiveOverview(this.selectedRun)
        .subscribe((res) => {
          this.paramValues = res[0];
        });
    } else {
      this.paramService.furnaceOverview(this.selectedRun).subscribe((res) => {
        this.paramValues = res[0];
      });
    }
  }

  returnZero() {
    return 0;
  }
  headerColor() {
    let styles = {
      color: '#ffffff',

    };
    return styles;
  }
  dotColor() {
    let styles = {
      background:'#fff',

    };
    return styles;
  }
}
