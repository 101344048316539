<section class="card mb-4" *ngIf="selection">
  <p class="table-title mb-0 py-2" [ngStyle]="headerColor()"><span class="dot" [ngStyle]="dotColor()"></span>{{header}}
  </p>
  <div class="table-responsive">
    <table class="table table-borderless tableBody">
      <thead>
        <tr class="main-table-row text-right" [ngStyle]="headerColor()">
          <td scope="col"></td>
          <!-- <td scope="col font-weight-bold">Min</td> -->
          <td scope="col font-weight-bold">{{avgTitle}} Value</td>
          <td scope="col font-weight-bold">UoM</td>
        </tr>
      </thead>
      <tbody *ngIf="!isConfig">
        <tr class="table-data trow" *ngFor="let data of selectedParameter; let i= index" (click)="openModal(data.parameter)">
          <!-- <td *ngIf="header == 'ACTUAL VALUE' || header == 'RECOMMENDED VALUE'" class="cellBorder" [ngStyle]="headerColor()">{{data.parameter}}</td> -->
          <td class="cellBorder" [ngStyle]="headerColor()">{{data.parameter |
            replaceUnderscore}}
          </td>
          <td class="row-data cellBorder text-right" [ngStyle]="headerColor()">{{data.parameter_value | number:
            '1.2-2'}}</td>
          <td class="row-data cellBorder text-right" [ngStyle]="headerColor()">{{data.unit}}</td>
        </tr>
      </tbody>
      <tbody *ngIf="isConfig">
        <tr class="table-data" *ngFor="let data of selectedParameter; let i= index">
          <td class="cellBorder font-weight-bold">{{data.parameter}}</td>
          <!-- <td class="row-data cellBorder"><input class="w-100" type="number" [value]='data.min_value' (change)="changeInMin()" [(ngModel)]="data.min_value"></td> -->
          <td class="row-data cellBorder text-right"><input class="w-100" type="number" [value]='data.parameter_value'
              (change)="changeInMax()" [(ngModel)]="data.parameter_value"></td>
          <td class="row-data cellBorder text-right">{{data.unit}}</td>
        </tr>
      </tbody>
    </table>
  </div>
  <ng-content></ng-content>
</section>
