import { ModifyUserDialogComponent } from './pages/setting-configuaration/dailogs/modify-user/modify-user.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './pages/login/login.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FactoryImageComponent } from './components/factory-image/factory-image.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { DcuLiveComponent } from './components/dcu-live/dcu-live.component';
import { DcuYieldsComponent } from './components/dcu-yields/dcu-yields.component';
import { ParameterTableComponent } from './components/parameter-table/parameter-table.component';
import { ModalComponent } from './components/modal/modal.component';
import { ModalService } from './core/modal.service';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CommonGraphComponent } from './components/common-graph/common-graph.component';
import { SettingConfiguarationComponent } from './pages/setting-configuaration/setting-configuaration.component';
import { ToastrModule } from 'ngx-toastr';
import { DcuComponent } from './components/dcu/dcu.component';
import { TooltipDirective } from './components/dcu/tooltip.directive';
import { MatFormFieldModule } from '@angular/material/form-field';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { MAT_DATE_LOCALE } from '@angular/material/core';

import { ParameterConfiguarationComponent } from './pages/setting-configuaration/components/parameter-configuaration/parameter-configuaration.component';
import { UserManagementComponent } from './pages/setting-configuaration/components/user-management/user-management.component';
// tslint:disable-next-line:max-line-length
import { AccessConfigurationComponent } from './pages/setting-configuaration/components/access-configuration/access-configuration.component';
import { AddUserDialogComponent } from './pages/setting-configuaration/dailogs/add-user/add-user.component';
import { DeleteUserDialogComponent } from './pages/setting-configuaration/dailogs/delete-user/delete-user.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { ReplaceUnderscorePipe } from './components/parameter-table/replace-underscore.pipe';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { ActualParameterGraphComponent } from './components/actual-parameter-graph/actual-parameter-graph.component';
import { ActualParameterGraphModalComponent } from './components/actual-parameter-graph-modal/actual-parameter-graph-modal.component';
import { ActualMainYieldGraphModalComponent } from './components/actual-main-yield-graph-modal/actual-main-yield-graph-modal.component';
import { PredictedMainYieldGraphModalComponent } from './components/predicted-main-yield-graph-modal/predicted-main-yield-graph-modal.component';
import { PredictedMainYieldGraphComponent } from './components/predicted-main-yield-graph/predicted-main-yield-graph.component';
import { ActualMainYieldGraphComponent } from './components/actual-main-yield-graph/actual-main-yield-graph.component';
import { DcuGraphModalComponent } from './components/dcu-graph-modal/dcu-graph-modal.component';
import { DcuGraphComponent } from './components/dcu-graph/dcu-graph.component';
import { Predicted1MainYieldGraphModalComponent } from './components/predicted1-main-yield-graph-modal/predicted1-main-yield-graph-modal.component';
import { Predicted1MainYieldGraphComponent } from './components/predicted1-main-yield-graph/predicted1-main-yield-graph.component';
import { Predicted1MainYieldLineGraphComponent } from './components/predicted1-main-yield-line-graph/predicted1-main-yield-line-graph.component';
import { PlantOperatorComponent } from './pages/plant-operator/plant-operator.component';
import { ActualMainPiechartComponent } from './components/actual-main-piechart/actual-main-piechart.component';
import { GraphDraggableDirective } from './components/graph-draggable.directive';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MsgBoxComponent } from './components/msg-box/msg-box.component';
import { FurnaceComponent } from './pages/furnace/furnace.component';
import { FurnaceImgComponent } from './components/furnace-img/furnace-img.component';
import { SParamMeasuresComponent } from './components/s-param-measures/s-param-measures.component';
import { ParamGraphComponent } from './components/param-graph/param-graph.component';
import { ParamModelComponent } from './components/param-model/param-model.component';
import { MParamMeasuresComponent } from './components/m-param-measures/m-param-measures.component';
import { MultiParamGraphModalComponent } from './components/multi-param-graph-modal/multi-param-graph-modal.component';
import { FurnaceOptimiserComponent } from './pages/furnace-optimiser/furnace-optimiser.component';
import { ActualPredictedMeasuresComponent } from './components/actual-predicted-measures/actual-predicted-measures.component';
import { ActualMeasuresComponent } from './components/actual-measures/actual-measures.component';
import { FrlMeasuresComponent } from './components/frl-measures/frl-measures.component';
import {FrlOptimzerMeasuresComponent } from './components/frl-optimzer-measures/frl-optimzer-measures.component';
import { MCParamMeasuresComponent } from './components/m-c-param-measures/m-c-param-measures.component';
import { MOptParamMeasuresComponent } from './components/m-opt-param-measures/m-opt-param-measures.component';
import { MultiParamGraphComponent } from './components/multi-param-graph/multi-param-graph.component';
import { OptimizedMeasuresComponent } from './components/optimized-measures/optimized-measures.component';
import { PredParamGraphComponent } from './components/pred-param-graph/pred-param-graph.component';
import { PredParamModalComponent } from './components/pred-param-modal/pred-param-modal.component';
import { SingleParamGraphComponent} from './components/single-param-graph/single-param-graph.component';
import { SingleParamGraphModalComponent } from './components/single-param-graph-modal/single-param-graph-modal.component';
import { SOptParamMeasuresComponent } from './components/s-opt-param-measures/s-opt-param-measures.component';
import { BalcodashboardComponent } from './pages/balcodashboard/balcodashboard.component';
import { Balcotheme1Component } from './components/balcotheme1/balcotheme1.component';
import { BalcofurnaceImageComponent } from './components/balco-furnace-image/balco-furnace-image.component';
import { BalcofurnacesvgComponent } from './components/balco-furnace-svg/balco-furnace-svg.component';
import { ParamTableModalComponent } from './components/param-table-modal/param-table-modal.component';
import { LandingpageComponent } from './pages/landingpage/landingpage.component';
import { ChartsModule } from 'ng2-charts';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { loginComponent } from './pages/Newlogin/login.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { ResetPasswordComponent} from './pages/reset-password/reset-password.component';
import { AdminpanelComponent } from "./pages/adminpanel/adminpanel.component";
import {AdminpanelModule} from "./pages/adminpanel/adminpanel.module";
import {DialogBoxComponent} from "./pages/setting-configuaration/dialog-box/dialog-box.component";

// import { signupComponent } from './pages/Signup/signup.component'

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    loginComponent,
    DialogBoxComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    NavbarComponent,
    FactoryImageComponent,
    DashboardComponent,
    DcuLiveComponent,
    DcuYieldsComponent,
    ParameterTableComponent,
    ModalComponent,
    CommonGraphComponent,
    SettingConfiguarationComponent,
    DcuComponent,
    TooltipDirective,
    ParameterConfiguarationComponent,
    UserManagementComponent,
    AddUserDialogComponent,
    ModifyUserDialogComponent,
    DeleteUserDialogComponent,
    AccessConfigurationComponent,
    LoadingBarComponent,
    ActualParameterGraphComponent,
    ActualParameterGraphModalComponent,
    ReplaceUnderscorePipe,
    ActualMainYieldGraphModalComponent,
    PredictedMainYieldGraphModalComponent,
    PredictedMainYieldGraphComponent,
    Predicted1MainYieldGraphModalComponent,
    Predicted1MainYieldGraphComponent,
    ActualMainYieldGraphComponent,
    DcuGraphModalComponent,
    DcuGraphComponent,
    Predicted1MainYieldLineGraphComponent,
    PlantOperatorComponent,
    ActualMainPiechartComponent,
    GraphDraggableDirective,
    MsgBoxComponent,
    FurnaceComponent,
    FurnaceImgComponent,
    SParamMeasuresComponent,
    ParamGraphComponent,
    ParamModelComponent,
    MParamMeasuresComponent,
    MultiParamGraphModalComponent,
    FurnaceOptimiserComponent,
    BalcodashboardComponent,
    Balcotheme1Component,
    BalcofurnaceImageComponent,
    BalcofurnacesvgComponent,
    ParamTableModalComponent,
    ActualPredictedMeasuresComponent,
    ActualMeasuresComponent,
    FrlMeasuresComponent,
    FrlOptimzerMeasuresComponent,
    MCParamMeasuresComponent,
    MOptParamMeasuresComponent,
    MultiParamGraphComponent,
    OptimizedMeasuresComponent,
    PredParamGraphComponent,
    PredParamModalComponent,
    SingleParamGraphComponent,
    SingleParamGraphModalComponent,
    SOptParamMeasuresComponent,
    LandingpageComponent,
    SpinnerComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    MatTabsModule,
    MatFormFieldModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    // AdminpanelModule,
    ChartsModule,
    ToastrModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
  ],
  providers: [ModalService, DatePipe, NgbActiveModal],
  bootstrap: [AppComponent],
  entryComponents: [
    ModalComponent,
    ActualParameterGraphModalComponent,
    AddUserDialogComponent,
    ModifyUserDialogComponent,
    DeleteUserDialogComponent,
    DialogBoxComponent
  ],
})
export class AppModule {}
