import { Component, Input, OnInit } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { MultiParamGraphModalComponent } from '../multi-param-graph-modal/multi-param-graph-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-m-param-measures',
  templateUrl: './m-param-measures.component.html',
  styleUrls: ['./m-param-measures.component.scss'],
})
export class MParamMeasuresComponent implements OnInit {
  @Input() selectedRun: number;

  multiParam = [
    { paramName: 'Ethane2 Feed vs CPR', pName: 'EthaneCPR' },
    { paramName: 'TMT vs CPR', pName: 'TMTCPR' },
    { paramName: 'COT vs CPR', pName: 'COTCPR' },
    { paramName: 'SHCR vs CPR', pName: 'SHCRCPR' },
    { paramName: 'Fuel Gas Flow vs CPR', pName: 'FGFCPR' },
    // { paramName: 'Oxygen Gas Flow vs CPR' },
  ];

  constructor(public dialog: MatDialog, private router: Router) {}

  ngOnInit(): void {}

  openMultipleParamDialog(param): void {

    const dialogRef = this.dialog.open(MultiParamGraphModalComponent, {
      // disableClose: true,
      // hasBackdrop: true,
      // closeOnNavigation: true,
      panelClass: 'normal-modal-class',
      data: {
        param: param,
        run: this.selectedRun,
        zone: 1,
      },
    });

    //close on navigation
    this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
  headerColor() {
    let styles = {
      color: '#ffffff',

    };
    return styles;
  }
  dotColor() {
    let styles = {
      background:'#fff',

    };
    return styles;
  }
}
