import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { paramData } from './dataSource';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-param-graph',
  templateUrl: './param-graph.component.html',
  styleUrls: ['./param-graph.component.scss'],
})
export class ParamGraphComponent implements OnInit {
  @Input() graphData: any[];
  // @Input() tempRun: any;
  // @Input() tempZone: any;

  constructor(public http: HttpClient) {
    // console.log(this.tempZone);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Create chart instance
    let chart: any = am4core.create('chartdiv', am4charts.XYChart3D);
    chart.background.fill = '#232d4a';
    chart.responsive.enabled = true;
    chart.timezoneOffset = 300;
    chart.paddingRight = 35;
    chart.logo.disabled = true;

    // Set input format for the dates
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd H-mm';

    //Add scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.topAxesContainer;

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.labels.template.fill = am4core.color('#fff');
    chart.legend.labels.template.fontWeight = 'bold';
    // chart.legend.reverseOrder = true;

    // Enable export
    // chart.exporting.menu = new am4core.ExportMenu();
    // chart.exporting.menu.items[0].icon =
    //   'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+';

    chart.data = this.graphData[0].Zone;

    let uom = this.graphData[0].Zone[0].uom;
    let pName = this.graphData[0].Zone[0].param_name;
    // chart.data = this.param2;
    // console.log(this.graphData[0]);
    // chart.data = paramData[0];

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 50;
    dateAxis.title.text = 'Date';
    dateAxis.title.fill = '#fff';
    dateAxis.title.fontWeight = 'bold';
    dateAxis.renderer.labels.template.fill = am4core.color('#fff');
    dateAxis.renderer.labels.template.fontSize = 15;
    dateAxis.skipEmptyPeriods = true;
    // dateAxis.tooltipDateFormat = 'yyyy-MM-dd HH-mm';
    dateAxis.tooltipDateFormat = 'd MMM';
    dateAxis.baseInterval = {
      timeUnit: 'hour',
      count: 2,
    };

    //  Sort by date
    chart.events.on('beforedatavalidated', function (ev) {
      chart.data.sort(function (a, b) {
        return (
          new Date(a.timestamp).valueOf() - new Date(b.timestamp).valueOf()
        );
      });
    });

    // this makes the data to be grouped
    // dateAxis.groupData = true;
    // dateAxis.groupCount = 500;

    // let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    // valueAxis.title.text = 'Value';
    // valueAxis.title.fill = '#fff';
    // valueAxis.title.fontWeight = 'bold';
    // valueAxis.renderer.labels.template.fill = am4core.color('#fff');
    // valueAxis.renderer.labels.template.fontSize = 15;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    // chart.cursor.snapToSeries = series;
    chart.cursor.xAxis = dateAxis;

    // Create series
    function createAxisAndSeries(field, paramName, color, uom) {
      let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
      valueAxis.title.text = uom;
      valueAxis.title.fill = '#fff';
      valueAxis.title.fontWeight = 'bold';
      valueAxis.renderer.labels.template.fontSize = 15;
      if (chart.yAxes.indexOf(valueAxis) != 0) {
        valueAxis.syncWithAxis = chart.yAxes.getIndex(0);
      }

      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = 'obs_time';
      series.strokeWidth = 4;
      series.yAxis = valueAxis;
      series.name = paramName;
      series.tooltipText =
        "[bold]{param_name}: {pro_val.formatNumber('#.000')} {uom}";
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = color;
      series.tooltip.label.fill = am4core.color('#000');
      series.tooltip.label.fontSize = 17;
      series.stroke = color;
      series.tensionX = 0.8;
      series.showOnInit = true;

      let interfaceColors = new am4core.InterfaceColorSet();

      valueAxis.renderer.line.strokeOpacity = 1;
      valueAxis.renderer.line.strokeWidth = 2;
      valueAxis.renderer.line.stroke = series.stroke;
      valueAxis.renderer.labels.template.fill = series.stroke;
      valueAxis.cursorTooltipEnabled = false;
      valueAxis.renderer.labels.template.fill = am4core.color('#fff');
      // valueAxis.extraTooltipPrecision = 2;
      // valueAxis.adjustLabelPrecision = '#.0000';
    }

    createAxisAndSeries('pro_val', pName, '#009933', uom);
  }
}
