import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ApicallService } from '../../../Apicalls/apicall.service';
import { ThemeService } from '../../../Apicalls/Themeservice/theme.service';

@Component({
  selector: 'app-dialog-box',
  templateUrl: './dialog-box.component.html',
  styleUrls: ['./dialog-box.component.scss']
})
export class DialogBoxComponent implements OnInit, OnDestroy {
  themeSubscription: Subscription;
  isDarkTheme = false;
  isLoadingfirst = false;
  DeleteUser: any;
  DeleteuserMessage: string | null = null;

  constructor(
    public dialogRef: MatDialogRef<DialogBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private themeService: ThemeService,
    private apiCallsService: ApicallService,
    private router: Router
  ) {
    this.themeSubscription = this.themeService.theme$.subscribe(theme => {
      this.isDarkTheme = theme === 'darktheme';
    });
  }

  ngOnInit() {
    this.isLoadingfirst = false;
    if (this.data.clicked === 1) {
      this.DeleteUser = this.data.DeleteUser;
    }
  }

  ngOnDestroy() {
    if (this.themeSubscription) {
      this.themeSubscription.unsubscribe();
    }
  }

  closed() {
    this.dialogRef.close();
  }

  deleteUser() {
    if (this.DeleteUser) {
      this.isLoadingfirst = true;
      this.apiCallsService.deleteUser(this.DeleteUser.user_id, this.DeleteUser.user_name).subscribe(
        res => {
          this.DeleteuserMessage = res.Message;
          this.isLoadingfirst = false; // Data loading complete
          setTimeout(() => {
            this.closed();
            window.location.reload();
          }, 3000);
        },
        err => {
          console.error(err);
          this.isLoadingfirst = false; // Ensure loading is set to false on error
        }
      );
    }
  }
}
