<section class="card mb-4">
  <p class="table-title mb-0 py-2" [ngStyle]="headerColor()"><span class="dot" [ngStyle]="dotColor()"></span>Parameter Trends
  </p>

  <div class="table-responsive" id="demo">
    <table class="table table-borderless tableBody">
      <thead>
        <tr class="main-table-row text-center" style="color: white;">
          <td scope="col font-weight-bold"></td>

        </tr>
      </thead>
      <tbody>
        <tr class="table-data trow" *ngFor="let mParam of multiParam" (click)="openMultipleParamDialog(mParam.pName)">
          <td class="row-data cellBorder text-center" style="color: white;">{{mParam.paramName}}</td>

        </tr>
      </tbody>
    </table>
  </div>
</section>
<!-- <div *ngFor="let mParam of multiParam">
  <button type="button" class="btn btn-lg btn-block font-weight-bold my-2" (click)="openMultipleParamDialog(mParam.pName)">{{mParam.paramName}}</button>
</div> -->
