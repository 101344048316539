import { Component, OnInit } from '@angular/core';
import { ParamService } from 'src/app/core/param.service';
@Component({
  selector: 'app-s-opt-param-measures',
  templateUrl: './s-opt-param-measures.component.html',
  styleUrls: ['./s-opt-param-measures.component.scss'],
})
export class SOptParamMeasuresComponent implements OnInit {
  paramValues: any;
  constructor(private paramService: ParamService) {}

  ngOnInit(): void {
    // this.singleParam();
  }

  // singleParam() {
  //   this.paramService.furnaceOptimzed().subscribe((res) => {
  //     this.paramValues = res[0];
  //   });
  // }
}
