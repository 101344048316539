<mat-progress-bar mode="indeterminate" *ngIf="isLoading"></mat-progress-bar>
<div class="d-flex">
  <h1 mat-dialog-title class="mb-0">Add User</h1>
  <i class="fa fa-times cursor-pointer ml-auto" (click)="onNoClick()"></i>
</div>

<div mat-dialog-content>
  <form action="" class="mt-2" [formGroup]="userForm">
    <div class="row">

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput type="text" name="name" formControlName="name" required>
          <mat-error *ngIf="errorHandling('name','required')">Please enter Name</mat-error>
          <mat-error *ngIf="errorHandling('name','pattern')">Name is invalid</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Login Id</mat-label>
          <input matInput type="text" name="loginid" formControlName="login_id" required>
          <mat-error *ngIf="errorHandling('login_id','required')">Please enter Login Id</mat-error>
          <mat-error *ngIf="errorHandling('login_id','minLength')">Login Id should be atleast 5 characters</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Email Id</mat-label>
          <input matInput type="text" name="mailid" formControlName="email_id" required>
          <mat-error *ngIf="errorHandling('email_id','required')">Please enter Email Id</mat-error>
          <mat-error *ngIf="errorHandling('email_id','pattern')">Email Id is invalid</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Designation</mat-label>
          <input matInput type="text" name="designation" formControlName="designation" required>
          <mat-error *ngIf="errorHandling('designation','required')">Please enter Designation</mat-error>
        </mat-form-field>
      </div>
      <!-- 
      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>User Type</mat-label>
          <select matInput matNativeControl type="text" formControlName="user_type" required>
            <option [value]="role" *ngFor="let role of roles">{{role}}
            </option>
          </select>
          <mat-error *ngIf="errorHandling('user_type','required')">Please select user type</mat-error>
        </mat-form-field>
      </div> -->

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>User Type</mat-label>
          <select matNativeControl formControlName="user_type" required>
            <option class="optionParam" value="" selected></option>
            <option class="optionParam" [value]="role" *ngFor="let role of roles">{{role}}</option>
          </select>
          <mat-error *ngIf="errorHandling('user_type','required')">Please select user type</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input matInput type="password" name="password" formControlName="password" autocomplete="new-password"
            required>
          <mat-error *ngIf="errorHandling('password','required')">Please enter your password</mat-error>
          <mat-error *ngIf="errorHandling('password','pattern')">Password should contains 8 chars with atleast one
            capital letter, one special character and one number</mat-error>
        </mat-form-field>
      </div>

      <div class="col-md-12">
        <mat-form-field appearance="outline">
          <mat-label>Re-enter Password</mat-label>
          <input matInput type="password" name="repassword" formControlName="reEnterPassword" required
            (input)="onRePasswordInput()">
          <mat-error *ngIf="errorHandling('reEnterPassword','required')">Please confirm your password</mat-error>
          <mat-error *ngIf="errorHandling('reEnterPassword','mismatch')">
            Passwords don't match
          </mat-error>
        </mat-form-field>
      </div>

      <!-- <div class="col-md-12">
        <span class="labels-text" style="margin-left: 0.5em;">Email Notification </span><label class="switch"
          style="line-height: 1;">
          <input type="checkbox" checked formControlName="notifications">
          <span class="slider round"></span>
        </label>
      </div> -->

    </div>
  </form>
</div>
<div mat-dialog-actions class="float-right">
  <button class="btn btn-sm purple-button mx-2" (click)="addUser()">Add User</button>
</div>