import { Component, OnInit, Input, ViewEncapsulation,Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ParamService } from 'src/app/core/param.service';
import { DatePipe } from '@angular/common';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [DatePipe],
})
export class ModalComponent implements OnInit {
  // @Input() fromParamTable;
  today: number = Date.now();
  yieldsParameterName: any;
  selectedParam: any;

  constructor(
    public activeModal: NgbActiveModal,
    private paramService: ParamService,
    private datePipe: DatePipe,
    public dialogRef: MatDialogRef<ModalComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: MatDialog
  ) {
    this.paramService.yieldspredictedValuesData.subscribe((res: any) => {
      this.yieldsParameterName = res;
    });
    this.paramService.selectedYieldParameter.subscribe((res1: any) => {
      this.selectedParam = res1;
    });
    this.paramService
      .getYieldsParameterGraphData(this.selectedParam)
      .subscribe((res) => {
        // this.paramService.selYieldParamGraphData.next(res);
      });
  }

  ngOnInit() {}

  changeParam() {
    this.paramService.selectedYieldParameter.next(this.selectedParam);
    this.paramService
      .getYieldsParameterGraphData(this.selectedParam)
      .subscribe((res) => {
        // this.paramService.selYieldParamGraphData.next(res);
      });
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}
