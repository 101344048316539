import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class DatashareService {
  constructor() {}

  private edituserdetails = new BehaviorSubject<any>(null);
  edituserdetails$ = this.edituserdetails.asObservable();
  setedituserdetails(data: any) {
    this.edituserdetails.next(data);
  }
  private emailID = new BehaviorSubject<any>(null);
  emailID$ = this.emailID.asObservable();
  setemailID(data: any) {
    this.emailID.next(data);
  }
}
