<div class="paneBody">

  <p class="h5 text-center live-title">DCU - Live Performance</p>

  <div class="container">
    <div class="row">
      <div class="col-md-11 p-0 pb-2">
        <select name="selectedParameter" class="form-control text-light selectParam" [(ngModel)]="selectedParameter"
          (change)="changeActualParameter()">
          <option *ngFor="let option of dcuParameter" [value]="option.value">{{option.name}}</option>
        </select>
      </div>
      <div class="col-md-1">

        <!-- <mat-form-field appearance="fill" >       -->
          <!-- <mat-label>Enter a date range</mat-label>       -->
          <!-- <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">       -->
          <mat-date-range-input [rangePicker]="picker" [min]="minDate" [max]="maxDate">      
            <input style="color: #fff; visibility: hidden" matStartDate #dateRangeStart placeholder="Start date">
            <input style="color: #fff; visibility: hidden" matEndDate #dateRangeEnd placeholder="End date" (dateChange)="avgDashboardData(dateRangeStart, dateRangeEnd)">  
          </mat-date-range-input>      
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle> 
          <mat-date-range-picker #picker></mat-date-range-picker>
          <!-- <mat-datepicker touchUi #picker></mat-datepicker>       -->
        <!-- </mat-form-field>  -->
        
      </div>
    </div>
  </div>

  <div class="d-flex" *ngIf="changeDate">
    <p class="text-white h6 font-weight-bold">Start Date: {{dateRangeStart.value | date}}</p>
      <p class="text-white h6 font-weight-bold ml-auto">End Date: {{dateRangeEnd.value | date}}</p>
  </div>
  <!-- radio buttons ends -->

  <!-- actual value starts -->
  <app-parameter-table *ngIf="ifData" [header]="'PROCESS VALUE'" [selection]='selectedParameter'
    [selectedParameter]="actualValuesData">
  </app-parameter-table>
  <!-- actual value starts -->

  <!-- actual yield starts -->
  <app-parameter-table *ngIf="ifData" [header]="'DCU ACTUAL OTHER YIELDS'" [selection]='selectedParameter'
    [selectedParameter]='yieldsActualValuesData'>
  </app-parameter-table>
  <!-- actual yield starts -->
</div>


