import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { ParamService } from 'src/app/core/param.service';

@Component({
  selector: 'app-dcu-graph-modal',
  templateUrl: './dcu-graph-modal.component.html',
  styleUrls: ['./dcu-graph-modal.component.scss'],
})
export class DcuGraphModalComponent implements OnInit {
  selectedParameter: any;
  fromParamTable: any;
  graphData: any[];
  isLoadingComplete: boolean;
  chartId: any;
  isNotPredictedLine: boolean;
  constructor(
    public dialogRef: MatDialogRef<DcuGraphModalComponent>,
    private paramService: ParamService
  ) {}

  ngOnInit(): void {
    this.isLoadingComplete = false;
    // this.paramService.selectedYieldParameter.subscribe((res1: any) => {
    //   this.selectedParameter = res1;
    // });
    this.getGraphData();
  }

  getGraphData() {
    this.paramService
      .getYieldsParameterGraphData(this.selectedParameter)
      .subscribe((res) => {
        res[0]['actual_values'].forEach(
          (element) =>
            (element['actual_value'] = this.paramService.displayGraphDecimal(
              element['actual_value']
            ))
        );
        res[0]['isNot_predicted_line'] = true;
        res[0]['isNot_threshold_line'] = true;
        this.graphData = res;
        this.isLoadingComplete = true;
      });
  }

  changeParameter() {
    this.isLoadingComplete = false;
    this.getGraphData();
  }

  closeModal() {
    this.dialogRef.close(this.chartId);
  }
}
