<section class="card mb-4">
    <!-- <p class="table-title mb-0 py-2" style="color: white;text-align: center;">Hourly Recommendation For Current Shift
    </p> -->
    <h4 class="modal-title font-weight-bold text-white" id="modal-title" style="margin-left: 70px; margin-top: 10px;">On Demand Hourly Recommendation </h4>
        
    <div class="table-responsive" id="demo">
        <table class="table table-borderless tableBody">

            <thead>
                <tr class="main-table-row text-left" style="color: white;">
                  <td scope="col font-weight-bold">KPI</td>
                  <td scope="col font-weight-bold">Current Values</td>
                  <td scope="col font-weight-bold">Case Changes</td>
                  <td scope="col font-weight-bold">Improved Values</td>
                </tr>
              </thead>
              <tbody>       
      <!-- <tr>
        <td style="text-align: left;">Actual ALF3 Addition</td>
        <td>
            {{values.actual_alf3_added |  number: '1.3-3'}} Kg
        </td>
    </tr> -->
      <tr class="table-data trow">
          <td class="row-data cellBorder text-left">Alf3 Added</td>
          <td class="row-data cellBorder text-left">
              {{recom_values.current_alf3_added | number: '1.3-3'}} Kg
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.case_alf3_added | number: '1.3-3'}} Kg
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.improved_alf3_added | number: '1.3-3'}} Kg
          </td>
      </tr>
      <tr class="table-data trow">
        <td class="row-data cellBorder text-left">Over Feed</td>
        <td class="row-data cellBorder text-left">
              {{recom_values.current_over_feed | number: '1.3-3'}} %
          </td>
          <td class="row-data cellBorder text-left">
             {{recom_values.case_over_feed | number: '1.3-3'}} %
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.improved_over_feed | number: '1.3-3'}} %
          </td>
      </tr>
      <tr class="table-data trow">
        <td class="row-data cellBorder text-left">Under Feed</td>
        <td class="row-data cellBorder text-left">
              {{recom_values.current_under_feed | number: '1.3-3'}} %
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.case_under_feed | number: '1.3-3'}} %
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.improved_under_feed | number: '1.3-3'}} %
          </td>
      </tr>
      <tr class="table-data trow">
        <td class="row-data cellBorder text-left">Working Voltage</td>
        <td class="row-data cellBorder text-left">
              {{recom_values.current_working_voltage | number: '1.3-3'}} mv
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.case_working_voltage | number: '1.3-3'}} mv
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.improved_working_voltage | number: '1.3-3'}} mv
          </td>
      </tr>
      <tr class="table-data trow">
        <td class="row-data cellBorder text-left">Bath Height</td>
        <td class="row-data cellBorder text-left">
              {{recom_values.current_bath_height | number: '1.3-3'}}  cm
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.case_bath_height | number: '1.3-3'}} cm
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.improved_bath_height | number: '1.3-3'}} cm 
          </td>
      </tr>
      <tr class="table-data trow">
        <td class="row-data cellBorder text-left">Metal Height</td>
        <td class="row-data cellBorder text-left">
              {{recom_values.current_metal_height | number: '1.3-3'}} cm
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.case_metal_height | number: '1.3-3'}} cm
          </td>
          <td class="row-data cellBorder text-left">
              {{recom_values.improved_metal_height | number: '1.3-3'}} cm
          </td>
      </tr>
</tbody>
</table>
  </div>
</section>