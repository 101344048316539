import { paramData } from '../param-graph/dataSource';
import { NONE_TYPE } from '@angular/compiler';
import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ParamService } from 'src/app/core/param.service';

@Component({
  selector: 'app-multi-param-graph-modal',
  templateUrl: './multi-param-graph-modal.component.html',
  styleUrls: ['./multi-param-graph-modal.component.scss'],
})
export class MultiParamGraphModalComponent implements OnInit {
  runs: any;
  zones = [1, 2, 3];

  param1: any[];
  param2: any[];
  isLoadingComplete: boolean;
  pName1: string;
  pName2: string;
  header: string;
  run: any;
  zone: any;
  uom1: any;
  uom2: any;
  batchRuns: any;
  selectedRun: number;

  constructor(
    public dialogRef: MatDialogRef<MultiParamGraphModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private paramService: ParamService
  ) {
    console.log(data.run);
    console.log(data.param);
  }

  ngOnInit(): void {
    this.isLoadingComplete = false;
    this.getGraphData();

    this.paramService.runCount().subscribe((res) => {
      let temp = res[0].run;
      this.batchRuns = temp.splice(0, temp.length - 1);
      console.log('Batch', this.batchRuns);
      this.runs = this.batchRuns.reverse();
      // this.selectedRun = this.runs[this.runs.length - 1];
      this.selectedRun = this.runs[0];
    });
  }

  getGraphData() {
    this.paramService
      .getMultiParamGraphData(this.data.zone, this.data.run)
      .subscribe((res) => {
        console.log('Multiple', res);
        // console.log('graph', this.data);
        if (this.data.param == 'EthaneCPR') {
          this.header = 'Ethane Feed vs CPR';
          this.param1 = res[0]['Ethane Feed'];
          this.param2 = res[0]['CPR'];
          this.uom1 = res[0]['Ethane Feed'][0].uom;
          this.uom2 = res[0]['CPR'][0].uom;
          this.pName1 = 'Ethane Feed';
          this.pName2 = 'CPR';
        } else if (this.data.param == 'TMTCPR') {
          this.header = 'TMT vs CPR';
          this.param1 = res[0]['TMT'];
          this.param2 = res[0]['CPR'];
          this.uom1 = res[0]['TMT'][0].uom;
          this.uom2 = res[0]['CPR'][0].uom;
          this.pName1 = 'TMT';
          this.pName2 = 'CPR';
        } else if (this.data.param == 'COTCPR') {
          this.header = 'COT vs CPR';
          this.param1 = res[0]['COT'];
          this.param2 = res[0]['CPR'];
          this.uom1 = res[0]['COT'][0].uom;
          this.uom2 = res[0]['CPR'][0].uom;
          this.pName1 = 'COT';
          this.pName2 = 'CPR';
        } else if (this.data.param == 'SHCRCPR') {
          this.header = 'SHCR vs CPR';
          this.param1 = res[0]['SHC'];
          this.param2 = res[0]['CPR'];
          this.uom1 = res[0]['SHC'][0].uom;
          this.uom2 = res[0]['CPR'][0].uom;
          this.pName1 = 'SHCR';
          this.pName2 = 'CPR';
        } else if (this.data.param == 'FGFCPR') {
          this.header = 'Fuel Gas Flow vs CPR';
          this.param1 = res[0]['Fuel Gas Flow  (Floor)'];
          this.param2 = res[0]['CPR'];
          this.uom1 = res[0]['Fuel Gas Flow  (Floor)'][0].uom;
          this.uom2 = res[0]['CPR'][0].uom;
          this.pName1 = 'Fuel Gas Flow';
          this.pName2 = 'CPR';
        }

        this.isLoadingComplete = true;
      });
  }

  // changeRun(run) {
  //   console.log(this.run);
  //   this.data.run = run;
  //   this.isLoadingComplete = false;
  //   setTimeout(() => {
  //     this.paramService
  //       .getMultiParamGraphData(this.data.zone, run)
  //       .subscribe((res) => {
  //         console.log('Multiple', res);
  //         // console.log('graph', this.data);
  //         if (this.data.param == 'EthaneCPR') {
  //           this.header = 'Ethane Feed vs CPR';
  //           this.param1 = res[0]['Ethane Feed'];
  //           this.param2 = res[0]['CPR'];
  //           this.uom1 = res[0]['Ethane Feed'][0].uom;
  //           this.uom2 = res[0]['CPR'][0].uom;
  //           this.pName1 = 'Ethane Feed';
  //           this.pName2 = 'CPR';
  //         } else if (this.data.param == 'TMTCPR') {
  //           this.header = 'TMT vs CPR';
  //           this.param1 = res[0]['TMT'];
  //           this.param2 = res[0]['CPR'];
  //           this.uom1 = res[0]['TMT'][0].uom;
  //           this.uom2 = res[0]['CPR'][0].uom;
  //           this.pName1 = 'TMT';
  //           this.pName2 = 'CPR';
  //         } else if (this.data.param == 'COTCPR') {
  //           this.header = 'COT vs CPR';
  //           this.param1 = res[0]['COT'];
  //           this.param2 = res[0]['CPR'];
  //           this.uom1 = res[0]['COT'][0].uom;
  //           this.uom2 = res[0]['CPR'][0].uom;
  //           this.pName1 = 'COT';
  //           this.pName2 = 'CPR';
  //         } else if (this.data.param == 'SHCRCPR') {
  //           this.header = 'SHCR vs CPR';
  //           this.param1 = res[0]['SHC'];
  //           this.param2 = res[0]['CPR'];
  //           this.uom1 = res[0]['SHC'][0].uom;
  //           this.uom2 = res[0]['CPR'][0].uom;
  //           this.pName1 = 'SHCR';
  //           this.pName2 = 'CPR';
  //         } else if (this.data.param == 'FGFCPR') {
  //           this.header = 'Fuel Gas Flow vs CPR';
  //           this.param1 = res[0]['Fuel Gas Flow (Floor)'];
  //           this.param2 = res[0]['CPR'];
  //           this.uom1 = res[0]['Fuel Gas Flow (Floor)'][0].uom;
  //           this.uom2 = res[0]['CPR'][0].uom;
  //           this.pName1 = 'Fuel Gas Flow';
  //           this.pName2 = 'CPR';
  //         }
  //         this.isLoadingComplete = true;
  //       });
  //   }, 1);
  // }

  changeZone(zone) {
    console.log(zone);
    this.data.zone = zone;
    this.isLoadingComplete = false;
    setTimeout(() => {
      this.paramService
        .getMultiParamGraphData(zone, this.data.run)
        .subscribe((res) => {
          console.log('Multiple', res);
          // console.log('graph', this.data);
          if (this.data.param == 'EthaneCPR') {
            this.header = 'Ethane Feed vs CPR';
            this.param1 = res[0]['Ethane Feed'];
            this.param2 = res[0]['CPR'];
            this.uom1 = res[0]['Ethane Feed'][0].uom;
            this.uom2 = res[0]['CPR'][0].uom;
            this.pName1 = 'Ethane Feed';
            this.pName2 = 'CPR';
          } else if (this.data.param == 'TMTCPR') {
            this.header = 'TMT vs CPR';
            this.param1 = res[0]['TMT'];
            this.param2 = res[0]['CPR'];
            this.uom1 = res[0]['TMT'][0].uom;
            this.uom2 = res[0]['CPR'][0].uom;
            this.pName1 = 'TMT';
            this.pName2 = 'CPR';
          } else if (this.data.param == 'COTCPR') {
            this.header = 'COT vs CPR';
            this.param1 = res[0]['COT'];
            this.param2 = res[0]['CPR'];
            this.uom1 = res[0]['COT'][0].uom;
            this.uom2 = res[0]['CPR'][0].uom;
            this.pName1 = 'COT';
            this.pName2 = 'CPR';
          } else if (this.data.param == 'SHCRCPR') {
            this.header = 'SHCR vs CPR';
            this.param1 = res[0]['SHC'];
            this.param2 = res[0]['CPR'];
            this.uom1 = res[0]['SHC'][0].uom;
            this.uom2 = res[0]['CPR'][0].uom;
            this.pName1 = 'SHCR';
            this.pName2 = 'CPR';
          } else if (this.data.param == 'FGFCPR') {
            this.header = 'Fuel Gas Flow vs CPR';
            this.param1 = res[0]['SHC'];
            this.param2 = res[0]['CPR'];
            this.uom1 = res[0]['SHC'][0].uom;
            this.uom2 = res[0]['CPR'][0].uom;
            this.pName1 = 'Fuel Gas Flow';
            this.pName2 = 'CPR';
          }

          this.isLoadingComplete = true;
        });
    }, 1);
  }

  closeModal(): void {
    this.dialogRef.close();
  }
}
