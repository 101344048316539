import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-actual-main-yield-graph-modal',
  templateUrl: './actual-main-yield-graph-modal.component.html',
  styleUrls: ['./actual-main-yield-graph-modal.component.scss'],
})
export class ActualMainYieldGraphModalComponent implements OnInit {
  periodList = ['Average', 'Overall'];
  predictionPeriod = this.periodList[0];
  isLoadingComplete: boolean;
  constructor(public activeModal: NgbActiveModal,
    public dialogRef: MatDialogRef<ActualMainYieldGraphModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {
    this.isLoadingComplete = true;
  }

  closeModal() {
    this.dialogRef.close(this.data);
  }

  changePeriod() {
    this.isLoadingComplete = false;
    setTimeout(() => {
      this.isLoadingComplete = true;
    }, 1);
  }
}
