import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
const baseUrl2 = environment['baseUrl2'];
@Injectable({

  providedIn: 'root'
})
export class LoginapiService {
  constructor(private http: HttpClient, private router: Router) { }
     /* Method which makes API call to login */
     login(body: any): Observable<any> {
      return this.http.post(`${baseUrl2}login`, body).pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            console.log(err);
          }
        )
      );
    }
    /* Method which makes API call to verify the email */
    verifyEmail(body: any): Observable<any> {
      return this.http.post(`${baseUrl2}request_otp_to_email`, body).pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            console.log(err);
          }
        )
      );
    }
     /* Method which makes API call to verify the otp */
     otpverifyEmail(body: any): Observable<any> {
      return this.http.post(`${baseUrl2}otp_verification`, body).pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            console.log(err);
          }
        )
      );
    }
     /* Method which makes API call to update the password */
     updatePassword(body: any): Observable<any> {
      return this.http.put(`${baseUrl2}update_password`, body).pipe(
        map(
          (response: any) => {
            return response;
          },
          (err: any) => {
            console.log(err);
          }
        )
      );
    }
  signup(body: any): Observable<any> {
    return this.http.post(`${baseUrl2}add_user`, body).pipe(
      map(
        (response: any) => {
          return response;
        },
        (err: any) => {
          console.log(err);
        }
      )
    );
  }
}
