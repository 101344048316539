import {
  Component,
  OnInit,
  ViewChild,
  Injectable,
  ViewEncapsulation,
} from '@angular/core';
import { ParamService } from 'src/app/core/param.service';
import { MatAccordion } from '@angular/material/expansion';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dcu-live',
  templateUrl: './dcu-live.component.html',
  styleUrls: ['./dcu-live.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DcuLiveComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  selectedParameter: any;
  selectedVersion: any;
  panelOpenState = false;
  dcuParameter = [
    { name: 'Input Feed', value: 'input Feed' },
    { name: 'Heater', value: 'Heater' },
    { name: 'Coke Drum', value: 'Coke Drum' },
    { name: 'Fractionator', value: 'Fractionator' },
  ];
  actualParameter: { name: string }[];
  actualValuesData: any;
  yieldsActualValuesData: any;
  minDate = new Date(2024, 0, 1);
  maxDate = new Date();

  startDate = new Date(new Date().setDate(new Date().getDate() - 6)).toISOString();
  endDate = new Date().toISOString();

  changeDate: boolean = false;
  cokeYield: any;
  preRecCokeYield: any;
  avg: any;
  postRecCokeYield: any;
  ifData: any = true;

  constructor(private paramService: ParamService) {
    this.paramService.selectedParameter.subscribe((res: any) => {
      this.selectedParameter = res;
      // console.log('Amit', res);
    });
    this.changeActualParameter();
    this.paramService.yieldsActualValuesData.subscribe((res: any) => {
      this.yieldsActualValuesData = res;
    });

    this.paramService.checkVal.subscribe((res) => {
      this.ifData = res;
    });

    this.getDashboardData();
  }

  ngOnInit(): void {}

  getDashboardData() {
    this.paramService
      .getDashboardData(this.startDate, this.endDate)
      .subscribe((res) => {});
  }

  avgDashboardData(
    dateRangeStart: HTMLInputElement,
    dateRangeEnd: HTMLInputElement
  ) {
    let sd = dateRangeStart.value;
    let ed = dateRangeEnd.value;

    // let test: any = (sd.valueOf() - ed.valueOf()) / 86400000;
    let dateDiff: any =
      (new Date(sd).valueOf() - new Date(ed).valueOf()) / 86400000;

    if (dateDiff == 0) {
      this.paramService.avg.next(' ');
    } else {
      this.paramService.avg.next('Avg. ');
    }

    this.paramService.preYieldDropdown.next(0);
    this.paramService.postYieldDropdown.next(0);
    // this.paramService.checkVal.next(true);
    this.changeDate = true;
    this.paramService.sDate.next(dateRangeStart.value);
    this.paramService.eDate.next(dateRangeEnd.value);
    this.paramService.data.next(false);
    this.paramService
      .getDashboardData(dateRangeStart.value, dateRangeEnd.value)
      .subscribe((res) => {});
    this.paramService.hidePred.next(true);
    this.paramService
      .avgCokeYieldData(dateRangeStart.value, dateRangeEnd.value)
      .subscribe((res) => {
        this.cokeYield = res[0].coke_yields;
        // console.log(this.cokeYield);
        this.paramService.actYield.next(this.cokeYield);
      });

    this.paramService
      .getCokePrePredictedYieldData(
        dateRangeStart.value,
        dateRangeEnd.value,
        'Weekly'
      )
      .subscribe((res) => {
        // console.log('preCoke', res[0].current_pre_recommended_coke_yields);
        this.preRecCokeYield = res[0].current_pre_recommended_coke_yields;
        this.paramService.predYield.next(this.preRecCokeYield);
      });

    this.paramService
      .getCokePrePredictedYieldData(
        dateRangeStart.value,
        dateRangeEnd.value,
        ''
      )
      .subscribe((res) => {
        // console.log('preCoke', res[0].current_pre_recommended_coke_yields);
        this.postRecCokeYield = res[0].current_post_recommended_coke_yields;
        this.paramService.postYield.next(this.postRecCokeYield);
      });
  }

  changeActualParameter() {
    this.paramService.selectedParameter.next(this.selectedParameter);
    if (this.selectedParameter === 'input Feed') {
      this.paramService.inputFeedActData.subscribe((res: any) => {
        this.actualValuesData = res;
      });
      // this.paramService.inputFeedPreData.subscribe((res: any) => {
      //   this.predictedValuesData = res;
      // });
    } else if (this.selectedParameter === 'Coke Drum') {
      this.paramService.cokeDrumActData.subscribe((res: any) => {
        this.actualValuesData = res;
      });
      // this.paramService.cokeDrumPreData.subscribe((res: any) => {
      //   this.predictedValuesData = res;
      // });
    } else if (this.selectedParameter === 'Heater') {
      this.paramService.heaterActData.subscribe((res: any) => {
        this.actualValuesData = res;
      });
      // this.paramService.heaterPreData.subscribe((res: any) => {
      //   this.predictedValuesData = res;
      // });
    } else if (this.selectedParameter === 'Fractionator') {
      this.paramService.fractActData.subscribe((res: any) => {
        this.actualValuesData = res;
      });
      // this.paramService.fractPreData.subscribe((res: any) => {
      //   this.predictedValuesData = res;
      // });
    }
    // this.paramService.selectedVersion.subscribe((res: any) => {
    //   this.selectedVersion = res;
    // });
  }
}
