<div class="main_view">
  <div class="header">
    <img
      class="logo"
      src="./assets/logo/LivNSense Logo.svg"
      alt="livNSense"
    />
    <div class="support">
      <div>Get help</div>
      <img src="./assets/icons/Frame.svg" alt="support" />
    </div>
  </div>
  <div class="center_container">
    <div class="left-part"><img src="assets/img/Frame%201(1).svg"></div>
    <div class="right-part">
    <div class="heading">Welcome to GreenOps</div>
    <div class="form_container">
      <form #forgetPwForm="ngForm">
        <div class="input-container">
          <input
            type="text"
            id="email"
            name="email"
            [(ngModel)]="email"
            required
            #emailInput="ngModel"
            placeholder="Mail ID"
          />
          <img class="input-icon" src="./assets/icons/Mail.svg" />
          <img
            *ngIf="isOTP && countdown == 0"
            class="resend-icon"
            (click)="resendOTP()"
            src="./assets/icons/Resend.svg"
          />
        </div>
        <div *ngIf="errorHandler && !isOTP" class="error-message">
          <!-- <div class="error-message-heading">
            <img src="../../../../../assets/Icons/Warning.svg" />Uh oh! We're
            having trouble signing you in.
          </div> -->
          <div class="error-message-content" style="margin-top: 8px;">
            {{ errorHandler }}
          </div>
        </div>
        <!-- <div *ngIf="EmailMessage" class="success-message">
            <div class="success-message-heading">
              <img src="../../../../../assets/Icons/Warning.svg" />Uh oh! We're
              having trouble signing you in.
            </div>
            <div class="success-message-content" style="margin-top: 8px;">
              {{ EmailMessage }}
            </div>
          </div> -->
        <br *ngIf="isOTP" />
        <div *ngIf="isOTP" class="input-container">
          <input
            type="text"
            id="otp"
            name="otp"
            pattern="[0-9]{6}"
            [(ngModel)]="otp"
            placeholder="Enter OTP"
            required
            maxlength="6"
            #otpInput="ngModel"
          />
          <img class="input-icon" src="./assets/icons/Lock.svg" />
        </div>
        <div class="resend-otp" *ngIf="isOTP">
          {{ isOTPSent ? "Resend in " + countdown + "sec" : "Resend OTP" }}
        </div>
        <div class="error-message" *ngIf="errorHandler && isOTP">
          <!-- <div class="error-message-heading">
            <img src="../../../../../assets/Icons/Warning.svg" />Uh oh! We're
            having trouble signing you in.
          </div> -->
          <div class="error-message-content" style="margin-top: 8px;">
            {{ errorHandler }}
          </div>
        </div>
        <span style="display: flex; justify-content: center; margin-top: 20px">
          <span
            style="display: flex; justify-content: space-between; gap: 15px"
          >
            <button
              *ngIf="!isOTP"
              class="send-mail-btn"
              type="submit"
              [disabled]="forgetPwForm.invalid"
              (click)="getOTPform()"
            >
              Send OTP
            </button>
            <button
              *ngIf="isOTP"
              class="send-mail-btn"
              type="submit"
              [disabled]="otp.invalid"
              (click)="goToResetPasswordform()"
            >
              Reset
            </button>
            <button
              class="back-sign-in-btn"
              type="submit"
              (click)="goToLoginform()"
            >
              Back to Sign In
            </button>
          </span>
        </span>
      </form>
      </div>
    </div>
  </div>
  <div class="footer">
    &copy;{{year}} LivNSense . All Rights Reserved . Powered by GreenOps™&reg;
  </div>
</div>
