import { Component, Input, OnInit } from '@angular/core';
// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ParamService } from 'src/app/core/param.service';

@Component({
  selector: 'app-predicted1-main-yield-line-graph',
  templateUrl: './predicted1-main-yield-line-graph.component.html',
  styleUrls: ['./predicted1-main-yield-line-graph.component.scss'],
})
export class Predicted1MainYieldLineGraphComponent implements OnInit {
  @Input() startDate: any[];
  @Input() endDate: any[];
  @Input() graphData: any;
  @Input() period: any;
  @Input() chartId: any;
  constructor(private paramService: ParamService) {}

  ngOnInit(): void {
    // console.log(this.startDate, this.endDate);
  }

  ngAfterViewInit() {
    // Themes begin
    am4core.useTheme(am4themes_animated);
    // Themes end

    // Color set
    var colors = new am4core.ColorSet();

    // Create chart instance
    let chart: any = am4core.create(this.chartId, am4charts.XYChart3D);
    chart.background.fill = '#3C4358';
    chart.responsive.enabled = true;

    // chart.paddingRight = 20;
    chart.logo.disabled = true;

    // chart.dateFormatter.timezoneOffset = -9000;
    chart.dateFormatter.inputDateFormat = 'yyyy-MM-dd';

    //Add scrollbar
    chart.scrollbarX = new am4core.Scrollbar();
    chart.scrollbarX.parent = chart.bottomAxesContainer;

    // Add legend
    chart.legend = new am4charts.Legend();
    chart.legend.position = 'top';
    chart.legend.labels.template.fill = am4core.color('#fff');
    chart.legend.labels.template.fontWeight = 'bold';
    // chart.legend.reverseOrder = true;

    // Enable export
    chart.exporting.menu = new am4core.ExportMenu();

    chart.exporting.timeoutDelay = 10000;
    chart.exporting.filePrefix = 'DCU';

    chart.exporting.menu.items = [
      {
        label: '...',
        menu: [
          {
            label: 'Image',
            menu: [
              { type: 'png', label: 'PNG' },
              { type: 'jpg', label: 'JPG' },
              { type: 'svg', label: 'SVG' },
              { type: 'pdf', label: 'PDF' },
            ],
          },
          {
            label: 'Data',
            menu: [
              { type: 'json', label: 'JSON' },
              { type: 'csv', label: 'CSV' },
              { type: 'xlsx', label: 'XLSX' },
              { type: 'html', label: 'HTML' },
            ],
          },
        ],
      },
    ];

    chart.exporting.menu.items[0].icon =
      'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+';

    //  chart.data = this.graphData[0]['actual_values'];

    // Create axes
    let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 30;
    dateAxis.title.text = 'Date';
    dateAxis.title.fill = '#fff';
    dateAxis.title.fontWeight = 'bold';
    dateAxis.renderer.labels.template.fill = am4core.color('#fff');
    dateAxis.renderer.labels.template.fontSize = 15;
    // dateAxis.skipEmptyPeriods = true;
    // dateAxis.groupInterval = { timeUnit: 'hour', count: 1 };

    // this makes the data to be grouped
    dateAxis.groupData = true;
    dateAxis.groupCount = 500;
    dateAxis.groupInterval = { timeUnit: 'day', count: 1 };

    // dateAxis.minZoomCount = 50;

    let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = 'Value';
    valueAxis.title.fill = '#fff';
    valueAxis.title.fontWeight = 'bold';
    valueAxis.renderer.labels.template.fill = am4core.color('#fff');
    valueAxis.renderer.labels.template.fontSize = 15;

    // Add cursor
    chart.cursor = new am4charts.XYCursor();
    // chart.cursor.snapToSeries = series;
    chart.cursor.xAxis = dateAxis;

    //Create line
    function createSeries(data, name, color) {
      let series = chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = 'predicted_value';
      series.dataFields.dateX = 'timestamp';
      series.name = name;
      // series.tooltipText = '{name}: [bold]{predicted_value} %';
      series.tooltipText =
        "{name}: [bold]{predicted_value.formatNumber('#.00')} % [/]";
      series.tooltip.pointerOrientation = 'vertical';
      series.tooltip.getFillFromObject = false;
      series.tooltip.background.fill = color;
      series.tooltip.label.fill = am4core.color('#fff');
      series.stroke = color;
      series.data = data;
      series.strokeWidth = 3;
      return series;
    }

    this.paramService.getPredictedYieldData(this.period).subscribe((res) => {
      for (const [key, value] of Object.entries(res[0][this.graphData])) {
        let color;
        if (this.paramService.colorCode.find((x) => x.value === key)) {
          color = this.paramService.colorCode.find((x) => x.value === key)
            .color;
        } else if (this.paramService.colorCode.find((x) => x.value2 === key)) {
          color = this.paramService.colorCode.find((x) => x.value2 === key)
            .color;
        } else {
          color = '#fff';
        }
        createSeries(value, key, color);
      }
    });
  }
}
