import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ActualParameterGraphModalComponent } from '../actual-parameter-graph-modal/actual-parameter-graph-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ParamService } from 'src/app/core/param.service';
import { MsgBoxComponent } from '../msg-box/msg-box.component';

@Component({
  selector: 'app-parameter-table',
  templateUrl: './parameter-table.component.html',
  styleUrls: ['./parameter-table.component.scss'],
})
export class ParameterTableComponent implements OnInit {
  @Input() selection;
  @Input() selectedParameter;
  @Input() header;
  @Input() isConfig;
  @Output() onMinInputValueChanged = new EventEmitter<boolean>();
  @Output() onMaxInputValueChanged = new EventEmitter<boolean>();
  isNotPredictedLine: boolean;
  chartId: any;
  selectedGraphList: any[] = [];
  isOpendModal: boolean;
  avg: string;
  avgTitle: string;
  ifData: boolean;

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private paramService: ParamService
  ) {
    this.paramService.avg.subscribe((res) => {
      this.avgTitle = res;
    });

    this.paramService.checkVal.subscribe((res) => {
      this.ifData = res;
    });
  }

  ngOnInit(): void {}

  ngOnChanges(): void {
    // console.log(this.selectedParameter);
  }

  changeInMin() {
    this.onMinInputValueChanged.emit(this.selectedParameter);
  }

  changeInMax() {
    this.onMaxInputValueChanged.emit(this.selectedParameter);
  }

  openModal(value) {
    if (this.header == 'PROCESS VALUE') {
      this.isNotPredictedLine = true;
      this.chartId = value + 'Act';
    } else if (this.header == 'DCU ACTUAL OTHER YIELDS') {
      this.isNotPredictedLine = true;
      this.chartId = value + 'Act';
    } else {
      this.isNotPredictedLine = false;
      this.chartId = value + 'Pre';
    }
    if (this.selectedGraphList.includes(this.chartId)) {
      this.paramService.openDuplicateGraphSnackBar();
    } else {
      this.selectedGraphList.push(this.chartId);
      this.paramService.graphPopupTopPosition += 2;
      const modalRef = this.dialog.open(ActualParameterGraphModalComponent, {
        disableClose: true,
        hasBackdrop: false,
        closeOnNavigation: true,
        panelClass: 'normal-modal-class',
        position: {
          top: this.paramService.graphPopupTopPosition.toString() + '%',
        },
      });
      this.router.events.subscribe(() => {
        modalRef.close();
      });
      modalRef.componentInstance.chartId = this.chartId;
      modalRef.componentInstance.isNotPredictedLine = this.isNotPredictedLine;
      modalRef.componentInstance.selectedParameter = value;
      modalRef.componentInstance.fromParamTable = this.selectedParameter;

      modalRef.afterClosed().subscribe((result) => {
        this.selectedGraphList.splice(
          this.selectedGraphList.indexOf(result),
          1
        );
        this.paramService.graphPopupTopPosition -= 2;
      });
    }
  }

  headerColor() {
    let styles = {
      color:
        this.header === 'PROCESS VALUE'
          ? '#ffff'
          : this.header === 'DCU ACTUAL OTHER YIELDS'
          ? '#ffff'
          : '#f2d405',
    };
    return styles;
  }

  dotColor() {
    let styles = {
      background:
        this.header === 'PROCESS VALUE'
          ? '#ffff'
          : this.header === 'DCU ACTUAL OTHER YIELDS'
          ? '#ffff'
          : '#f2d405',
    };
    return styles;
  }
}
