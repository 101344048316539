<div class="card border-0">
  <div class="card-header">
    <div class="row">
      <div class="col-md-6">
        <p class="h5 text-white font-weight-bold ">Pre-Recommendation</p>
        <div class="row">
          <div class="col-md-6">
            <p class="font-weight-bold" style="color: #1FC67A; font-size: 1.1rem;">{{avgTitle}}Actual Yield <br />&nbsp;<span *ngIf="ifData"
                class="font-weight-none">{{cokeYield |
                number: '1.2-2'}}%</span></p>
          </div>
          <div class="col-md-6 cardBar text-center p-0">
            <p class="font-weight-bold" style="color: #ffde00; font-size: 1.1rem;">{{avgTitle}}Predicted Yield<br />&nbsp;<span *ngIf="ifData"
                class="font-weight-none">
                {{preRecCokeYield |
                number: '1.2-2'}}%</span></p>
            <select [disabled]="ifData === false" id="my-select" class="selectParam" name="predictionPeriod1" [(ngModel)]="predictionPeriod1"
              (change)="changePeriod1()">
              <option *ngFor="let period of periodList1" [value]="period">{{period}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6 cardBar text-center"
        data-tip="yay, tooltip content! provided by your friendly data-* attribute">
        <p class="h5 text-white font-weight-bold">Post-Recommendation</p>
        <div class="row">
          <div class="col-md-6">
            <p class="font-weight-bold" style="color: #ffde00; font-size: 1.1rem;">Coke Yield <br /><span 
                *ngIf="strAlgo || isUser && ifData" class="font-weight-none">{{postRecCokeYield|
                number: '1.2-2'}}%</span></p>
            <select *ngIf="strAlgo || isUser" id="my-select" class="selectParam" name="predictionPeriod2"
              [(ngModel)]="predictionPeriod2" (change)="changePeriod2()">
              <option *ngFor="let period of periodList2" [value]="period">{{period}}</option>
            </select>
          </div>
          <div class="col-md-6 cardBar text-center p-0">
            <p class="font-weight-bold" style="color: #ffde00; font-size: 1.1rem;"> Reduction in Coke %<br /><span
                *ngIf="strAlgo || isUser" class="font-weight-none">
                {{((cokeYield - postRecCokeYield) < 0) ? 0 : (cokeYield - postRecCokeYield) | number:'1.2-2'}}%</span>
            </p>

            <div class="item-hints toolTip" *ngIf="!strAlgo && !isUser">
              <div class="hint" data-position="4">
                <!-- <span class="hint-radius"></span>
                <span class="hint-dot"></span> -->
                <i class="fas fa-info-circle text-white"></i>
                <div class="hint-content do--split-children">
                  <p class="tooltipMsg p-2 rounded">Click on button to start AI based Engine for Recommendation
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">
    <app-dcu></app-dcu>
  </div>
</div>
