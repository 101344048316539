<div class="customModal" appGraphDraggable>
    <div class="modal-body">
        <div class="row">
            <div class="col d-flex">
                <div class="form-group">
                    <!-- <label for="my-select">Text</label> -->
                    <select id="my-select" class="selectParam custom-select" name="predictionPeriod"
                        [(ngModel)]="predictionPeriod" (change)="changePeriod()">
                        <option *ngFor="let period of periodList" [value]="period">{{period}}</option>
                    </select>
                </div>
                <p class="h4 text-light mx-auto">Prediction Yields</p>
                <button type="button" class="close text-right" aria-label="Close button" data-dismiss="modal"
                    (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- <div class="col">
                <p class="text-light">Prediction Yield</p>
            </div> -->
        </div>
        <div *ngIf="!isLoadingComplete" style="width: 100%; height: 500px;padding-top: 30%;">
            <p class="h5 text-light text-center">Loading...</p>
        </div>
        <div *ngIf="isLoadingComplete">
            <app-predicted1-main-yield-graph *ngIf="predictionPeriod =='Daily'">
            </app-predicted1-main-yield-graph>
            <app-predicted1-main-yield-line-graph *ngIf="predictionPeriod !='Daily'" [period]="period"
                [chartId]="chartId" [graphData]="graphData">
            </app-predicted1-main-yield-line-graph>
        </div>
    </div>
</div>