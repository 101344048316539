import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private themeSubject = new BehaviorSubject<string>('lighttheme'); // Initial theme is light
  public theme$ = this.themeSubject.asObservable();

  toggleTheme() {
    const newTheme = this.themeSubject.value === 'lighttheme' ? 'darktheme' : 'lighttheme';
    this.themeSubject.next(newTheme);
  }
}
