import { DcuGraphModalComponent } from './../dcu-graph-modal/dcu-graph-modal.component';
import { ParamService } from 'src/app/core/param.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-dcu',
  templateUrl: './dcu.component.svg',
  styleUrls: ['./dcu.component.scss'],
})
export class DcuComponent implements OnInit {
  @Input() selection;
  @Input() selectedParameter;
  @Input() header;
  @Input() isConfig;
  @Output() onMinInputValueChanged = new EventEmitter<boolean>();
  @Output() onMaxInputValueChanged = new EventEmitter<boolean>();
  value: '';
  cokeYield: any;
  isUser = false;
  isNotPredictedLine: boolean = true;
  chartId: any;
  selectedGraphList: any[] = [];
  ifData: boolean;
  drumState: any;

  constructor(
    private paramService: ParamService,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.paramService.checkVal.subscribe((res) => {
      this.ifData = res;
      if (this.ifData == false) {
        this.drumState = 'offline';
      } else {
        this.drumState = 'online';
      }
    });
  }

  ngOnInit(): void {
    this.paramService.getCokeYieldData().subscribe((res) => {
      this.cokeYield = res[0].coke_yields;
      // console.log('Test' + JSON.stringify(res));
    });

    let token = localStorage.getItem('currentUser');
    let subToken = token.substr(2, token.length - 3);
    let decodeToken = jwt_decode(subToken);
    if (decodeToken['first_name'] == 'engineer') {
      this.isUser = true;
    } else {
      this.isUser = false;
    }

    this.paramService.actYield.subscribe((res) => {
      // console.log(res);
      this.cokeYield = res;
    });
  }

  changeInMin() {
    this.onMinInputValueChanged.emit(this.selectedParameter);
  }

  changeInMax() {
    this.onMaxInputValueChanged.emit(this.selectedParameter);
  }

  openModal(value) {
    // this.paramService.selectedYieldParameter.next(value);
    if (this.selectedGraphList.includes(value)) {
      this.paramService.openDuplicateGraphSnackBar();
    } else {
      this.selectedGraphList.push(value);
      this.paramService.graphPopupTopPosition += 2;
      const modalRef = this.dialog.open(DcuGraphModalComponent, {
        disableClose: true,
        hasBackdrop: false,
        closeOnNavigation: true,
        panelClass: 'normal-modal-class',
        position: {
          top: this.paramService.graphPopupTopPosition.toString() + '%',
        },
      });
      this.router.events.subscribe(() => {
        modalRef.close();
      });
      modalRef.componentInstance.selectedParameter = value;
      modalRef.componentInstance.chartId = value + 'factoryImage';
      modalRef.afterClosed().subscribe((result) => {
        this.selectedGraphList.splice(
          this.selectedGraphList.indexOf(result),
          1
        );
        this.paramService.graphPopupTopPosition -= 2;
      });
    }
  }
}
