import { Component, OnInit, Inject } from '@angular/core';
import { BalcoService } from 'src/app/core/balco.service';

@Component({
  selector: 'app-param-table-modal',
  templateUrl: './param-table-modal.component.html',
  styleUrls: ['./param-table-modal.component.scss'],
})
export class ParamTableModalComponent implements OnInit {
  recom_values: any;
  tables: any;
  p: number = 1;
  inputForm: any;
  //actualVal: any;

  isLoading: boolean;

  selectButton = ['Pot Temperature', 'Pot Excess Alf3%'];
  selectedButton = this.selectButton[0];
  today: number = Date.now();
  tomorrow: Date = new Date();
  kpis: any;   //made chnges kips to kpis
 
  tempVal: any;
  xalf3Val: any;
  energyVal: any;
  alf3AddVal: any;
  alumFormVal: any;
  alumTapVal: any;
  anodeVal: any;
  temperatureVal: any;
  errMesage;
  submitted: boolean;
  saves: any;
  msg: any;
  dateInvalid: any;
  toastr: any;
  potNo: any;
 

  
  
  constructor(

    private balcoService: BalcoService
  ) 
  {
   // this.tables = this.data.tables;

    this.balcoService.potNo.subscribe((res) => {
      this.potNo = res;
     console.log("recomendation data",this.potNo)
    });
    
  }

  

  get f() {
    return this.inputForm.controls;
  }

  ngOnInit() {
    this.isLoading = true;
  
    this.getActPrediction();
  }
 
  //Realtime Optimization of Smelting Operations
  getActPrediction() {
    console.log("Recomd",this.potNo)
    this.balcoService.getROptimization(this.potNo).subscribe((res) => {
      this.recom_values = res[0][0];
      //this.actualVal = res[1]; 
      console.log("1",this.recom_values.current_working_voltage);
      console.log("2",this.recom_values.case_working_voltage);
      console.log("3",this.recom_values.improved_working_voltage);
           
    });
  }
}
