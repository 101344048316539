import { Component, Input, OnInit } from '@angular/core';
// amCharts imports
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_dataviz from '@amcharts/amcharts4/themes/dataviz';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import { ParamService } from 'src/app/core/param.service';

@Component({
  selector: 'app-predicted-main-yield-graph',
  templateUrl: './predicted-main-yield-graph.component.html',
  styleUrls: ['./predicted-main-yield-graph.component.scss'],
})
export class PredictedMainYieldGraphComponent implements OnInit {
  graphData: any[] = [];
  @Input() pieChartData: any[];
  constructor(private paramService: ParamService) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    // Themes begin
    am4core.useTheme(am4themes_dataviz);
    am4core.useTheme(am4themes_animated);
    // Themes end

    let chart = am4core.create('postRecMainPieChart', am4charts.PieChart3D);

    chart.hiddenState.properties.opacity = 0; // this creates initial fade-in

    //To increase chart size
    chart.radius = am4core.percent(80);

    chart.responsive.enabled = true;

    //To disable amchart logo
    chart.logo.disabled = true;

    chart.legend = new am4charts.Legend();
    chart.legend.position = 'bottom';
    chart.legend.labels.template.fill = am4core.color('#fff');
    chart.legend.labels.template.fontWeight = 'bold';
    chart.legend.labels.template.fontSize = 11;
    // chart.legend.labels.template.maxWidth = 10;
    // chart.legend.labels.template.truncate = true;
    chart.legend.labels.template.padding(0, 0, 0, 0);

    //To disable percentage
    chart.legend.valueLabels.template.disabled = true;

    // Add chart title
    // let title = chart.titles.create();
    // title.text = 'Parameter Recommendation';
    // title.fontSize = 25;
    // title.fill = am4core.color('#fff');
    // title.marginBottom = 30;

    this.paramService.getYieldsPieChartData().subscribe((res) => {
      res[0]['recommended_yields_data'].forEach(
        (element) =>
          (element['parameter_value'] = this.paramService.displayGraphDecimal(
            element['parameter_value']
          ))
      );
      let valueList =
        100 -
        res[0]['recommended_yields_data']
          .map((element) => element['parameter_value'])
          .reduce((a, b) => Number(a) + Number(b));
      res[0]['recommended_yields_data'][
        res[0]['recommended_yields_data'].length
      ] = {
        parameter_value: valueList,
        parameter_name: 'Other',
      };

      for (let i = 0; i < res[0]['recommended_yields_data'].length; i++) {
        if (
          this.paramService.colorCode.find(
            (x) =>
              x.value === res[0]['recommended_yields_data'][i]['parameter_name']
          )
        ) {
          res[0]['recommended_yields_data'][i][
            'color'
          ] = this.paramService.colorCode.find(
            (x) =>
              x.value === res[0]['recommended_yields_data'][i]['parameter_name']
          ).color;
        } else if (
          this.paramService.colorCode.find(
            (x) =>
              x.value2 ===
              res[0]['recommended_yields_data'][i]['parameter_name']
          )
        ) {
          res[0]['recommended_yields_data'][i][
            'color'
          ] = this.paramService.colorCode.find(
            (x) =>
              x.value2 ===
              res[0]['recommended_yields_data'][i]['parameter_name']
          ).color;
        } else {
          res[0]['recommended_yields_data'][i][
            'color'
          ] = this.paramService.otherYieldColor;
        }
      }
      this.graphData.push(res[0]);
      chart.data = this.graphData[0]['recommended_yields_data'];
      // chart.data = this.pieChartData;
    });

    let series = chart.series.push(new am4charts.PieSeries3D());
    series.dataFields.value = 'parameter_value';
    series.dataFields.category = 'parameter_name';
    series.slices.template.propertyFields.fill = 'color';
    series.innerRadius = am4core.percent(50);
    series.slices.template.tooltipText =
      "{category}: [bold]{value.percent.formatNumber('#.00')} % [/]";
    series.slices.template.states.getKey('hover').properties.scale = 1;
    series.slices.template.states.getKey('active').properties.shiftRadius = 0;
    series.strokeWidth = 3;

    // Disable ticks and labels
    // series.labels.template.disabled = true;
    // series.ticks.template.disabled = true;
    series.ticks.template.stroke = am4core.color('#ffff');
    series.ticks.template.strokeWidth = 2;
    // series.alignLabels = false;
    series.labels.template.text = "{value.percent.formatNumber('#.00')}%";
    series.labels.template.radius = am4core.percent(30);
    // series.labels.template.relativeRotation = 90;
    series.labels.template.fill = am4core.color('#fff');
    series.labels.template.fontSize = 15;
    series.labels.template.fontWeight = 'bold';
    // series.labels.template.maxWidth = 50;
    // series.labels.template.wrap = true;

    // Enable export
    chart.exporting.menu = new am4core.ExportMenu();

    chart.exporting.timeoutDelay = 10000;
    chart.exporting.filePrefix = 'DCU';

    chart.exporting.menu.items = [
      {
        label: '...',
        menu: [
          {
            label: 'Image',
            menu: [
              { type: 'png', label: 'PNG' },
              { type: 'jpg', label: 'JPG' },
              { type: 'svg', label: 'SVG' },
              { type: 'pdf', label: 'PDF' },
            ],
          },
          {
            label: 'Data',
            menu: [
              { type: 'json', label: 'JSON' },
              { type: 'csv', label: 'CSV' },
              { type: 'xlsx', label: 'XLSX' },
              { type: 'html', label: 'HTML' },
            ],
          },
        ],
      },
    ];

    chart.exporting.menu.items[0].icon =
      'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxNnB4IiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxNiAxNiIgd2lkdGg9IjE2cHgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6c2tldGNoPSJodHRwOi8vd3d3LmJvaGVtaWFuY29kaW5nLmNvbS9za2V0Y2gvbnMiIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48dGl0bGUvPjxkZWZzLz48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGlkPSJJY29ucyB3aXRoIG51bWJlcnMiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIj48ZyBmaWxsPSIjMDAwMDAwIiBpZD0iR3JvdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC03MjAuMDAwMDAwLCAtNDMyLjAwMDAwMCkiPjxwYXRoIGQ9Ik03MjEsNDQ2IEw3MzMsNDQ2IEw3MzMsNDQzIEw3MzUsNDQzIEw3MzUsNDQ2IEw3MzUsNDQ4IEw3MjEsNDQ4IFogTTcyMSw0NDMgTDcyMyw0NDMgTDcyMyw0NDYgTDcyMSw0NDYgWiBNNzI2LDQzMyBMNzMwLDQzMyBMNzMwLDQ0MCBMNzMyLDQ0MCBMNzI4LDQ0NSBMNzI0LDQ0MCBMNzI2LDQ0MCBaIE03MjYsNDMzIiBpZD0iUmVjdGFuZ2xlIDIxNyIvPjwvZz48L2c+PC9zdmc+';
  }
}
