<nav class="navbar navbar-dark navbar-expand-md fixed-top">
  <div class="container-fluid p-0">
    <!-- <div class="logo m-n3"> -->
      <!-- <img class="navbar-brand px-3" src="../../../assets/logo/lnslogo.png" alt="Cognitive demo"> -->
    <!-- </div> -->
    <button data-toggle="collapse" class="navbar-toggler" data-target="#navcol-1"><span class="sr-only">Toggle
        navigation</span><span class="navbar-toggler-icon"></span></button>
         <!-- <button type="button" class="ml-5 mb-2 mt-2 btn btn-primary" (click)="changeRoute('dcu')">Demo DCU</button>
         <button type="button" class="ml-1 mb-2 mt-2 btn btn-primary" (click)="changeRoute('frl')">Demo FRL</button> -->
         <!-- <div class="col-md-2 pl-3 pb-2">
          <select name="SelectedDomain" class="form-control text-light selectParam" [(ngModel)]="SelectedDomain"
          (change)="changeRoute($event)">
          <option *ngFor="let option of SelectDomain" [value]="option.id">{{option.name}}</option>
          </select>
         
        </div> -->
         <!-- <ul class="nav nav-pills ml-3 mr-auto d-none d-sm-flex">
          <li class="nav-item ">
              <a class="nav-link" [routerLink]="['/dashboard/plant-admin']" routerLinkActive="active" href="./dashboard/plant-admin">DCU Demo</a>
          </li>
          <li class="nav-item ">
              <a class="nav-link " [routerLink]="['/dashboard/plant-frl']" routerLinkActive="active" href="./dashboard/plant-frl">FRL Demo</a>
          </li>
          <li class="nav-item ">
            <a class="nav-link " [routerLink]="['/dashboard/balco-dashboard']" routerLinkActive="active" href="./dashboard/balco-dashboard">BALCO Demo</a>
        </li>
        </ul> -->
    <div class="collapse navbar-collapse" id="navcol-1">
      <ul class="nav navbar-nav text-right text-white ml-auto">
        <li class="nav-item" role="presentation">
          <p class="h5 pt-2 mr-auto font-weight-bold text-shadow" style="color: white;"><span
              class="h4 font-weight-bold text-white text-shadow">Cognitive 4.0 Solutions - </span><span style="font-size:20px">Powered  by</span>&nbsp;<img src="assets\logo\greenOps.png" width="150px" alt=""></p>
        </li>
      </ul>
      <!--Top user icon-->
      <ul class="nav navbar-nav ml-auto">
        <li class="nav-item"></li>
        <!-- <li class="nav-item pr-3" role="presentation"><a class="nav-link text-white">Date:
            {{today | date:'dd-MM-yyyy'}}</a></li> -->
        <img class="img-fluid border-50 avatar d-flex" src="../../../assets/logo/img_avatar.png" alt="profile-icon">
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle font-weight-bold text-white" href="#" id="navbarDropdown" role="button"
            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span
              class="font-weight-bold text-primary">Welcome</span>
            {{fname}}
          </a>
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" href="#" (click)="logout()" routerLink="/login"><i
                class="fas fa-sign-out-alt p-1"></i>Logout</a>
          </div>
        </li>
      </ul>
      <!-- End-->

    </div>
  </div>
</nav>
