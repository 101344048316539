<section class="card mb-4">
  <p class="table-title mb-0 py-2" style="color: yellow;"><span class="dot" style="color: yellow;"></span>Optimized Parameters
  </p>
  <div class="table-responsive" id="demo">
    <table class="table table-borderless tableBody">
      <thead>
        <tr class="main-table-row text-left" style="color: yellow;">
          <td scope="col font-weight-bold"></td>
          <td scope="col font-weight-bold">Zone 1</td>
          <td scope="col font-weight-bold">Zone 2</td>
          <td scope="col font-weight-bold">Zone 3</td>
        </tr>
      </thead>
      <tbody>
        <tr class="table-data trow" *ngFor="let paramValue of paramValues"
          (click)="openSingleParamDialog(paramValue.parameter)">
          <td class="row-data cellBorder text-left">{{paramValue.parameter}}<span
              class="h6 font-weight-bold px-2">{{paramValue.uom}}</span></td>
          <td class="row-data cellBorder text-left" style="color: white;">{{paramValue.zone1 |
                number: '1.2-2'}}</td>
          <td class="row-data cellBorder text-left" style="color: white;">{{paramValue.zone2 |
                number: '1.2-2'}}</td>
          <td class="row-data cellBorder text-left" style="color: white;">{{paramValue.zone3 |
                number: '1.2-2'}}</td>
        </tr>

      </tbody>
    </table>
  </div>
</section>
