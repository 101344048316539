import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddUserDialogComponent } from '../../dailogs/add-user/add-user.component';
import { DeleteUserDialogComponent } from '../../dailogs/delete-user/delete-user.component';
import { ModifyUserDialogComponent } from '../../dailogs/modify-user/modify-user.component';
import { ApiCallsService } from '../../services/api-calls.service';
import { SnackbarComponent } from '../snackbar/snackbar.component';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UserManagementComponent implements OnInit {

  usersData: any;
  user: any;

  constructor(private dialog: MatDialog,
    private apiCallsService: ApiCallsService,
    private snackBar: MatSnackBar) { }

  ngOnInit() {
    this.getUsers();
  }

  openUpdateUserDialog(user) {
    const dialogRef = this.dialog.open(ModifyUserDialogComponent, {
      width: '30%',
      disableClose: true,
      data: JSON.parse(JSON.stringify(user)),
      panelClass: 'management-dialog'
    });
  }

  /* Method which open the dialog to delete user */
  openDeleteUserDialog(user) {
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      width: '450px',
      disableClose: true,
      data: user['login_id'],
      panelClass: 'management-dialog'
    });
  }

  /* Method which open the dialog to modify user account count */
  // openModifyUserCount(user) {

  //   const dialogRef = this.dialog.open(ModifyUserAcountCountDialogComponent, {
  //     width: '450px',
  //     disableClose: true,
  //     panelClass: 'management-dialog',
  //     data: Object.assign({}, {
  //       admin_user: this.usersData['admin_count'],
  //       standard_user: this.usersData['standard'],
  //       parallel_sessions: this.usersData['parallel_sessions']
  //     })
  //   });
  // }

  /* Method which open the dialog to add user */
  openAddUserDialog() {
    const dialogRef = this.dialog.open(AddUserDialogComponent, {
      width: '450px',
      disableClose: true,
      panelClass: 'management-dialog'
    });
  }

  openSnackBar(res) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1500,
      data: res,
      horizontalPosition: 'start',
      verticalPosition: 'bottom'
    });
  }
  
  /* Method which calls the api calls service to get all the users */
  getUsers() {
    this.apiCallsService.getAllUsers().subscribe(data => {
      this.usersData = data;
    }, err => {
      let usersData = [{ name: 'Test', login_id: '12345', email_id: 'Test@123', designation: 'super_admin', user_type: 'Super Admin' }, { name: 'Test-user', login_id: '11111', email_id: 'Testuser@123', designation: 'admin', user_type: 'Admin' }];
      this.usersData = usersData;
      console.log(err);
      this.openSnackBar(err['error']);
    })
  }
}
