import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ParamService } from 'src/app/core/param.service';
import { PredParamModalComponent } from '../pred-param-modal/pred-param-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-optimized-measures',
  templateUrl: './optimized-measures.component.html',
  styleUrls: ['./optimized-measures.component.scss'],
})
export class OptimizedMeasuresComponent implements OnInit {
  selected: number;

  paramValues: any;

  constructor(
    public dialog: MatDialog,
    private paramService: ParamService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.paramService.selectedRun.subscribe((res) => {
      this.selected = res;
      console.log(this.selected);
    });
    this.singleParam();
  }

  openSingleParamDialog(param): void {
    const dialogRef = this.dialog.open(PredParamModalComponent, {
      // disableClose: true,
      // hasBackdrop: true,
      // closeOnNavigation: true,
      panelClass: 'normal-modal-class',
      data: {
        param: param,
        run: this.selected,
        zone: 1,
      },
    });

    //close on navigation
    this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  ngOnChanges() {
    // if (this.selectedRun == 1) this.paramValue = this.run1;
    // else if (this.selectedRun == 2) this.paramValue = this.run2;
    // else if (this.selectedRun == 3) this.paramValue = this.run3;
    // else if (this.selectedRun == 4) this.paramValue = this.run4;
  }

  singleParam() {
    this.paramService.furnaceOptimzed(this.selected).subscribe((res) => {
      this.paramValues = res[0];
    });
  }

  returnZero() {
    return 0;
  }
}
