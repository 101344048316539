import { AuthenticationService } from '../../core/authentication.service';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ParamService } from 'src/app/core/param.service';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  SelectDomain = [
    { id: 0, name: 'Home' },
    { id: 1, name: 'Chemical - Demo' },
    { id: 2, name: 'Petro chemical - Demo' },
    { id: 3, name: 'ALF3 - Demo' },
  ];
  SelectedDomain: any = 0;

  decodeToken: any;
  fname: string;
  buttonVal: any;
  toggle: any;
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    public paramService: ParamService
  ) {
    //  this.paramService.SelectedDomain.subscribe((res: any) => {
    //    this.SelectedDomain = res;
    //  });
    //this.changeRoute(1);
  }

  ngOnInit(): void {
    this.fname = 'Plant Administrator';
  }

  changeRoute(event) {
    console.log(event.target.value);
    //this.paramService.SelectedDomain.next(this.SelectedDomain);
    let domain = event.target.value;
    this.SelectedDomain = domain;
    this.paramService.SelectedDomain = this.SelectedDomain;
    //this.param
    if (this.SelectedDomain == 0)
      this.router.navigateByUrl('/dashboard/landingpage');
    else if (this.SelectedDomain == 1)
      this.router.navigateByUrl('/dashboard/plant-frl');
    else if (this.SelectedDomain == 2)
      this.router.navigateByUrl('/dashboard/plant-admin');
    else this.router.navigateByUrl('/dashboard/alf3-dashboard');
  }

  logout() {
    this.authenticationService.logout();
  }
}
