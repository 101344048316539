<div class="customModal">
  <div class="modal-body">
    <div class="row">
      <div class="col-md-6">
        <h3 class="modal-title font-weight-bold text-white" id="modal-title">{{selectedParam | replaceUnderscore}}</h3>
      </div>
      <div class="col-md-6">
        <button type="button" class="close" aria-label="Close button" data-dismiss="modal"
          (click)="closeModal('dismiss')">
          <span aria-hidden="true">&times;</span>
        </button></div>
        <div class="w-50 ml-auto pr-4 float-right">
          <select class="selectParam form-control custom-select-sm" [(ngModel)]="selectedParam" (change)="changeParam()">
            <option *ngFor="let param of yieldsParameterName" [value]="param.parameter">{{param.parameter | replaceUnderscore}}</option>
          </select>
        </div>
        <!-- <div class="w-25 ml-auto pr-4 float-right">
          <select class="form-control custom-select-sm">
            <option selected>Today, {{today | date:'MMM d'}}</option>
            <option value="1">Weekly</option>
            <option value="2">Monthly</option>
            <option value="3">Quarterly</option>
            <option value="3">Yearly</option>
          </select>
        </div> -->
    </div>
    <!-- <app-trend-charts></app-trend-charts> -->
  </div>
</div>
