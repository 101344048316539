import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from "@angular/router";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Cognitive Demo';
  private inactiveTimeout: any;
  constructor(private router: Router, public snackBar: MatSnackBar) {}
  @HostListener("document:visibilitychange", ["$event"])
  onVisibilityChange(event: Event): void {
    if (document.hidden) {
      this.startInactiveTimeout();
    } else {
      this.clearInactiveTimeout();
    }
  }
  private startInactiveTimeout(): void {
    this.inactiveTimeout = setTimeout(() => {
        window.location.reload();
      },
      2 * 60 * 60 * 1000  // 2 hours in milliseconds
      //  5 * 60 * 1000 // 5 minutes in milliseconds
    );
  }
  private clearInactiveTimeout(): void {
    if (this.inactiveTimeout) {
      clearTimeout(this.inactiveTimeout);
    }
  }
  private setupTabActivityDetection(): void {
    // Initial setup for visibility change
    if (document.hidden) {
      this.startInactiveTimeout();
    }
  }
  ngOnInit(): void {
    this.setupTabActivityDetection();
    const token = localStorage.getItem("user");
    if (token) {
      const tokenPayload = token.split(".")[1];
      const decodedPayload = atob(tokenPayload);
      const decodedPayloadJson = JSON.parse(decodedPayload);
      const expirationTime = decodedPayloadJson.exp;
      const currentTime = Math.floor(Date.now() / 1000);
      // console.log(
      //   "Current Time:",
      //   new Date(currentTime * 1000).toLocaleString()
      // );
      // console.log(
      //   "Expiration Time:", expirationTime,
      //   new Date(expirationTime * 1000).toLocaleString()
      // );
      if (currentTime >= expirationTime) {
        // console.log("Token has expired.");
        this.logout();
        setTimeout(() => {
          this.openSnackBar();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }, 100);
      } else {
        // console.log("Token is still valid.");
        // Automatically log out after the token expiration time
        const timeUntilLogout = expirationTime - currentTime;
        setTimeout(() => {
          this.logout();
          this.openSnackBar();
          setTimeout(() => {
            // Clear browser cache and force a reload
            window.location.reload();
          }, 1000);
        }, timeUntilLogout * 1000); // Convert seconds to milliseconds
      }
    }
  }
  ngOnDestroy() {
    this.clearInactiveTimeout();
  }
  openSnackBar() {
    this.snackBar.open('Session Timeout', '',{
      duration: 2000,
    });
  }
  logout() {
    localStorage.clear();
    this.router.navigate(["auth/login"]);
  }
}

