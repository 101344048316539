<app-navbar></app-navbar>
<div class="main-body">
  <div class="dashBody row pt-1">
    <div class="col d-flex">
      <div class="mr-auto">
        <p class="text-white pt-2 font-weight-bold" style="font-size: 16px">Digital Twins ALF3
          
        </p>
      </div>
      <div>
        <p class="form-control text-light text-center" style="background-color: #393f4f;">Date: {{today | date:
          'dd-MM-yyyy'}}</p>
      </div>
    </div>
  </div>
  <div class="dashBody row mb-3">
    <div class="col-md-3 leftPane">
                  <!-- start-->
      
                  <div class="ml-auto pt-3">
                    
                    <div class="col-md-11 p-0 pb-2">
                      <select [(ngModel)]="SelectedPot" class="form-control text-light selectParam" (change)="OnPots($event)">
                          <option *ngFor="let pot of SelectPot" [value]="pot.id">{{pot.name}}</option>
                      </select>
                     
                    </div>
                   
                  </div>
      
                  <!--End-->

        <p class="font-weight-normal">Critical Parameters</p>
        <app-balcotheme1></app-balcotheme1>        
    </div>
    <div class="col-md-6 centerPane text-center mt-1">
      <p class="h2 text-white font-weight-bold pb-1">Aluminium Smelter 4.0</p>
      <app-balco-furnace-image></app-balco-furnace-image>
      
      <!-- <div>
        <img class="img-fluid" src="assets\img\newAlf3 1.svg" alt="AluminumSmeltingGraphic" style="width:100%">
      </div> -->

    </div>

    <div class="col-md-3 rightPane">

      
      <!-- <button type="button" class="mb-2 mr-2 mt-2 btn btn-success" (click)="openParamDialog()">Generate Recommendations</button> -->
      <button type="button" class="mb-2 mr-2 mt-2 btn btn-success" (click)="generateRecommendations()">Generate Recommendations</button>
      <p *ngIf="!loading"></p>

          <mat-spinner class="my-auto mx-auto" *ngIf="loading"></mat-spinner>
      <div *ngIf="predlive" >
       <app-param-table-modal></app-param-table-modal>
      </div>
      


      <!-- app optimized end-->

    </div>
  </div>
  <div col-12 ml-2 class="text-white"> © LivNSense GreenOps Pvt. Ltd. All Rights Reserved. </div>
</div>