<div class="main_view">
  <div class="header">
    <img
      class="logo"
      src="./assets/logo/LivNSense Logo.svg"
      alt="livNSense"
    />
    <div class="support">
      <div>Get help</div>
      <img src="./assets/icons/Frame.svg" alt="support" />
    </div>
  </div>
  <div class="center_container">
    <div class="left-part">
        <img src="assets/img/Frame%201(1).svg" alt="Image 1">
    </div>
    <div class="right-part">
    <div class="heading">Welcome to GreenOps</div>
    <div class="form_container">
      <form (ngSubmit)="login()" #loginForm="ngForm">
        <div class="input-container">
          <input
            type="text"
            id="username"
            name="username"
            [(ngModel)]="username"
            required
            #usernameInput="ngModel"
            placeholder="Username"
          />
          <img class="input-icon" src="./assets/icons/User.svg" />
        </div>
        <br />
        <div class="input-container">
          <input
            [type]="showPassword ? 'text' : 'password'"
            id="password"
            name="password"
            [(ngModel)]="password"
            placeholder="Password"
            required
            #passwordInput="ngModel"
          />
          <span
            class="toggle-password-visibility"
            (click)="togglePasswordVisibility()"
          >
            <img
              *ngIf="!showPassword"
              class="toggle-icon"
              style="display: flex; left: 87.5%"
              src="./assets/icons/Eye.svg"
            />
            <img
              *ngIf="showPassword"
              class="toggle-icon"
              style="display: flex; left: 87.5%"
              src="./assets/icons/Eye-close.svg"
            />
          </span>
          <img class="input-icon" src="./assets/icons/Lock.svg" />
        </div>
        <p class="forgot_pass" (click)="goToForgotPasswordform()">
          Forgot Password?
        </p>
        <div class="error-message" *ngIf="loginHandler">
          <div class="error-message-heading">
            <img src="./assets/icons/Warning.svg" />Uh oh! We're
            having trouble signing you in.
          </div>
          <div class="error-message-content">
            Please double-check your credentials and try again (or)<br />
            Click "Forgot Password" to receive a password reset link.
          </div>
        </div>
        <div *ngIf="isLoading" style="height: 10vh; display: grid; width: 100%">
          <div style="margin: auto">
            <img
              src="../../../assets/Images/spinner.gif"
              style="width: 70px"
            />
            <p style="text-align: center">Signing In...</p>
          </div>
        </div>
        <span style="display: flex; justify-content: center">
          <button
            class="sign-in-btn"
            type="submit"
            [disabled]="loginForm.invalid"
          >
            Sign In
          </button>
        </span>
      </form>
    </div>
    </div>
    </div>
  <div class="footer">
    &copy;{{year}} LivNSense . All Rights Reserved . Powered by GreenOps™&reg;
  </div>
</div>
