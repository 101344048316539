import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../../components/snackbar/snackbar.component';
import { ApiCallsService } from '../../services/api-calls.service';

@Component({
  selector: 'app-modify-user',
  templateUrl: './modify-user.component.html',
  styleUrls: ['./modify-user.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModifyUserDialogComponent implements OnInit {

  isLoading: boolean;
  roles: any[];
  misMatchError: boolean;

  passwordForm: any;

  constructor(public dialogRef: MatDialogRef<ModifyUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public snackBar: MatSnackBar, private apiCallsService: ApiCallsService) { }

  ngOnInit() {
    let rolesList = ['Admin', 'Super Admin', 'User'];
    this.roles = rolesList;
    this.passwordForm = { password: '', reEnterPassword: '' };

  }

  /* Method to close the modal if user clicks the cancel*/
  onNoClick(): void {
    this.dialogRef.close();
  }

  /* Method to toggle labels */
  toggleLabels() {

    /* this.labelStatus = !this.labelStatus;

    this.alertCardsRequired = !this.alertCardsRequired;
    this.toggleChange.emit(this.labelStatus); */
  }

  /* Method to update configuration */
  updateUser() {
    this.isLoading = true;
    this.apiCallsService.updateUser(this.data).subscribe(data => {
      this.dialogRef.close();
      this.isLoading = false;
      this.openSnackBar(data);
    }, err => {
      this.dialogRef.close();
      this.isLoading = false;
      this.openSnackBar(err['error']);
    })
  }

  public isAnyEmptyFields() {
    let category = this.data;
    for (let o in category) {
      if (!category[o])
        return false;
    }
    return true;
  }

  /* Method which opens the snackbar */
  openSnackBar(res) {
    this.snackBar.openFromComponent(SnackbarComponent, {
      duration: 1500,
      data: res,
      horizontalPosition: 'start',
      verticalPosition: 'bottom'
    });
  }

  isResetDisabled() {
    return (this.passwordForm['password'].length > 0
      && this.passwordForm['reEnterPassword'].length > 0)
      ? false
      : true;
  }

  onRePasswordInput() {
    if (this.passwordForm['reEnterPassword'].length > 0) {
      if (this.passwordForm['reEnterPassword'] !== this.passwordForm['password']) {
        this.misMatchError = true;
      }
    }
  }

  resetPassword() {
    const body = { login_id: this.data['login_id'], password: this.passwordForm['password'] };
    this.apiCallsService.modifyPassword(body).subscribe(data => {
      this.openSnackBar(data);
    }, err => {
      this.openSnackBar(err['error']);
    })
  }
}
