<div>
  <div class="row">
    <div class="col-md-7">
      <div class="table-responsive p-0" id="demo">
        <table class="table table-borderless tableBody">
          <thead>
            <tr class="main-table-row text-center">
              <td scope="col font-weight-bold" style="width: 7rem; padding: 0px;"></td>
              <td scope="col font-weight-bold" style="padding: 0px;"></td>
              <td scope="col font-weight-bold" style="padding: 0px;"><p class="h4 font-weight-bold text-white">CPR <span class="h6 pl-2">Ratio</span></p></td>
              <td scope="col font-weight-bold" style="padding: 0px;"></td>
            </tr>
            <tr class="main-table-row text-center">
              <td scope="col font-weight-bold" style="width: 7rem"></td>
              <td scope="col font-weight-bold">Zone 1</td>
              <td scope="col font-weight-bold">Zone 2</td>
              <td scope="col font-weight-bold">Zone 3</td>
            </tr>
          </thead>
          <tbody>
            <tr class="table-data trow">
              <td class="cellBorder font-weight-bold text-center">SOR</td>
              <td class="cellBorder font-weight-bold text-center">{{cprz1 |
                number: '1.2-2'}}</td>
              <td class="cellBorder font-weight-bold text-center">{{cprz2 |
                number: '1.2-2'}}</td>
              <td class="cellBorder font-weight-bold text-center">{{cprz3 |
                number: '1.2-2'}}</td>
            </tr>
            <!-- <tr class="table-data trow">
              <td class="cellBorder font-weight-bold text-center">EOR</td>
              <td class="cellBorder font-weight-bold text-center"></td>
              <td class="cellBorder font-weight-bold text-center"></td>
              <td class="cellBorder font-weight-bold text-center"></td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-md-5">
      <div class="table-responsive p-0" id="demo">
        <table class="table table-borderless tableBody">
          <thead>
            <tr class="main-table-row text-center">
              <td scope="col font-weight-bold p-0" style="padding: 0px;"></td>
              <td scope="col font-weight-bold p-0" style="padding: 0px;"><p class="h4 font-weight-bold text-white">TMT <span class="h6 pl-2">°C</span></p></td>
              <td scope="col font-weight-bold p-0" style="padding: 0px;"></td>
            </tr>
            <tr class="main-table-row text-center">
              <td scope="col font-weight-bold">Zone 1</td>
              <td scope="col font-weight-bold">Zone 2</td>
              <td scope="col font-weight-bold">Zone 3</td>
            </tr>
          </thead>
          <tbody>
            <tr class="table-data trow">
              <td class="cellBorder font-weight-bold text-center">{{tmtz1 |
                number: '1.2-2'}} &nbsp;</td>
              <td class="cellBorder font-weight-bold text-center">{{tmtz2 |
                number: '1.2-2'}} &nbsp;</td>
              <td class="cellBorder font-weight-bold text-center">{{tmtz3 |
                number: '1.2-2'}} &nbsp;</td>
            </tr>
            <!-- <tr class="table-data trow">
              <td class="cellBorder font-weight-bold text-center">&nbsp;</td>
              <td class="cellBorder font-weight-bold text-center">&nbsp;</td>
              <td class="cellBorder font-weight-bold text-center">&nbsp;</td>
            </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
