<section class="card mb-4">
  <p class="table-title mb-0 py-2" [ngStyle]="headerColor()"><span class="dot" [ngStyle]="dotColor()"></span>Critical Parameters
  </p>
  <div class="table-responsive" id="demo">
      <table class="table table-borderless tableBody">
        <thead>
          <tr class="main-table-row text-left" style="color: white;">
            <td scope="col font-weight-bold"></td>
            <td scope="col font-weight-bold">Zone 1</td>
            <td scope="col font-weight-bold">Zone 2</td>
            <td scope="col font-weight-bold">Zone 3</td>
          </tr>
        </thead>
        <tbody>
          <tr class="table-data trow" *ngFor="let paramValue of paramValues | keyvalue: returnZero"
            (click)="openSingleParamDialog(paramValue.key)">
            <!-- Key: <b>{{paramValue.key}}</b>
                <div *ngFor="let item1 of paramValue.value">
                    {{item1.Zone1}}
                  </div> -->
            <ng-container *ngFor="let param of paramValue.value">
              <td class="row-data cellBorder text-left">{{paramValue.key}}<span class="h6 font-weight-bold px-2">{{param.uom}}</span></td>
            <td class="row-data cellBorder text-left" style="color: white;">{{param.Zone1 |
                  number: '1.2-2'}}</td>
            <td class="row-data cellBorder text-left" style="color: white;">{{param.Zone2 |
                  number: '1.2-2'}}</td>
            <td class="row-data cellBorder text-left" style="color: white;">{{param.Zone3 |
                  number: '1.2-2'}}</td>
            </ng-container>
          </tr>
        </tbody>
      </table>
    </div>
  </section>