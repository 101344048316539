import { ParamService } from './../../core/param.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import jwt_decode from 'jwt-decode';

@Component({
  selector: 'app-plant-operator',
  templateUrl: './plant-operator.component.html',
  styleUrls: ['./plant-operator.component.scss'],
})
export class PlantOperatorComponent implements OnInit {
  today: number = Date.now();
  selectedVersion: any;
  selectedParam: any;
  lastUpdatedDate: any;
  startDate: any;
  endDate: any;
  constructor(private paramService: ParamService, private router: Router) {
    this.paramService.selectedVersion.subscribe((res: any) => {
      this.selectedVersion = res;
    });
    // this.paramService.selectedParameter.subscribe((res: any) => {
    //   this.selectedParam = res;
    // });
    // this.getValues(this.selectedVersion);
  }

  ngOnInit(): void {
    let token = localStorage.getItem('currentUser');
    let subToken = token.substr(2, token.length - 3);
    let decodeToken = jwt_decode(subToken);
    if (decodeToken['first_name'] == 'engineer') {
      this.router.navigate(['/dashboard/plant-operator']);
    } else {
      this.router.navigate(['/dashboard/landingpage']);
    }
  }

  changeVersion() {
    this.paramService.selectedVersion.next(this.selectedVersion);
    // this.paramService.selectedParameter.subscribe((res: any) => {
    //   this.selectedParam = res;
    // });
    // this.getValues(this.selectedVersion);
  }

  // getValues(num) {
  //   this.paramService
  //     .getDashboardData(this.startDate, this.endDate)
  //     .subscribe((res) => {
  //       this.lastUpdatedDate = res[0].timestamp;
  //     });
  // }
}
