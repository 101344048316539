 <div class="card my-3 p-3 config-card">
   <div class="header-text">
     <img src="assets\icons\AccessConfig.png" height="50px">
     <span class="text-light ml-2">Access Configuration</span>

     <button class="btn btn-sm purple-button float-right" aria-hidden="true" [disabled]="isUpdateDisabled()"
       (click)="updatePermissions()">Update</button>
   </div>

   <div class="access-config-table custom-height-col">
     <table class="table bordered-table table-dark user-c-table table-responsive d-table w-100 mt-3">
       <thead>
         <tr>
           <th>Section</th>
           <th>Feature</th>
           <th class="text-center">Super Admin</th>
           <th class="text-center">Admin</th>
           <th class="text-center">Non Admin</th>
         </tr>
       </thead>

       <tbody>

         <ng-container *ngFor="let section of sections; let i = index;">
           <tr>
             <td [attr.rowspan]="allPermissions[section]?.length + 1">{{section}}</td>
           </tr>
           <tr *ngFor="let access of allPermissions[section]">
             <td>{{access.feature}}</td>

             <td class="text-center"><span class="toggle-btn"><span class="labels-text"></span><label
                   class="config-switch">
                   <input type="checkbox" [(ngModel)]="access['Super Admin']">
                   <span class="slider round"></span>
                 </label></span></td>

             <td class="text-center"><span class="toggle-btn"><span class="labels-text"></span><label
                   class="config-switch">
                   <input type="checkbox" [(ngModel)]="access['Admin']">
                   <span class="slider round"></span>
                 </label></span></td>

             <td class="text-center"><span class="toggle-btn"><span class="labels-text"></span><label
                   class="config-switch">
                   <input type="checkbox" [(ngModel)]="access['Non Admin']">
                   <span class="slider round"></span>
                 </label></span></td>
           </tr>
         </ng-container>
       </tbody>
     </table>
   </div>
   
 </div>
