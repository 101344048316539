import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-single-param-graph',
  templateUrl: './single-param-graph.component.html',
  styleUrls: ['./single-param-graph.component.scss']
})
export class SingleParamGraphComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
