<div class="main_view">
  <div class="header">
    <img
      class="logo"
      src="./assets/logo/LivNSense Logo.svg"
      alt="livNSense"
    />
    <div class="support">
      <div>Get help</div>
      <img src="./assets/icons/Frame.svg" alt="support" />
    </div>
  </div>
  <div class="center_container">
    <div class="left-part"><img src="assets/img/Frame%201(1).svg"></div>
    <div class="right-part">
    <div class="heading">Welcome to GreenOps</div>
    <div class="form_container">
      <form #changePwForm="ngForm" *ngIf="!success">
        <div class="input-container">
          <input
            [type]="showPassword1 ? 'text' : 'password'"
            id="newpassword"
            name="newpassword"
            [(ngModel)]="newpassword"
            placeholder="New password"
            required
            minlength="8"
            maxlength="24"
            #newpasswordInput="ngModel"
            (ngModelChange)="validatePassword($event)"
          />
          <span
            style="right: 10px"
            class="toggle-password-visibility"
            (click)="togglePasswordVisibility1()"
          >
            <img
              *ngIf="!showPassword1"
              class="toggle-icon"
              src="./assets/icons/Eye-close.svg"
            />
            <img
              *ngIf="showPassword1"
              class="toggle-icon"
              src="./assets/icons/Eye.svg"
            />
          </span>
        </div>
        <div *ngIf="errorHandler && !isOTP" class="error-message">
          <!-- <div class="error-message-heading">
              <img src="../../../../../assets/Icons/Warning.svg" />Uh oh! We're
              having trouble signing you in.
            </div> -->
          <div class="error-message-content" style="margin-top: 8px">
            {{ errorHandler }}
          </div>
        </div>
        <br />
        <div class="input-container">
          <input
            [type]="showPassword2 ? 'text' : 'password'"
            id="confirmpassword"
            name="confirmpassword"
            [(ngModel)]="confirmpassword"
            placeholder="Re-enter password"
            required
            #confirmpasswordInput="ngModel"
          />
          <span
            style="right: 10px"
            class="toggle-password-visibility"
            (click)="togglePasswordVisibility2()"
          >
            <img
              *ngIf="!showPassword2"
              class="toggle-icon"
              src="./assets/icons/Eye-close.svg"
            />
            <img
              *ngIf="showPassword2"
              class="toggle-icon"
              src="./assets/icons/Eye.svg"
            />
          </span>
        </div>
        <div class="info-message">
          <!-- <div class="error-message-heading">
                <img src="../../../../../assets/Icons/Warning.svg" />Uh oh! We're
                having trouble signing you in.
              </div> -->
          <div class="info-message-content" style="margin-top: 8px">
            <div [ngClass]="{ valid: passwordCriteria.length }">
              At least 8 characters length
            </div>
            <div [ngClass]="{ valid: passwordCriteria.number }">
              At least 1 number
            </div>
            <div [ngClass]="{ valid: passwordCriteria.lowercase }">
              At least 1 lowercase letter
            </div>
            <div [ngClass]="{ valid: passwordCriteria.uppercase }">
              At least 1 uppercase letter
            </div>
            <div [ngClass]="{ valid: passwordCriteria.specialChar }">
              At least 1 symbol
            </div>
          </div>
        </div>
        <div *ngIf="loginHandler" class="error-message">
          <!-- <div class="error-message-heading">
              <img src="../../../../../assets/Icons/Warning.svg" />Uh oh! We're
              having trouble signing you in.
            </div> -->
          <div class="error-message-content" style="margin-top: 8px">
            {{ loginHandler }}
          </div>
        </div>
        <span style="display: flex; justify-content: center; margin-top: 20px">
          <span
            style="display: flex; justify-content: space-between; gap: 15px"
          >
            <button
              style="padding: 10px 30px"
              class="sign-in-btn"
              type="submit"
              [disabled]="changePwForm.invalid"
              (click)="SubmitNewpw()"
            >
              Change password
            </button>
            <!-- <button
                *ngIf="isOTP"
                class="send-mail-btn"
                type="submit"
                [disabled]="otp.invalid"
                (click)="goToResetPasswordform()"
              >
                Reset
              </button> -->
            <button
              class="back-sign-in-btn"
              type="submit"
              (click)="goToLoginform()"
            >
              Back to Sign In
            </button>
          </span>
        </span>
      </form>
      <div class="redirect" *ngIf="success">
        <p class="success">{{ success }}</p>
        <button
          style="padding: 10px 50px"
          class="sign-in-btn"
          type="submit"
          (click)="goToLoginform()"
        >
          Back to Sign In
        </button>
      </div>
     </div>
    </div>
  </div>
  <div class="footer">
    &copy;{{year}} LivNSense . All Rights Reserved . Powered by GreenOps™&reg;
  </div>
</div>
