import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting-configuaration',
  templateUrl: './setting-configuaration.component.html',
  styleUrls: ['./setting-configuaration.component.scss']
})
export class SettingConfiguarationComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}

