import { Component, OnInit, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { ParamService } from './../../core/param.service';
import { MatDialog } from '@angular/material/dialog';
import { MultiParamGraphModalComponent } from '../../components/multi-param-graph-modal/multi-param-graph-modal.component';

@Component({
  selector: 'app-furnace',
  templateUrl: './furnace.component.html',
  styleUrls: ['./furnace.component.scss'],
})
export class FurnaceComponent implements OnInit {
  today: number = Date.now();
  private batchRuns: string[];
 
  paramValue: any;
  selectedProcess: any;
  days: any;
  counter: any;
  selected: any;
  live: boolean = false;
  checked = [];
  //Optimizer start
  public show: boolean = false;
  public predshow: boolean = false;
  optlive: boolean = false;
  
  predlive: boolean = false;
  optimized = false;
  loading = false;
  
  predDays = 0;
//Optimizer end
  // value: any;
  liveRun: any;
  lBatchVal: any = 6;

  constructor(private router: Router, public dialog: MatDialog, private paramService: ParamService) {
    this.paramService.selectedRun.subscribe((res) => {
      console.log(res);
      this.selected = res;
    });

    this.paramService.switch.subscribe((res) => {
      console.log(res);
      this.live = res;
    });

    this.paramService.actualRunlength(this.selected).subscribe((res) => {
      const hours = res[0]['Ethane Feed'][0].runlength;
      this.days = hours / 24;
    });
  }

  ngOnInit(): void {

    this.paramService.runCount().subscribe((res) => {
      let temp = res[0].run;
      this.batchRuns = temp.splice(0, temp.length - 1);
      console.log('Batch', this.batchRuns);
      console.log('last Batch', this.lBatchVal[0]);
      let lValue = temp.splice(-1, 1);
      this.liveRun = lValue[0];
      console.log('Live', this.liveRun);
    });

    this.paramService.selectedRun.subscribe((res) => {
      this.selected = res;

      if (res === 6) {
        this.optlive = true;
        this.predlive = true;
      }
    });

    this.paramService.getEthaneZonewise(this.selected).subscribe((res) => {
      this.days = res[0].actual_days[0].day;
    });

    this.paramService.getEthaneZonewise(this.selected).subscribe((res) => {
      console.log(res[0])
      this.predDays = res[0].run_length[0];
      console.log(this.predDays);
    });
  }

  ngOnChanges() {}

  changeProcess() {
    this.live = !this.live;
    // this.value = !this.value;
    if (this.live == true) {
      this.paramService.selectedRun.next(this.liveRun);
      this.paramService.getEthaneZonewise(this.selected).subscribe((res) => {
        console.log(res);
        this.days = res[0].actual_days[0].day;
      });
      this.paramService.switch.next(true);
    } else {
      console.log('Test', this.lBatchVal);
      this.paramService.selectedRun.next(4);
      this.paramService.getEthaneZonewise(this.selected).subscribe((res) => {
        console.log(res);
        this.days = res[0].actual_days[0].day;
      });
      this.paramService.switch.next(false);
    }
  }

  changeRoute() {
    this.router.navigateByUrl('/dashboard/furnace-optimiser');
    this.paramService.selectedRun.next(this.selected);
  }

  changeRun(event) {
    this.selected = event.target.value;
    this.paramService.getEthaneZonewise(this.selected).subscribe((res) => {
      console.log(res);
      this.days = res[0].actual_days[0].day;
    });
  }

  switch() {
    // this.value = !this.value;
    this.live = !this.live;
  }
  optimizedParam() {
    if(this.show == false){
    this.loading = true;
    var min = 5,
      max = 10;
    var rand = Math.floor(Math.random() * (max - min + 1) + min);
    setTimeout(() => {
      this.loading = false;
      
        this.show = !this.show;
      this.optimized = true;
      console.log(rand);
    }, rand * 1000);
    }
  }
  predictParam() {
    //this.predshow = !this.predshow;
    if(this.predshow == false){
     this.loading = true;
     var min = 5,
       max = 10;
     var rand = Math.floor(Math.random() * (max - min + 1) + min);
     setTimeout(() => {
       this.loading = false;
       
        this.predshow = !this.predshow;
       //this.optimized = true;
       console.log(rand);
     }, rand * 1000);
    }
  }
  openMultipleParamDialog(): void {
    const dialogRef = this.dialog.open(MultiParamGraphModalComponent, {
      // disableClose: true,
      // hasBackdrop: true,
      // closeOnNavigation: true,
      panelClass: 'normal-modal-class',
      data: {
        param: 'FGFCPR',
        run: 6,
        zone: 1,
      },
    });

    //close on navigation
    this.router.events.subscribe(() => {
      dialogRef.close();
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }
}
