<div class="card" style="background-color: #2d3243;">
    <div class="row">
        <div class="col-4">
            <div class="form-group">
                <label class="text-light">Select period:</label>
                <select name="selectedPeriod" class="form-control text-primary selectParam"
                    [(ngModel)]="selectedPeriod">
                    <option class="text-light" value="0">Today, {{today | date:'MMM d'}}</option>
                    <option class="text-light" value="1">Weekly</option>
                    <option class="text-light" value="2">Monthly</option>
                    <option class="text-light" value="3">Quarterly</option>
                </select>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <label class="text-light">Select parameter type:</label>
                <select name="selectedParameter" class="form-control text-primary selectParam"
                    [(ngModel)]="selectedParameter" (change)="changeParameter()">
                    <option class="text-light" *ngFor="let option of dcuParameter" [value]="option.value">
                        {{option.name}}</option>
                </select>
            </div>
        </div>
        <div class="col-4">
            <div class="form-group">
                <label class="text-light">Select version:</label>
                <select name="selectedVersion" class="form-control text-primary selectParam"
                    [(ngModel)]="selectedVersion" (change)="changeParameter()">
                    <option class="text-light" value="1">1</option>
                    <option class="text-light" value="2">2</option>
                    <option class="text-light" value="3">3</option>
                    <option class="text-light" value="4">4</option>
                </select>
            </div>
        </div>
    </div>
    <div class="row mt-1">
        <div class="col-4 offset-md-4">
            <app-parameter-table [header]="selectedParameter" [isConfig]="true"
                [selection]='selectedParameter' (onMinInputValueChanged)="change($event)"
                (onMaxInputValueChanged)="change($event)" [selectedParameter]='actualParameter'>
            </app-parameter-table>
        </div>
        <div class="col-4 mt-auto mb-4">
            <div>
                <button class="btn btn-primary mr-1" (click)="update()">Update changes</button>
                <button class="btn btn-primary" (click)="createVersion()">Create</button>
            </div>
        </div>
    </div>
</div>
